<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row>
        <v-col sm="12">
          <h1>Mappings</h1>
          <p>Eine Übersicht der existierenden Mappings.</p>
        </v-col>
        <v-col class="text-right">
          <p>Hier können Mappings importiert und exportiert werden.</p>
          <v-btn class="ml-3 mb-3 primary" v-on:click="importMappings"><v-icon class="mr-1">mdi-upload</v-icon>Import</v-btn>
          <v-btn class="ml-3 mb-3 primary" v-on:click="exportMappings"><v-icon class="mr-1">mdi-download</v-icon>Export</v-btn>
          <p>
            <small><b>Import:</b> existierende Mapping Definitionen im Config Verzeichnis werden importiert (platziert durch IT).</small><br>
            <small><b>Export:</b> Mappings werden ins Config Verzeichnis exportiert und zusätzlich als Download zur Verfügung gestellt.</small>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Suche"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="mappings"
                :items-per-page="50"
                :item-class="markLegacy"
                :search="search"
                class="elevation-1"
                sort-by="name">
                <template v-slot:item.actions="{ item }">
                  <v-icon class="mr-2" small @click="editMapping(item)">mdi-pencil</v-icon>
                  <v-icon v-if="item.locked === false" small @click="deleteMapping(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="450">
        <v-card>
          <v-card-title class="green--text">
            <v-icon color="green" large left>mdi-information-outline</v-icon>
            <span class="title">Result</span>
          </v-card-title>
          <v-card-text>
            {{ mappingExportImportResult }}
          </v-card-text>
          <v-card-actions class="px-6 pb-6">
            <v-btn v-on:click="dialog=false" class="primary--text" outlined>
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogEdit" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Bearbeiten</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-switch v-model="editedItem.legacy" label="Veraltetes Mapping" color="orange darken-3"></v-switch>
                  <v-textarea v-model="editedItem.description" label="Beschreibung" counter="255"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeEdit">
              abbrechen
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save">
              <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
              <span v-if="loading === false">Speichern</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">Achtung!</v-card-title>
          <v-card-text>Mapping "{{ editedItem.name }}" wirklich löschen?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
              <span v-if="loading === false">Löschen</span>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    headers: [
      { text: 'ID', value: 'id', sortable: false },
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Target Class', value: 'targetClass' },
      { text: 'Columns', value: 'columns.length' },
      { text: 'Locked', value: 'locked' },
      { text: 'Veraltet', value: 'legacy' },
      { text: 'Revision', value: 'revision' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    search: '',
    mappings: [],
    loading: false,
    dialog: false,
    dialogDelete: false,
    dialogEdit: false,
    defaultItem: {
      name: '',
      description: null,
      legacy: false
    },
    editedIndex: -1,
    editedItem: {
      name: '',
      description: null,
      legacy: false
    },
    mappingExportImportResult: ''
  }),
  watch: {
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    getList: async function () {
      const response = await this.$http.get('/mapping/list')
      this.mappings = response.data
    },
    importMappings: async function () {
      const response = await this.$http.get('/mapping/import_mappings')
      this.mappingExportImportResult = 'Importierte oder aktualisierte Mappings: ' + response.data.length
      this.dialog = true
      await this.getList()
      this.initiated = true
    },
    exportMappings: async function () {
      await this.$http.get(
        '/mapping/export_mappings',
        { responseType: 'blob' }
      ).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'mapping_export.zip')
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
      })
    },
    editMapping (item) {
      this.editedIndex = this.mappings.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true
    },
    closeEdit () {
      this.dialogEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteMapping: async function (item) {
      this.editedIndex = this.mappings.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm: async function () {
      this.loading = true
      await this.$http.delete('/mapping/delete/' + this.editedItem.id)
      this.loading = false
      this.closeDelete()
      await this.getList()
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save: async function () {
      if (this.editedIndex > -1) {
        this.loading = true
        const payload = {
          description: this.editedItem.description === '' ? null : this.editedItem.description,
          legacy: this.editedItem.legacy === true
        }
        await this.$http.patch('/mapping/update/' + this.editedItem.id, payload)
        this.loading = false
      }
      this.closeEdit()
      await this.getList()
    },
    markLegacy: function (item) {
      return item.legacy === true ? 'legacy' : ''
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    await this.getList()
    this.initiated = true
  }
}
</script>
<style>
.legacy td {
  background-color: darkgrey;
}
</style>
