<template>

  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <h5>Ausgeschlossene Wörter im Tabellenkopf</h5>
          <p class="explanation body-2 pt-3 pr-3 mb-0">
            Bei diesem Check, wird geprüft, ob sich ein im Voraus ausgeschlossenes Wort im Tabellenkopf der Lieferung befindet.
          </p>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="profile === true">
        <v-icon color="light-green">mdi-checkbox-marked-circle-outline</v-icon>
        Ok. Es wurden keine ausgeschlossenen Wörter gefunden.
      </v-expansion-panel-content>
      <v-expansion-panel-content v-else>
        <p class="pb-2">
          <v-icon color="red darken-1">mdi-close-circle-outline</v-icon>
          Achtung! Es wurden Wörter gefunden, die nicht verarbeitet werden können. Insbesondere im Tabellenkopf ist dies nicht zulässig und muss in der Lieferung behoben werden.
        </p>
        <ul>
          <li v-for="(value, header) in sub">
            Tabellenkopfeintrag in Spalte
              <span class="monospace font-bigger">{{ header }}</span>
            überprüfen. </li>
        </ul>
        <p class="explanation body-2 pt-3 mb-0">Als ausgeschlossene Wörter definiert sind: 'null', 'false', ’n/a', 'true', ' '</p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
export default {
  props: [
    'profile',
    'sub',
    'header'
  ]
}
</script>
