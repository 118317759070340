<template>
  <v-container fluid class="relative header-space container">
    <div>
      <h1>Freigabe der Lieferung</h1>
      <p class="col-md-8 pa-0">In diesem Bereich kann eine Freigabe zur Lieferung von einzelnen Projekten erteilt werden. Ob ein Projekt bereits freigegeben wurde, lässt sich der "Freigegeben"-List entnehmen.
        Falls ein neues Projekt freigegeben werden soll, müssen folgende Schritte durchlaufen werden:
      </p>
      <ol>
        <li>Nach dem Klick auf Neue Freigabe geben müssen Projekt und Jahrgang ausgewählt werden.</li>
        <li>Im Anschluss können einzelne Toplisten angeklickt werden, um eine finale Prüfung der Score-Daten durchzuführen.</li>
        <li>Werden die Daten von Research für korrekt empfunden, können sie mit Klick auf den entsprechenden Button das Projekt freigeben.</li>
      </ol>
      <v-row>
        <v-col class="pr-lg-12">
          <div v-show="initiated">
            <v-card v-if="adding" class="my-6 pa-4">
              <div v-show="!showPreview">
                <h5 class="mb-2">Freizugebende Liste auswählen</h5>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="selectedRankingGroupShortCode"
                      label="Projekt"
                      :items="rankingGroups"
                      item-text="name"
                      item-value="shortCode"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="selectedPeriodName"
                      label="Jahrgang"
                      :items="periods"
                      item-text="name"
                      item-value="name"
                      :disabled="selectedRankingGroupShortCode === null">
                    </v-select>
                  </v-col>
                  <v-col class="pt-3">
                    <v-btn
                      v-on:click="loadPreview"
                      @click="showPreview = true"
                      class="mb-6"
                      color="blue darken-3"
                      outlined
                      block
                      :disabled="selectedPeriodName === null">
                      Vorschau anzeigen
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <div v-if="showPreview && !loading">
                <h5 class="mb-2">Wähle zur Ansicht eine Topliste aus dem Projekt <span>{{ selectedRankingGroup.name }}</span> aus:</h5>
                <v-chip-group active-class="blue--text text--darken-3" show-arrows v-model="selectedToplistLabel" v-on:change="loadPreviewToplistLabelItems" value="id" >
                  <v-chip v-for="toplist in toplistLabelsByName" :key="toplist.id" filter :value="toplist.id" :chipLabel="toplist.value">
                    {{ toplist.value }}
                  </v-chip>
                </v-chip-group>

                <div class="mt-6" v-if="toplistPreview">
                  <h5 class="mt-6 mb-2">Vorschau:</h5>
                  <!-- Table shows Data from selected rankingGroup and period-->
                  <v-data-table
                    :headers="headers"
                    :items="sortedItems"
                    :loading="loading"
                    loading-text="Lädt"
                    :options.sync="options"
                    :items-per-page="100"
                    :server-items-length="total"
                    :footer-props="{'items-per-page-options': [100, 250, 1000]}"
                    disable-sort>

                    <!-- Decides whether Account-Name or Contact firstName and surname  -->
                    <template v-slot:item.shortName="{ item }">
                      <router-link v-if="item[rootType]" :to="'/' + rootType + '/' + item[rootType].id ">
                        <span v-if="rootType === 'account'">{{ item.account.name }}</span>
                        <span v-else>{{ item.contact.firstName }} {{ item.contact.surname }}</span>
                      </router-link>
                    </template>

                    <!-- Replace empty rank-entries with –  -->
                    <template v-slot:item.rank="{ item }">
                      <span v-if="item.rank !== null" class="mono font-bigger">{{ item.rank }}</span>
                      <span v-else>–</span>
                    </template>

                    <!-- Replace empty scoring-entries with –  -->
                    <template v-slot:item.scoring="{ item }">
                      <span v-if="item.scoring !== null" class="mono font-bigger">{{ item.scoring }}</span>
                      <span v-else>–</span>
                    </template>

                    <!-- Add mono class to id -->
                    <template v-slot:item.id="{ item }">
                      <span class="mono small grey--text text--darken-2">{{ item.id }}</span>
                    </template>
                  </v-data-table>
                </div>

                <!-- Release-Dialog -->
                <v-dialog
                  v-model="openDialog"
                  max-width="450">
                  <v-card>
                    <v-card-title class="headline">
                      <v-icon class="ml-1 amber--text" style="margin-right: 10px">mdi-alert-outline</v-icon>
                      Achtung!
                    </v-card-title>
                    <v-card-text class="pb-0">
                      Sicher, dass das Projekt <span class="bold"> {{ selectedRankingGroup.name }} </span> ({{ selectedRankingGroupShortCode }})
                      <span class="bold"> {{selectedPeriodName }} </span>
                      zur Veröffentlichung freigegeben werden soll?
                      <v-checkbox
                        label="alle Entities sofort zur Synchronisierung markieren?"
                        v-model="push"
                      ></v-checkbox>
                      <small>Falls es Probleme bei der Erstellung eines Releases gibt (Timeouts), diese Option abwählen und manuell nach der Erstellung des Releases alle Entities zum Sync markieren.</small>
                    </v-card-text>
                    <div class="pt-1 pb-5 px-6">
                      <v-card-actions class="pt-3 pb-0 px-0">
                        <!-- ToDo: New Release in FLEDW-445 -->
                        <v-btn
                          v-on:click="newRelease"
                          @click="openDialog = false"
                          class="mr-0 ml-auto"
                          color="blue darken-3"
                          outlined
                          block>Ja, Freigeben</v-btn>
                      </v-card-actions>
                    </div>
                  </v-card>
                </v-dialog>
              </div>

              <!-- Error-Dialog -->
              <v-dialog v-model="errorDialog" max-width="400">
                <v-card class="pa-4">
                  <v-card-text>
                    <h4 class="amber--text my-4">Achtung</h4>
                    <p>
                      Der Datensatz kann nicht verarbeitet werden.<br>
                      <span v-if="notVerifiedErrorMessage">
                        Es sieht aus, als wären noch redaktionelle Verifikation offen.<br>
                        Steht noch eine Verifikation aus, wird die Freigabe zur Veröffentlichung verhindert.
                      </span>
                      <span v-if="uniqueErrorMessage">
                        Es sieht aus, als wurde dieses Release schon erstellt.<br>
                        Prüfe in der Übersicht, ob die Freigabe für <span class="bold"> {{ selectedRankingGroup.name }}
                        </span> <span class="bold"> {{selectedPeriodName }} </span> schon getätigt wurde.
                      </span>
                    </p>
                    <p v-if="notVerifiedErrorMessage" class="mb-0 small grey--text text--darken-2">
                      Original-Nachricht: {{ this.notVerifiedErrorMessage }}
                    </p>
                    <p v-if="uniqueErrorMessage" class="mb-0 small grey--text text--darken-2">
                      Original-Nachricht: {{ this.uniqueErrorMessage }}
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      href="javascript:history.go(0)"
                      color="blue darken-3"
                      class="px-2"
                      block
                      outlined>
                      Verstanden
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <div v-if="loading" class="pa-4 text-center grey--text">
                <h5 class="mt-6 mb-2">Vorschau wird geladen</h5>
                <v-progress-circular class="ml-4" color="grey" indeterminate></v-progress-circular>
              </div>
              <div class="text-right">
                <v-btn
                  v-if="toplistPreview && !loading"
                  v-on:click="openDialog = true"
                  class="mt-6 white--text"
                  color="blue darken-3"
                  depressed>
                  Alles in Ordnung! <span class="bold px-1"> {{ selectedRankingGroup.name }} </span> ({{ selectedRankingGroupShortCode }})
                  <span class="bold px-1"> {{ selectedPeriodName }} </span> jetzt freigeben
                </v-btn>
              </div>
            </v-card>

            <!-- Push-Dialog -->
            <v-dialog v-model="pushDialog" max-width="450">
              <v-card>
                <v-card-title class="green--text">
                  <v-icon color="green" class="pr-2" large>mdi-check-bold</v-icon><br>
                  <h5>Erfolg</h5>
                </v-card-title>
                <v-card-text class="pb-0">
                  Anfrage wurde erfolgreich ausgeführt. Die betroffenen Entitäten werden beim nächsten Cronjob-Lauf automatisch synchronisiert.
                </v-card-text>
                <div class="pt-1 pb-5 px-6">
                  <v-card-actions class="pt-3 pb-0 px-0">
                    <v-btn v-on:click="pushDialog = false" color="primary" outlined block>Ok</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-dialog>

            <!-- Starts process of checking data and create a new release -->
            <div v-if="!adding" class="mt-6">
              <h5>Soll eine Liste zur Publikation freigegeben werden?</h5>
              <v-btn
                v-on:click="loadReleaseFilters"
                class="my-6"
                color="blue darken-3"
                outlined>
                <v-icon class="pr-1">mdi-plus</v-icon>neue Freigabe geben
              </v-btn>
            </div>

            <!-- Shows a list of all released toplists per year -->
            <v-card v-if="!showPreview" class="mb-6 pa-4">
              <h5 class="mb-2">Freigegeben:</h5>
              <v-simple-table>
                <thead>
                <tr>
                  <th>Projekt</th>
                  <th>freigegeben von</th>
                  <th>freigegeben am</th>
                  <th>Id</th>
                  <th>markieren für Synchronisation</th>
                  <th>ES Sync Status</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in releases" :key="item.id">
                  <td>{{ item.rankingGroup.name }} - {{ item.period.name }}</td>
                  <td>{{ item.createdBy.fullName }}</td>
                  <td class="mono small grey--text text--darken-2">{{ item.createdAt }}</td>
                  <td class="mono small grey--text text--darken-2">{{ item.id }}</td>
                  <td>
                    <v-btn
                      v-on:click="pushRelease(item.id, 'elasticsearch')"
                      class="mr-3"
                      color="blue darken-3"
                      outlined>
                      ElasticSearch
                    </v-btn>
                    <v-btn
                      v-on:click="pushRelease(item.id, 'salesforce')"
                      class="mr-3"
                      color="blue darken-3"
                      outlined>
                      Salesforce
                    </v-btn>
                  </td>
                  <td>
                    <span class="mr-3">{{ item.numberOfAwardsSyncedToES }} / {{ item.numberOfAwards }}</span>
                    <v-btn
                      v-on:click="checkReleaseStatus(item.id)"
                      outlined>
                      Check
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { find } from 'lodash'

export default {
  components: {
    //
  },
  props: {
    chipLabel: {
      default: String
    }
  },
  data: () => ({
    initiated: false,
    releases: [],
    adding: false,
    rankingGroups: [{ shortCode: null, name: '' }],
    periods: [],
    toplistLabels: [{ toplistLabelId: null, name: '' }],
    previewToplistLabelsItems: [],
    items: [],
    showPreview: false,
    selectedRankingGroupShortCode: null,
    selectedPeriodName: null,
    selectedToplistLabel: null,
    loading: false,
    openDialog: false,
    toplistPreview: false,
    headers: [],
    options: {
      page: 1,
      itemsPerPage: 100
    },
    rootType: '',
    notVerifiedErrorMessage: '',
    uniqueErrorMessage: '',
    errorDialog: false,
    pushDialog: false,
    push: true
  }),
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    const calls = []
    // promise of loading releases from backend
    const releaseListPromise = this.getReleaseList()
    calls.push(releaseListPromise)

    // Collects all promises
    await Promise.all(calls)
    this.initiated = true
  },
  methods: {
    getReleaseList: async function () {
      const payload = {
        query: '{ releases { id, createdAt, createdBy { id, fullName }, rankingGroup { id, name }, period { id, name } } }'
      }
      const response = await this.$http.post('/warehouse-graphql', payload)

      // Init in placeholders for Release metrics.
      response.data.data.releases.forEach(function (element) {
        element.numberOfAwardsSyncedToES = '?'
        element.numberOfAwards = '?'
      })

      this.releases = response.data.data.releases
    },
    checkReleaseStatus: async function (id) {
      const response = await this.$http.get('/release/status/' + id)

      const releaseIndex = this.releases.findIndex(obj => obj.id === id)
      this.releases[releaseIndex].numberOfAwardsSyncedToES = response.data.numberOfAwardsSyncedToES
      this.releases[releaseIndex].numberOfAwards = response.data.numberOfAwards
    },
    loadReleaseFilters: async function () {
      this.adding = true

      const calls = []

      // promise of loading ranking groups from backend
      const rankingGroupPromise = this.$http.get('/ranking_group/list')
        .then((response) => {
          this.rankingGroups = response.data
        })
      calls.push(rankingGroupPromise)

      // promise of loading periods from backend
      const periodPromise = this.$http.get('/period/list')
        .then((response) => {
          this.periods = response.data
        })
      calls.push(periodPromise)

      // Collects all promises
      await Promise.all(calls)
    },
    loadPreview: function () {
      this.loading = true
      const urlParams = new URLSearchParams()
      // checks for a selected ranking group
      if (this.selectedRankingGroupShortCode !== null) {
        urlParams.append('rgShortCode', this.selectedRankingGroupShortCode)
      }
      // checks for a selected period
      if (this.selectedPeriodName !== '') {
        urlParams.append('periodName', this.selectedPeriodName)
      }
      // promise of loading toplists from backend
      return this.$http.get('/toplist_label/list?' + urlParams.toString())
        .then((response) => {
          this.toplistLabels = response.data.list
          this.rootType = 'contact'
          if (this.selectedRankingGroup.rootType.endsWith('Account')) {
            this.rootType = 'account'
          }
          this.loading = false
        })
    },
    loadPreviewToplistLabelItems: async function () {
      this.items = []
      this.headers = [
        { text: 'Id', value: 'id', sortable: false },
        { text: 'Name', value: 'shortName' },
        { text: 'Rang', value: 'rank' },
        { text: 'Score', value: 'scoring' }
      ]
      this.loading = true
      const page = this.options.page - 1
      const limit = this.options.itemsPerPage
      const start = page * limit
      let url = '/award/list?' + '&offset=' + start + '&limit=' + limit
      if (this.selectedRankingGroupShortCode !== null) {
        url += '&rgShortCode=' + decodeURIComponent(this.selectedRankingGroupShortCode)
      }
      if (this.selectedPeriodName !== '') {
        url += '&periodName=' + decodeURIComponent(this.selectedPeriodName)
      }
      if (this.selectedToplistLabel !== null) {
        url += '&toplistLabelId=' + decodeURIComponent(this.selectedToplistLabel)
      }
      const response = await this.$http.get(url)
      this.items = response.data.list
      console.log('items list:', this.items)
      this.total = response.data.total
      this.loading = false
      this.toplistPreview = true
    },
    newRelease: async function () {
      this.loading = true
      // creates a new release
      const payload = {
        periodId: this.selectedPeriod.id,
        rankingGroupId: this.selectedRankingGroup.id,
        push: this.push
      }
      await this.$http.post('/release', payload)
        .catch((e) => {
          if (e.response.data.class === 'App\\Exception\\ReleaseHinderedException') {
            // Display error message, if data is not fully approved
            this.notVerifiedErrorMessage = e.response.data.message
            this.errorDialog = true
          }
          if (e.response.data.class === 'Doctrine\\DBAL\\Exception\\UniqueConstraintViolationException') {
            // Display error message, if already released,
            this.uniqueErrorMessage = e.response.data.message
            this.errorDialog = true
          }
          throw e
        })
      // clears input and resets select
      this.selectedPeriodName = null
      this.selectedRankingGroupShortCode = null
      // loads new release list
      await this.getReleaseList()
      this.openDialog = false
      this.loading = false
      this.showPreview = false
      location.reload()
    },
    pushRelease: function (id, destination) {
      this.loading = true
      // push release
      this.$http.post('/release/push/' + id + '/' + destination)
        .catch((e) => {
          if (e.response.data.class === 'App\\Exception\\ReleaseHinderedException') {
            // Display error message, if data is not fully approved
            this.notVerifiedErrorMessage = e.response.data.message
            this.errorDialog = true
          }
          throw e
        })
        .then((response) => {
          this.pushDialog = true
        })
      this.loading = false
    }
  },
  computed: {
    toplistLabelsByName () {
      if (this.toplistLabels.length === null) {
        return []
      }
      // sorts a copy of toplist-list alphabetically
      return this.toplistLabels.slice().sort((d1, d2) => d1.value.localeCompare(d2.value))
    },
    selectedRankingGroup () {
      if (this.selectedRankingGroupShortCode === null) {
        return null
      }
      return find(this.rankingGroups, { shortCode: this.selectedRankingGroupShortCode })
    },
    selectedPeriod () {
      if (this.selectedPeriodName === null) {
        return null
      }
      return find(this.periods, { name: this.selectedPeriodName })
    },
    // sorting items in data table according to various criteria
    sortedItems () {
      if (this.items !== null) {
        // sorts a copy of items, first by rank (from 1st), than by scoring (from highest scoring)
        return this.items.slice().sort((a, b) => a.rank - b.rank || b.scoring - a.scoring)
      }
      // if ranks and scorings not available items will be sort alphabetically by shortName or surname
      return this.itemsSortedByName
    },
    itemsSortedByName () {
      if (this.selectedRankingGroup.rootType.endsWith('Account')) {
        return this.items.slice().sort((d1, d2) => d1.account.name.localeCompare(d2.account.name))
      }
      return this.items.slice().sort((d1, d2) => d1.contact.surname.localeCompare(d2.contact.surname))
    }
  }
}
</script>

<style scoped>
  .column-width {
    min-width: 120px;
  }
</style>
