<template>
  <div class="relative mb-10">
    <p class="overline amber--text label mb-0 text-right absolute">{{ main ? 'Main ' : '' }}Account {{ account.category !== null ? '(' + account.category.name + ')' : '' }}</p>
    <v-card class="pa-12 mb-2">
      <div class="absolute context-menu right mr-2 ml-auto" style="top: 8px;" v-if="!inspectionMode">
        <Approve
          v-if="!account.isApproved && !account.excludedByEditor"
          targetClass="account"
          :id="account.id"></Approve>
        <DetailContext targetClass="account" :id="account.id"></DetailContext>
      </div>
      <div v-if="account.excludedByEditor">
        <v-chip class="mb-2 grey--text text--darken-2" outlined>
          <v-icon left>mdi-alert-circle-outline</v-icon>
          Account redaktionell ausgeschlossen
        </v-chip>
      </div>
      <div v-if="account.markedForFollowUp">
        <v-chip class="mb-2 grey--text text--darken-2" outlined>
          <v-icon left>mdi-help-circle-outline</v-icon>
          Account für Follow Up (Rückfrage) markiert
        </v-chip>
      </div>

      <InputSwitch
        label="Name"
        v-model="account.name"
        counter="255"
        required
        :entryId="account.id"
        targetClass="account"
        field="name"
        :readonly="readOnlyMode"
        :inspectionMode="inspectionMode"
        :loadChanges="loadChanges"
      ></InputSwitch>

      <InputSwitch
        v-model="account.shortName"
        :counter="255"
        label="Name (Druckversion, kurz)"
        required
        :entryId="account.id"
        targetClass="account"
        field="shortName"
        :readonly="readOnlyMode"
        :inspectionMode="inspectionMode"
        :loadChanges="loadChanges"
      ></InputSwitch>

      <InputSwitch
        v-model="account.street"
        :counter="255"
        label="Straße (inkl. Hausnummer)"
        required
        :entryId="account.id"
        targetClass="account"
        field="street"
        :readonly="readOnlyMode"
        :inspectionMode="inspectionMode"
        :loadChanges="loadChanges"
      ></InputSwitch>

      <InputSwitch
        v-model="account.city"
        :counter="50"
        label="Stadt"
        required
        :entryId="account.id"
        targetClass="account"
        field="city"
        :readonly="readOnlyMode"
        :inspectionMode="inspectionMode"
        :loadChanges="loadChanges"
      ></InputSwitch>

      <InputSwitch
        v-model="account.zipCode"
        :counter="5"
        label="Postleitzahl"
        required
        :entryId="account.id"
        targetClass="account"
        field="zipCode"
        :readonly="readOnlyMode"
        :inspectionMode="inspectionMode"
        :loadChanges="loadChanges"
      ></InputSwitch>

      <InputSwitch
        v-model="account.salesforceId"
        label="SalesforceID"
        :entryId="account.id"
        targetClass="account"
        :readonly="readOnlyMode"
        field="salesforceId"
        :inspectionMode="inspectionMode"
        :loadChanges="loadChanges"
      ></InputSwitch>

      <InputSwitch
        v-model="account.salesforceRecordTypeId"
        label="Salesforce RecordType"
        :entryId="account.id"
        targetClass="account"
        :readonly="readOnlyMode"
        field="salesforceRecordTypeId"
        :inspectionMode="inspectionMode"
        :loadChanges="loadChanges"
        :selectables="[
          { text: '(unset)', value: null },
          { text: 'RECORD_TYPE_ACCOUNT_GESUNDHEIT', value: '012080000015CgOAAU' },
          { text: 'RECORD_TYPE_ACCOUNT_MERCHANDISING', value: '01224000000kN6wAAE' },
          { text: 'RECORD_TYPE_ACCOUNT_ARZTSUCHE', value: '01224000000kN6vAAE' },
          { text: 'RECORD_TYPE_ACCOUNT_ITALY', value: '01208000000oZtYAAU' }
        ]"
      ></InputSwitch>

      <InputSwitch
        v-model="account.salesforceDestinationMarket"
        label="Salesforce Destination Market"
        :entryId="account.id"
        targetClass="account"
        :readonly="readOnlyMode"
        field="salesforceDestinationMarket"
        :inspectionMode="inspectionMode"
        :loadChanges="loadChanges"
        :selectables="[
          { text: '(unset)', value: null },
          { text: '🇩🇪 Deutschland', value: 'DE' },
          { text: '🇮🇹 Italien', value: 'IT' },
          { text: '🇫🇷 Frankreich', value: 'FR' },
        ]"
      ></InputSwitch>

      <InputSwitch
        v-model="account.excludedFromSalesforce"
        label="state_SF_inaktiv"
        :entryId="account.id"
        targetClass="account"
        :readonly="readOnlyMode"
        field="excludedFromSalesforce"
        :inspectionMode="inspectionMode"
        :loadChanges="loadChanges"
        :selectables="[{text:'true', value: true}, {text:'false', value: false}]"
      ></InputSwitch>

      <InputSwitch
        v-if="inspectionMode"
        v-model="account.excludedByEditor"
        label="excludedByEditor"
        :entryId="account.id"
        targetClass="account"
        :readonly="readOnlyMode"
        field="excludedByEditor"
        :inspectionMode="inspectionMode"
        :loadChanges="loadChanges"
        :selectables="[{text:'true', value: true}, {text:'false', value: false}]"
      ></InputSwitch>

      <div class="text-center" v-if="!showMoreInfoForChanges">
        <v-btn
          v-if="!showMoreInfoToggle"
          v-on:click="showMoreInfoToggle = true"
          class="my-5"
          text>
          Mehr Informationen <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          v-if="showMoreInfoToggle"
          v-on:click="showMoreInfoToggle = false"
          class="my-5"
          text>
          Weniger Informationen <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </div>

      <!--
        Decides whether Information are shown on first sight
        if account is root Element
        or information is shown after click
        or changes exist.
        -->
      <div class="grey lighten-4 mb-4" v-show="showMoreInfo">
        <InputSwitch
          v-model="account.region"
          :counter="50"
          label="Bundesland"
          :entryId="account.id"
          targetClass="account"
          field="region"
          :readonly="readOnlyMode"
          :inspectionMode="inspectionMode"
          :loadChanges="loadChanges"
        ></InputSwitch>

        <InputSwitch
          v-model="account.countryCode"
          :counter="2"
          label="Land (ISO 3166-2 Code)"
          required
          :entryId="account.id"
          targetClass="account"
          field="countryCode"
          :readonly="readOnlyMode"
          :inspectionMode="inspectionMode"
          :loadChanges="loadChanges"
        ></InputSwitch>

        <InputSwitch
          v-model="account.telephoneNumber"
          :counter="50"
          label="Telefon"
          :entryId="account.id"
          targetClass="account"
          field="telephoneNumber"
          :readonly="readOnlyMode"
          :inspectionMode="inspectionMode"
          :loadChanges="loadChanges"
          :data-type="'TYPE_STRING'"
        ></InputSwitch>

        <InputSwitch
          v-model="account.faxNumber"
          :counter="50"
          label="Fax"
          :entryId="account.id"
          targetClass="account"
          field="faxNumber"
          :readonly="readOnlyMode"
          :inspectionMode="inspectionMode"
          :loadChanges="loadChanges"
          :data-type="'TYPE_STRING'"
        ></InputSwitch>

        <InputSwitch
          v-model="account.emailAddress"
          :counter="100"
          label="E-Mail"
          :entryId="account.id"
          targetClass="account"
          field="emailAddress"
          :readonly="readOnlyMode"
          :inspectionMode="inspectionMode"
          :loadChanges="loadChanges"
        ></InputSwitch>

        <InputSwitch
          v-model="account.website"
          :counter="255"
          label="Webseite"
          :entryId="account.id"
          targetClass="account"
          field="website"
          :readonly="readOnlyMode"
          :inspectionMode="inspectionMode"
          :loadChanges="loadChanges"
        ></InputSwitch>

        <InputSwitch
          v-model="account.shortWebsite"
          :counter="50"
          label="Webseite (Druckversion, kurz)"
          :entryId="account.id"
          targetClass="account"
          field="shortWebsite"
          :readonly="readOnlyMode"
          :inspectionMode="inspectionMode"
          :loadChanges="loadChanges"
        ></InputSwitch>

        <InputSwitch
          v-model="account.longitude"
          :counter="11"
          label="Geographische Länge (Longitude)"
          :entryId="account.id"
          targetClass="account"
          field="longitude"
          :readonly="readOnlyMode"
          :inspectionMode="inspectionMode"
          :loadChanges="loadChanges"
          :data-type="'TYPE_DOUBLE'"
        ></InputSwitch>

        <InputSwitch
          v-model="account.latitude"
          :counter="10"
          label="Geographische Breite (Latitude)"
          :entryId="account.id"
          targetClass="account"
          :readonly="readOnlyMode"
          field="latitude"
          :inspectionMode="inspectionMode"
          :loadChanges="loadChanges"
          :data-type="'TYPE_DOUBLE'"
        ></InputSwitch>

        <InputSwitch
          v-model="account.description"
          :counter="1000"
          label="Beschreibung"
          :entryId="account.id"
          targetClass="account"
          field="description"
          :readonly="readOnlyMode"
          :inspectionMode="inspectionMode"
          :loadChanges="loadChanges"
        ></InputSwitch>
      </div>

      <v-btn
        v-if="!showSupplier"
        v-on:click="showSupplier = true"
        class="px-0 mx-0 mb-0 grey--text text--darken-2 mono"
        elevation="0"
        color="white">
        Lieferanten <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
      <v-btn
        v-if="showSupplier"
        v-on:click="showSupplier = false"
        class="px-0 mx-0 mb-0 grey--text text--darken-2 mono"
        elevation="0"
        color="white">
        Lieferanten <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
      <div v-show="showSupplier" class="mb-0 pt-3">
        <p v-for="item in account.supplierIds" :key="item.value + item.scope" class="grey--text text--darken-2 small">
          <span>
            <v-icon
              small
              @click="deleteSupplierId(item)"
            >mdi-delete</v-icon>
          </span>
          <span class="mb-1">{{ item.supplier.name }} ({{ item.scope }}): </span>
          <span class="mono mb-0">{{ item.value }}</span>
        </p>
      </div>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline red--text">Achtung!</v-card-title>
          <v-card-text>
            <p>SupplierIdentification wirklich löschen? Diese Operation ist nicht umkehrbar, nur bei 100% Sicherheit ausführen!</p>
            <p>Um den Löschvorgang fortzusetzen bitte "LÖSCHEN" in das untenstehende Textfeld eingeben und bestätigen.</p>
            <v-text-field v-model="confirmString" label="Bestätigungsstring"></v-text-field>
            <p v-if="this.confirmationInvalid === true" class="red--text">Bestätigungsstring ist nicht korrekt.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteSupplierIdCancel">abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="deleteSupplierIdConfirm(confirmString)">
              <v-progress-circular v-if="deleting === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
              <span v-if="deleting === false">Löschen</span>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card>

  </div>
</template>

<script>
import Approve from './Approve'
import DetailContext from './DetailContext'
import InputSwitch from './InputSwitch'

export default {
  props: {
    account: Object,
    readonly: {
      type: Boolean,
      default: false
    },
    inspectionMode: {
      default: false
    },
    rootType: {
      default: ''
    },
    loadChanges: {
      type: Boolean,
      default: true
    },
    main: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Approve,
    DetailContext,
    InputSwitch
  },
  data: () => ({
    showMoreInfoToggle: false,
    showSupplier: false,
    changes: {},
    dialogDelete: false,
    supplierIdForDeletion: null,
    deleting: false,
    confirmString: '',
    confirmationInvalid: null
  }),
  created () {
    this.$state.addToRegistry(this.account)
    if (this.loadChanges) {
      this.changes = this.$changeService.getPendingChangesByTarget(this.account.id, 'account')
    }
    if (this.rootType === 'account') {
      this.showMoreInfoToggle = true
    }
  },
  methods: {
    deleteSupplierId (item) {
      this.dialogDelete = true
      this.supplierIdForDeletion = item
    },
    deleteSupplierIdConfirm: async function (confirmString) {
      if (confirmString === 'LÖSCHEN') {
        this.deleting = true
        await this.$http.delete('/supplier-identification/delete/' + this.supplierIdForDeletion.id)
          .finally(() => {
            this.deleting = false
            this.dialogDelete = false
            this.supplierIdForDeletion = null
          })
      } else {
        this.confirmationInvalid = true
      }
    },
    deleteSupplierIdCancel () {
      this.dialogDelete = false
      this.supplierIdForDeletion = null
    }
  },
  computed: {
    readOnlyMode: function () {
      if (this.readonly === true) {
        return true
      }
      if (this.account.excludedByEditor === true) {
        return true
      }
      if (this.account.isApproved === false) {
        return true
      }
      return false
    },
    showMoreInfoForChanges: function () {
      return Object.keys(this.changes).length !== 0
    },
    showMoreInfo: function () {
      if (this.showMoreInfoToggle) {
        return true
      }
      if (this.showMoreInfoForChanges) {
        return true
      }
      return false
    }
  }
}
</script>
