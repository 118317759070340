<template>

  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <h5>Ausgeschlossene Wörter</h5>
          <p class="explanation body-2 pt-3 pr-3 mb-0">
            Bei diesem Check, wird geprüft, ob sich ein im Voraus ausgeschlossenes Wort in der Lieferung befindet.
          </p>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="profile === true">
        <v-icon color="light-green">mdi-checkbox-marked-circle-outline</v-icon>
        Ok. Es wurden keine ausgeschlossenen Wörter gefunden.
      </v-expansion-panel-content>
      <v-expansion-panel-content v-else>
        <p class="pb-2">
          <v-icon color="amber">mdi-alert-outline</v-icon>
          Warnung! Es wurden Wörter gefunden, die wohlmöglich nicht sauber verarbeitet werden können. Eine Prüfung wird empfohlen.
        </p>
        <ul>
          <li v-for="(row, rowIndex) in sub" class="column" >
            Einträge in Zeile {{ rowIndex }} ...
            <p v-for="(value, header) in row" class="mb-2">... Spalte: "<span class="monospace font-bigger">{{ header }}</span>" überprüfen.</p>
          </li>
        </ul>
        <p class="explanation body-2 pt-3 mb-0">Als ausgeschlossene Wörter definiert sind: 'null', 'false', 'n/a', 'empty', 'zero', 'true', '.', '-'</p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
export default {
  props: [
    'profile',
    'sub',
    'header'
  ]
}
</script>
