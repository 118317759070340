import Vue from 'vue'
// Plugins
import { Auth0Plugin } from './plugins/auth'
import { ApplicationState } from './plugins/state'
import { ChangeService } from '@/plugins/ChangeService'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import axios from 'axios'
// Auth0 configuration
import { apiUrl, domain, clientId, audience } from '../auth_config.json'

/**
 * Routing: $router
 */
import Start from './views/Start'
import Detail from './views/Detail'
import List from './views/List'
import Verification from './views/Verification'
import Quickverification from './views/Quickverification'
import Upload from './views/Upload'
import Export from './views/Export'
import Analyse from './views/Analyse'
import Import from './views/Import'
import Users from './views/Users'
import Suppliers from './views/Suppliers'
import Admin from './views/Admin'
import ToplistLabels from './views/ToplistLabels'
import ManagedDynamicLabels from './views/ManagedDynamicLabels'
import MappingEditor from './views/MappingEditor'
import Release from './views/Release'
import Translation from './views/Translation'
import Samples from './views/Samples'
import System from './views/System'
import GeoDataRevision from './views/GeoDataRevision'
import RankingGroups from './views/RankingGroups'
import ApiImport from './views/ApiImport'
import Mapping from './views/Mapping'
import Conflicts from './views/Conflicts'
import Taxonomy from './views/Taxonomy'
import SalesforceSync from './views/SalesforceSync'
import Elasticsearch from './views/Elasticsearch'
import FollowUpList from './views/FollowUpList'
import MultiMinqIdsConflictResolve from './views/MultiMinqIdsConflictResolve'
import EntityAmbiguityConflictResolve from './views/EntityAmbiguityConflictResolve'
import AwardDuplication from './views/AwardDuplication'
import Templates from './views/Templates'
import Periods from './views/Periods'
import App from './App.vue'

Vue.use(ApplicationState, {})
Vue.use(ChangeService, {})

Vue.use(VueRouter)
const router = new VueRouter({
  routes: [
    { path: '/', component: Start },
    { path: '/projekt/:rankingGroupCode', component: List },
    { path: '/verifikation', component: Verification },
    { path: '/verifikation/kurz', component: Quickverification },
    { path: '/upload', component: Upload },
    { path: '/api-import', component: ApiImport },
    { path: '/export', component: Export },
    { path: '/analyse/:sampleId', component: Analyse },
    { path: '/import/:sampleId', component: Import },
    { path: '/verwaltung', component: Admin },
    { path: '/verwaltung/benutzer', component: Users },
    { path: '/verwaltung/lieferanten', component: Suppliers },
    { path: '/verwaltung/toplisten', component: ToplistLabels },
    { path: '/verwaltung/dynamische-felder', component: ManagedDynamicLabels },
    { path: '/verwaltung/ranking-groups', component: RankingGroups },
    { path: '/verwaltung/translation', component: Translation },
    { path: '/verwaltung/samples', component: Samples },
    { path: '/verwaltung/salesforce-sync', component: SalesforceSync },
    { path: '/verwaltung/system', component: System },
    { path: '/verwaltung/geodaten-abgleich', component: GeoDataRevision },
    { path: '/verwaltung/mapping', component: Mapping },
    { path: '/verwaltung/taxonomy', component: Taxonomy },
    { path: '/verwaltung/elasticsearch', component: Elasticsearch },
    { path: '/verwaltung/periods', component: Periods },
    { path: '/mapping-editor', component: MappingEditor },
    { path: '/award-duplikation', component: AwardDuplication },
    { path: '/freigabe', component: Release },
    { path: '/konflikte', component: Conflicts },
    { path: '/minq-konflikt/:id', component: MultiMinqIdsConflictResolve },
    { path: '/ea-konflikt/:id', component: EntityAmbiguityConflictResolve },
    { path: '/follow-up', component: FollowUpList },
    { path: '/templates', component: Templates },
    { path: '/:rootType/:id', component: Detail }
  ]
})

/**
 * Axios HTTP: $http
 */
Vue.prototype.$http = axios.create({
  baseURL: apiUrl,
  timeout: 20 * 60 * 1000 // 20 minutes
})
// Add automatic loading-state tracking
Vue.prototype.$http.interceptors.request.use((config) => {
  Vue.prototype.$state.addLoader()
  return config
})
// Response handler
Vue.prototype.$http.interceptors.response.use((response) => {
  Vue.prototype.$state.resolveLoader()
  return response
}, (error) => {
  Vue.prototype.$state.resolveLoader()
  if (error.response) {
    error.response.time = Date.now()
    Vue.prototype.$state.addNotification(error.response)
  } else {
    Vue.prototype.$state.addNotification(error)
  }
  console.log(error)
  return Promise.reject(error)
})

/**
 * Auth0 Authentication: $auth
 */
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

Vue.config.productionTip = false

/**
 * Vue root
 */
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
