<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row>
        <v-col sm="12">
          <h1>Geo-Daten Abgleich</h1>
          <p>
            Hier können die Geo-Daten der Accounts mit Hilfe von Google ergänzt werden.<br>
            Pro Durchlauf werden die frischesten 250 Accounts verarbeitet (updatedAt Feld).<br>
            Voraussetzung dafür ist:
          </p>
          <ul>
            <li>Account enthält halbwegs vollständige Addressdaten (PLZ, Stadt, Länderkürzel)</li>
            <li>Account ist approved</li>
            <li>Geo-Daten sind noch nicht vollständig (longitude und/oder latitude sind leer)</li>
          </ul>
          <p class="mt-3">Die neuen Geo-Daten werden sofort beim Account gespeichert, natürlich revisionssicher als Change.</p>
        </v-col>
      </v-row>
      <v-row v-show="initiated">
        <v-col sm="12">
          <div>
            <v-card class="mb-6">
              <v-simple-table>
                <thead>
                <tr>
                  <th class="text-left column-width">Name</th>
                  <th class="text-left column-width">Wert</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="metric in geoDataInformation" v-bind:key="metric.name">
                  <td>{{ metric.name }}</td>
                  <td>{{ metric.value }}</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </div>
          <div class="text-center">
            <v-btn primary v-on:click="bulkRevise">
              <v-progress-circular v-if="geoDataLoading === true" color="grey" class="mr-2" :width="3" indeterminate></v-progress-circular>
              <span v-if="geoDataLoading === true">Bitte warten</span>
              <span v-else>Geo-Daten abgleichen</span>
            </v-btn>
          </div>
          <v-dialog v-model="geoDataDialog" max-width="1000px">
            <v-card class="pa-4">
              <v-card-title>
                <span class="headline">Resultat</span>
              </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <span v-for="(result, index) in geoDataReviseResult">
                        {{ index }}: {{ result.message }}<br>
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  v-on:click="reload"
                >
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    geoDataInformation: [],
    geoDataReviseResult: null,
    geoDataDialog: null,
    geoDataLoading: false,
    loading: false
  }),
  methods: {
    getList: async function () {
      const response = await this.$http.get('/geo-data/overview')
      this.geoDataInformation = response.data
    },
    bulkRevise: async function () {
      this.loading = true
      this.geoDataLoading = true
      const response = await this.$http.get('/geo-data/revise-bulk')
      this.geoDataReviseResult = response.data
      this.geoDataLoading = false
      this.geoDataDialog = true
      this.loading = false
    },
    reload: function () {
      window.location.reload()
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    await this.getList()
    this.initiated = true
  }
}
</script>
