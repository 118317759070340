<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row>
        <v-col sm="12">
          <h1>Periods</h1>
          <h3>Anleitung</h3>
          <p>Hier können (Auszeichnungs-)Perioden gepflegt werden.</p>
          <p>Perioden können aktuell nicht umbenannt oder gelöscht werden, da es harte Abhängigkeiten in im Data Warehouse selbst als auch in Drittsystemen geben kann. Bei Bedarf muss das in Abstimmung mit und letztendlich über die IT geschehen.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-data-table
                :headers="headers"
                :items="periods"
                :items-per-page="50"
                class="elevation-1"
                sort-by="name"
                sort-desc>
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="pr-1">mdi-plus</v-icon> Periode hinzufügen
                        </v-btn>
                      </template>
                      <v-card class="pa-3">
                        <v-card-title>
                          <span class="headline">Periode Hinzufügen</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container fluid>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.name"
                                  label="Name"
                                  hint="Der nach außen hin sichtbare Name der Periode."
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            @click="close"
                            :disabled="loading === true">
                            abbrechen
                          </v-btn>
                          <v-btn
                            color="success"
                            @click="save"
                            :disabled="loading === true">
                            <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
                            <span v-if="loading === false">Speichern</span>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.id="{ item }">
                  <span class="monospace">{{ item.id }}</span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <small>{{ new Date(item.createdAt).toLocaleString() }}</small>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    periods: [],
    loading: false,
    dialog: false,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Anzahl Awards', value: 'awardsCount' },
      { text: 'Anzahl Scores', value: 'scoresCount' },
      { text: 'Anzahl Samples', value: 'samplesCount' },
      { text: 'Created At', value: 'createdAt' }
    ],
    editedIndex: -1,
    editedItem: {
      name: ''
    },
    defaultItem: {
      name: ''
    }
  }),
  methods: {
    getList: async function () {
      const response = await this.$http.get('/period/list')
      this.periods = response.data
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save: async function () {
      this.loading = true

      const payload = {
        name: this.editedItem.name
      }

      if (this.editedIndex > -1) {
        // No editing for now, as periods can not be simply edited (dependencies to ES, SF, etc.)
      } else {
        // Create new.
        await this.$http.post('/period', payload)
          .then((response) => {
            this.loading = false
            this.close()
            this.getList()
          })
          .catch((e) => {
            this.loading = false
            this.close()
          })
      }
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    await this.getList()
    this.initiated = true
  }
}
</script>
