<template>

  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <h5>Zeilen-Länge</h5>
          <p class="explanation body-2 pt-3 pr-3 mb-0">
            Es wurde überprüft, ob die Anzahl der Einträge in allen Zeilen gleich ist.<br>
            Anbei wird die Anzahl der Einträge in einer Zeile angegeben.
          </p>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="profile === true">
        <p class="mb-0">
          <v-icon color="light-green">mdi-checkbox-marked-circle-outline</v-icon>
          Ok.
        </p>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-else>
        <p class="mb-0">
          <v-icon color="red darken-1">mdi-close-circle-outline</v-icon>
          Achtung! In der Lieferung unterscheiden sich Zeilenlängen. Vermutlich liegen Defekte vor.
        </p>
      </v-expansion-panel-content>
      <v-expansion-panel-content class="row-count">
        <v-simple-table>
          <tr>
            <td class="column">Zeile 1</td>
            <td class="column monospace">{{ sub['1'] }}</td>
          </tr>
          <tr v-for="(value, rowIndex) in oddSubs">
            <td class="column">Zeile {{ rowIndex }}</td>
            <td class="column monospace">{{ value }}</td>
          </tr>
        </v-simple-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
import { filter, indexOf } from 'lodash'

export default {
  props: [
    'profile',
    'sub'
  ],
  data: () => ({
    odds: []
  }),
  created () {
    const firstRowValue = this.sub['1']
    this.odds = filter(this.sub, (value) => {
      return value !== firstRowValue
    })
  },
  methods: {
    isOdd (value) {
      return indexOf(this.odds, value) !== -1
    }
  },
  computed: {
    oddSubs: function () {
      return filter(this.sub, (item) => {
        return this.isOdd(item.value)
      })
    }
  }
}
</script>
