<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row>
        <v-col sm="12">
          <h1>Follow Up</h1>
          <p>Eine Auflistung aller zur Rückfrage (Follow Up) markierter Objekte.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-row class="pl-4 pr-4 pt-4">
                <div class="col-sm-12 col-md-4">
                  <v-select
                    v-model="selectedRankingGroupId"
                    v-on:change="getList"
                    label="Projekt auswählen"
                    :items="rankingGroups"
                    item-text="name"
                    item-value="id">
                  </v-select>
                </div>
                <div class="col-sm-12 col-md-4">
                  <v-select
                    v-model="selectedPeriodName"
                    v-on:change="getList"
                    label="Jahrgang auswählen"
                    :items="periodNames"
                    item-text="name"
                    item-value="id">
                  </v-select>
                </div>
              </v-row>
              <v-row class="pl-4 pr-4 pt-4">
                <div class="col-sm-12 col-md-12">
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Suche"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                </div>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="entities"
                :search="search"
                :items-per-page="50"
                class="elevation-1 follow-up-table"
                sort-by="updatedAt"
                :sort-desc=true>
                <template #item.id="{ item }">
                  <span class="mono">{{ item.id }}</span>
                </template>
                <template #item.name="{ item }">
                  <router-link v-if="item.class === 'App\\Entity\\Account'" :to="'/account/' + item.id" target="_blank">{{ item.name }}</router-link>
                  <router-link v-else :to="'/contact/' + item.id" target="_blank">{{ item.surname }} {{ item.firstName }}</router-link>
                </template>
                <template #item.createdAt="{ item }">
                  {{ new Date(item.createdAt).toLocaleString() }}
                </template>
                <template #item.updatedAt="{ item }">
                  {{ item.updatedAt ? new Date(item.updatedAt).toLocaleString() : '' }}
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    entities: [],
    loading: false,
    search: '',
    headers: [
      { text: 'Id', value: 'id', sortable: false },
      { text: 'Name', value: 'name' },
      { text: 'Vorname', value: 'firstName', class: 'd-none' },
      { text: 'Nachname', value: 'surname', class: 'd-none' },
      { text: 'Created At', value: 'createdAt' },
      { text: 'Updated At', value: 'updatedAt' }
    ],
    selectedRankingGroupId: null,
    selectedPeriodName: null,
    rankingGroups: [{ id: null, name: 'Alle Projekte' }],
    periodNames: [{ id: null, name: 'Alle' }]
  }),
  methods: {
    getList: async function () {
      const urlParams = new URLSearchParams()
      if (this.selectedRankingGroupId !== null) {
        urlParams.append('rgId', this.selectedRankingGroupId)
      }
      if (this.selectedPeriodName !== null) {
        urlParams.append('periodId', this.selectedPeriodName)
      }

      console.log('/list/follow-up?' + urlParams.toString())
      const response = await this.$http.get('/list/follow-up?' + urlParams.toString())
      this.entities = response.data
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    const calls = []
    // promise of loaded loadItems
    // let loadItemsPromise = this.loadItems()
    // calls.push(loadItemsPromise)
    // promise of loaded rankingGroups
    const rankingGroupPromise = this.$http.get('/ranking_group/list')
      .then((response) => {
        this.rankingGroups = this.rankingGroups.concat(response.data)
      })
    calls.push(rankingGroupPromise)

    const periodPromise = this.$http.get('/period/list')
      .then((response) => {
        this.periodNames = this.periodNames.concat(response.data)
      })
    calls.push(periodPromise)
    // collects all promises
    await Promise.all(calls)

    await this.getList()
    this.initiated = true
  }
}
</script>

<style>
  .follow-up-table tbody tr td:nth-child(3), .follow-up-table tbody tr td:nth-child(4) {
    display: none;
  }
</style>
