<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row>
        <v-col sm="12">
          <h1>System</h1>
          <p>Eine Übersicht der Systeminformationen.</p>
        </v-col>
        <v-col class="text-right">
          <p>Hier kann ein DB Dump erstellt und im Anschluss runtergeladen werden. Nur für die IT. "Slim" Version enthält die Changes Tabelle nicht.</p>
          <v-btn class="ml-3 mb-3 primary" v-on:click="dbDumpTrigger(false)">Dump erstellen</v-btn>
          <v-btn class="ml-3 mb-3 primary" v-on:click="dbDumpTrigger(true)">Dump erstellen (Slim)</v-btn>
        </v-col>
        <v-col sm="12">
          <p>Customized Dump:</p>
          <v-select
            v-model="selectedTables"
            :items="tables"
            label="Basistabellen auswählen"
            multiple
            hint="Mehrfachauswahl möglich. Dump enthält nur die ausgewählten Tabellen."
            persistent-hint
          ></v-select>
          <v-btn class="mb-3 mt-3 primary" v-on:click="dbDumpTrigger()">Dump erstellen</v-btn>
        </v-col>
        <v-col sm="12" v-show="initiated">
          <p>Bisherige Dumps von aktueller Instanz:</p>
          <v-card>
            <v-simple-table v-if="!loading">
              <thead>
              <tr>
                <th class="text-left column-width">Name</th>
                <th class="text-left column-width">Download</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(dump, index) in dbDumps">
                <td>{{ dump }}</td>
                <td>
                  <v-btn class="my-3 primary" v-on:click="dbDumpDownload(index)"><v-icon class="mr-1">mdi-download</v-icon> {{ index }}</v-btn>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-simple-table v-if="!loading">
                <thead>
                  <tr>
                    <th class="text-left column-width" style="min-width: 250px;">Name</th>
                    <th class="text-left column-width">Wert</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="metric in systemInformation" v-bind:key="metric.name">
                    <td>{{ metric.name }}</td>
                    <td v-if="Array.isArray(metric.value)">
                      <code class="d-inline-block my-3">
                        <span v-for="metricLine in metric.value">{{ metricLine }}<br></span>
                      </code>
                    </td>
                    <td v-else>
                      {{ metric.value }}
                    </td>
                  </tr>
                  <tr>
                    <td>Vue Timeout</td>
                    <td>{{ this.$http.defaults.timeout / 1000 }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    systemInformation: [],
    dbDumps: [],
    loading: false,
    tables: [
      'account',
      'account_contact',
      'award',
      'changes',
      'conflict',
      'conflict_member_reference',
      'contact',
      'dictionary',
      'lock_keys',
      'managed_dynamic_field',
      'managed_dynamic_label',
      'mapping',
      'mapping_column',
      'messages',
      'period',
      'product',
      'ranking_group',
      'release',
      'sample',
      'score',
      'string_translation',
      'supplier',
      'supplier_award',
      'supplier_identification',
      'supplier_score',
      'taxonomy_term',
      'toplist_label',
      'user'
    ],
    selectedTables: []
  }),
  methods: {
    getList: async function () {
      const response = await this.$http.get('/system-information')
      this.systemInformation = response.data
    },
    dbDumpList: async function () {
      const response = await this.$http.get('/db-dump-list')
      this.dbDumps = response.data
    },
    dbDumpTrigger: async function (slim = false) {
      let url = '/db-dump?slim=' + slim
      if (this.selectedTables.length > 0) {
        url += '&tables=' + String(this.selectedTables)
      }

      await this.$http.get(url)
    },
    dbDumpDownload: async function (file) {
      await this.$http.get(
        '/db-dump-download/' + file,
        { responseType: 'blob' }
      ).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        const fileName = response.headers['content-disposition'].split('filename=').pop()
        fileLink.href = fileURL
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
      })
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    await this.getList()
    await this.dbDumpList()
    this.initiated = true
  }
}
</script>
