<template>
  <v-container fluid class="relative header-space container">
    <div v-if="rankingGroup">
      <h1>{{ rankingGroup.name }}</h1>
      <v-card>
        <v-row class="pl-4 pr-4 pt-4">
          <v-col md="3">
            <v-select
              v-model="selectedToplist"
              v-on:change="loadItems"
              label="Topliste"
              :items="toplists"
              :disabled="loading"
              item-text="value"
              item-value="id"></v-select>
          </v-col>
          <v-col md="2">
            <v-select
              v-model="selectedPeriodId"
              v-on:change="loadItems"
              label="Jahrgang"
              :items="periods"
              :disabled="loading"
              item-text="name"
              item-value="id"></v-select>
          </v-col>
          <v-col md="3">
            <v-text-field
              v-model="search"
              :disabled="loading"
              v-on:keyup.enter="$event.target.blur()"
              label="Suche nach Name"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-switch
              v-model="discoveryViaScores"
              v-on:change="loadItems"
              :disabled="loading"
              :label="`Suche über Score: ${discoveryViaScores.toString()}`"
            ></v-switch>
          </v-col>
        </v-row>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Lädt"
          :disable-sort="true"
          :options.sync="options"
          :items-per-page="100"
          :server-items-length="total"
          :footer-props="{
            'items-per-page-options': [100, 250, 1000]
          }"
        >
          <!-- Account und Contact komibineren: nicht nur shortName-->
          <template v-slot:item.shortName="{ item }">
            <router-link :to="'/' + rootType + '/' + item.id " target="_blank">
              <span v-if="rootType === 'account'">{{ item.name }}</span>
              <span v-else>{{ item.surname }} {{ item.firstName}}</span>
            </router-link>
          </template>

        </v-data-table>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { find, debounce } from 'lodash'

export default {
  data: () => ({
    discoveryViaScores: false,
    headers: [
      { text: 'Name', value: 'shortName' },
      { text: 'Straße', value: 'street' },
      { text: 'PLZ', value: 'zipCode' },
      { text: 'Stadt', value: 'city' }
    ],
    items: [],
    loading: true,
    options: {
      page: 1,
      itemsPerPage: 100
    },
    periods: [{ id: null, name: 'Alle' }],
    rankingGroup: null,
    rootType: '',
    search: '',
    selectedToplist: null,
    selectedPeriodId: null,
    toplists: [{ id: null, value: 'Alle' }],
    total: null,
    typing: false
  }),
  async created () {
    // Firewall
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }
    // Load RankingGroup from backend for filter purposes.
    const rankingGroupCode = this.$route.params.rankingGroupCode
    // Load Toplists from backend for filter purposes.
    this.$http.get('/toplist_label/list?rgShortCode=' + rankingGroupCode).then((response) => {
      this.toplists = this.toplists.concat(response.data.list)
    })
    this.$http.get('/period/list').then((response) => {
      this.periods = this.periods.concat(response.data)
    })
    await this.$http.get('/ranking_group/list').then((response) => {
      this.rankingGroup = find(response.data, { shortCode: rankingGroupCode })
      this.rootType = this.rankingGroup.rootTypeSimplified
    })

    this.loadItems()
  },
  methods: {
    loadItems: async function (resetPagination = true) {
      if (this.rankingGroup === null) {
        return
      }
      this.loading = true
      this.items = []

      if (resetPagination === true) {
        this.options.page = 1
        this.options.itemsPerPage = 100
      }

      const page = this.options.page - 1
      const limit = this.options.itemsPerPage

      const start = page * limit
      let url = '/' + this.rootType + '/list?rankingGroupShortCode=' + this.$route.params.rankingGroupCode + '&offset=' + start + '&limit=' + limit
      if (this.search !== '') {
        url += '&search=' + decodeURIComponent(this.search)
      }
      if (this.selectedPeriod.id !== null) {
        url += '&periodName=' + decodeURIComponent(this.selectedPeriod.name)
      }
      if (this.selectedToplist !== null) {
        url += '&toplistId=' + decodeURIComponent(this.selectedToplist)
      }
      url += '&discoveryViaScores=' + this.discoveryViaScores
      const response = await this.$http.get(url)
      this.items = response.data.list
      this.total = response.data.total
      this.loading = false
    },
    searchInputDebounce: debounce(function () {
      this.loading = true
      this.loadItems()
    }, 500)
  },
  computed: {
    selectedPeriod () {
      return find(this.periods, { id: this.selectedPeriodId })
    }
  },
  watch: {
    search: function () {
      this.items = []
      this.searchInputDebounce()
    },
    options: {
      handler (newOptions, oldOptions) {
        if (newOptions.page !== oldOptions.page) {
          this.loadItems(false)
        } else if (newOptions.itemsPerPage !== oldOptions.itemsPerPage) {
          this.loadItems()
        }
      },
      deep: true
    }
  }
}
</script>
