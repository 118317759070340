<template>
  <v-container fluid class="relative header-space container" v-show="initiated">
    <div>
      <!-- Message on download success -->
      <v-alert v-if="duplicationSuccessful === true" dismissible type="success">Die Duplikation war erfolgreich!</v-alert>

      <h1>Award Duplikation</h1>
      <div>
        <p>
          Hier können Awards dupliziert werden.
          Typischer Anwendungsfall: Awards des aktuellen Jahres sollen für gesonderte Veröffentlichungen dupliziert werden.<br>
          Beispiel: Top Mediziner Ausgabe 1, 2 und 3. Diese sind letztendlich einfach nur Teilmengen der Ausgabe 4 des Vorjahres.
        </p>
      </div>

      <!-- Export area -->
      <v-card class="px-5 py-5">
        <v-card-title>Auswahl der zu duplizierenden Awards</v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="4" sm="12">
              <v-select
                v-model="$state.filters.rankingGroup"
                item-text="name"
                item-value="shortCode"
                :items="rankingGroups"
                label="Projekt"
                v-on:change="loadToplistLabels"></v-select>
            </v-col>
            <v-col md="4" sm="12">
              <v-select
                v-model="selectedToplists"
                item-text="value"
                item-value="id"
                :items="toplists"
                label="Topliste(n)"
                :disabled="$state.filters.rankingGroup === null"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text text-caption"
                  >
                    (+{{ selectedToplists.length - 1 }} weitere)
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @click="toggleToplistSelection"
                  >
                    <v-list-item-action>
                      <v-icon :color="selectedToplists.length > 0 ? 'indigo darken-4' : ''">
                        {{ toggleIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Alles togglen
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>
            <v-col md="4" sm="12">
              <v-select
                v-model="$state.filters.period"
                :disabled="$state.filters.rankingGroup === null"
                :items="periods"
                item-text="name"
                item-value="name"
                label="Jahrgang"></v-select>
            </v-col>
            <v-col md="4" sm="12">
              <v-select
                v-model="targetPeriod"
                :items="periods"
                item-text="name"
                item-value="name"
                label="Ziel-Jahrgang"></v-select>
            </v-col>
            <v-col md="4" sm="12">
              <v-switch
                v-model="dryrun"
                label="Dry Run"
                :false-value=false
                :true-value=true
                hide-details>
              </v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-on:click="duplicate"
            :disabled="missingInput"
            :loading="processing"
            class="primary">
            <v-icon class="mr-1">mdi-content-copy</v-icon>
            duplizieren
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- Error Dialog -->
      <v-dialog v-model="errorDialog" max-width="400">
        <v-card class="pa-4">
          <v-card-text>
            <h4 class="amber--text my-4">Achtung</h4>
            <p>
              Die Operation kann nicht durchgeführt werden.<br>
              Es ist ein Fehler aufgetreten.
            </p>
            <p v-if="errorMessage !== null" class="mb-0 small grey--text text--darken-2">
              Original-Nachricht: {{ errorMessage }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-on:click="errorDialog = false"
              color="blue darken-3"
              class="px-2"
              block
              outlined>
              Verstanden
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dryrun: true,
    errorMessage: null,
    errorDialog: false,
    processing: false,
    duplicationSuccessful: false,
    initiated: false,
    loading: false,
    periods: [],
    rankingGroups: [],
    toplists: [],
    selectedToplists: [],
    targetPeriod: null
  }),
  created: async function () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    // Reset filters.
    this.$state.filters.rankingGroup = null
    this.$state.filters.toplist = null

    const calls = []
    // promise of loading ranking groups from backend
    const rankingGroupPromise = this.$http.get('/ranking_group/list')
      .then((response) => {
        this.rankingGroups = this.rankingGroups.concat(response.data)
      })
    calls.push(rankingGroupPromise)
    // promise of loading periods from backend
    const periodPromise = this.$http.get('/period/list')
      .then((response) => {
        this.periods = response.data
      })
    calls.push(periodPromise)
    // Collects all promises
    await Promise.all(calls)
    this.initiated = true
  },
  methods: {
    loadToplistLabels: function () {
      this.loading = true
      const urlParams = new URLSearchParams()
      // checks for a selected ranking group
      if (this.$state.filters.rankingGroup !== null) {
        urlParams.append('rgShortCode', this.$state.filters.rankingGroup)
      }
      // promise of loading toplists from backend
      return this.$http.get('/toplist_label/list?' + urlParams.toString())
        .then((response) => {
          this.toplists = response.data.list
          this.loading = false
        })
    },
    toggleToplistSelection () {
      this.$nextTick(() => {
        if (this.selectedAllToplists) {
          this.selectedToplists = []
        } else {
          this.selectedToplists = []
          this.toplists.forEach(element => this.selectedToplists.push(element.id))
        }
        console.log(this.selectedToplists)
      })
    },
    async duplicate () {
      this.processing = true

      const payload = {
        rankingGroupShortCode: this.$state.filters.rankingGroup,
        periodName: this.$state.filters.period,
        toplistLabelIds: this.selectedToplists,
        targetPeriodName: this.targetPeriod,
        dryRun: this.dryrun
      }

      await this.$http.post('/award/duplicate', payload)
        .then((response) => {
          this.duplicationSuccessful = true
        })
        .catch((e) => {
          this.duplicationSuccessful = false
          if (e.response.data.message !== null) {
            this.errorMessage = e.response.data.message
            this.errorDialog = true
          }
          throw e
        })

      this.processing = false
    }
  },
  computed: {
    missingInput: function () {
      return this.$state.filters.rankingGroup === null || this.$state.filters.period === null || this.selectedToplists.length === 0 || this.targetPeriod === null
    },
    selectedAllToplists () {
      return this.selectedToplists.length === this.toplists.length
    },
    selectedSomeToplists () {
      return this.selectedToplists.length > 0 && !this.selectedAllToplists
    },
    toggleIcon () {
      if (this.selectedAllToplists) return 'mdi-close-box'
      if (this.selectedSomeToplists) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  }
}
</script>
