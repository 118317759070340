<template>
  <v-card class="mb-6 mt-4 relative" elevation="2">
    <v-progress-linear :active="loading" bottom absolute height="2" indeterminate></v-progress-linear>

    <div v-if="changes.length === 0">
      <div v-if="!loading">
        <p class="no-changes body-2">
          Es wurden bisher keine Änderungen vorgenommen.
        </p>
      </div>
    </div>

    <div v-else>
      <v-simple-table fixed-header height="160px" style="background-color: #fafafa">
        <thead>
        <tr>
          <th>Alter Wert</th>
          <th>Neuer Wert</th>
          <th>Kurator</th>
          <th>Datum</th>
        </tr>
        </thead>
        <tbody class="small">
        <tr class="mono font-bigger" v-for="change in changes" :key="change.id">
          <td>{{ typedValue(change.oldValue) }}</td>
          <td>
            <span :class="[change.declinedDate != null ? 'grey--text' : 'black--text']">{{ typedValue(change.newValue) }}</span>
          </td>
          <td class="bold">{{ change.curator.shortCode }}</td>
          <td>
            <!-- declined Change Date -->
            <span v-if="change.declinedDate != null" class="grey--text"><span class="font-body small">Wert abgelehnt am:</span><br> {{ change.declinedDate }}</span>
            <!-- accepted Change Date -->
            <span v-else>{{ change.acceptedDate }}</span>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div class="absolute close-slot"  v-if="!loading">
      <slot></slot>
    </div>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    changes: [],
    loading: true
  }),
  props: {
    entryId: String,
    targetClass: String,
    field: String
  },
  created: async function () {
    const response = await this.$http.get('change/list/target/' + this.targetClass + '/' + this.entryId + '?pending=0&attribute=' + this.field)
    this.loading = false
    this.changes = response.data
  },
  methods: {
    typedValue: function (value) {
      if (value === null) {
        return '(NULL)'
      }
      if (value === '') {
        return '(leerer string)'
      }
      return value
    }
  }
}
</script>

<style scoped>
  .theme--light.v-data-table.v-data-table--fixed-header thead th {
    background-color: #fafafa;
  }
  .no-changes {
    min-height: 40px;
    background-color: #fafafa;
    padding: 10px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
  }
  .close-slot {
    top: 0;
    right: 0;
    z-index: 10;
  }
</style>
