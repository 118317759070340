<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <h5>Konsistenzprüfung: Datum</h5>
          <p class="explanation body-2 pt-3 pr-3 mb-0">Prüfung, ob alle Einträge innerhalb einer Spalte einheitliche Datum-Form haben.</p>
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <DateFormatWithDotConsistencyAnalyse v-if="profile.DateFormatWithDotConsistencyCheck === false" :profile="profile.DateFormatWithDotConsistencyCheck" :sub="profile['DateFormatWithDotConsistencyCheck-sub']" :header="profile['HeaderItemCount-sub']" class="mb-6"></DateFormatWithDotConsistencyAnalyse>
        <DateFormatWithUnderscoreConsistencyAnalyse v-if="profile.DateFormatWithUnderscoreConsistencyCheck === false" :profile="profile.DateFormatWithUnderscoreConsistencyCheck" :sub="profile['DateFormatWithUnderscoreConsistencyCheck-sub']" :header="profile['HeaderItemCount-sub']" class="mb-6"></DateFormatWithUnderscoreConsistencyAnalyse>
      </v-expansion-panel-content>

      <v-expansion-panel-content>
        <DateFormatWithDotConsistencyAnalyse v-if="profile.DateFormatWithDotConsistencyCheck === true" :profile="profile.DateFormatWithDotConsistencyCheck" :sub="profile['DateFormatWithDotConsistencyCheck-sub']" :header="profile['HeaderItemCount-sub']" class="mb-6"></DateFormatWithDotConsistencyAnalyse>
        <DateFormatWithUnderscoreConsistencyAnalyse v-if="profile.DateFormatWithUnderscoreConsistencyCheck === true" :profile="profile.DateFormatWithUnderscoreConsistencyCheck" :sub="profile['DateFormatWithUnderscoreConsistencyCheck-sub']" :header="profile['HeaderItemCount-sub']" class="mb-6"></DateFormatWithUnderscoreConsistencyAnalyse>
        <div v-if="checkIsFalse">
          <p class="explanation body-2 pt-3 mb-0">
            <v-icon size="18" class="small-icon">mdi-check</v-icon>bedeutet eine Spalte hat einheitliche Einträge.<br>
            <v-icon size="18" class="small-icon" color="red darken-1">mdi-close</v-icon>bedeutet, eine Spalte enthält eine Mischform.
          </p>
        </div>
      </v-expansion-panel-content>

    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import DateFormatWithDotConsistencyAnalyse from '../../components/analyse/DateFormatWithDotConsistencyAnalyse'
import DateFormatWithUnderscoreConsistencyAnalyse from '../../components/analyse/DateFormatWithUnderscoreConsistencyAnalyse'

export default {
  components: {
    DateFormatWithUnderscoreConsistencyAnalyse,
    DateFormatWithDotConsistencyAnalyse
  },
  props: [
    'profile'
  ],
  computed: {
    checkIsFalse: function () {
      if (this.profile.DateFormatWithDotConsistencyCheck === true) {
        return false
      }
      if (this.profile.DateFormatWithDotConsistencyCheck === true) {
        return false
      }
      return true
    }
  }
}
</script>
