<template>

  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <h5>Spaltenlänge</h5>
          <p class="explanation body-2 pt-3 pr-3 mb-0">
            Der Spalten-Check überprüft, ob die Anzahl der Einträge in allen Spalten gleich ist.<br>
            In der Tabelle wird die Anzahl der Einträge in einer Spalte angegeben.
          </p>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="profile === true">
        <p class="mb-0">
          <v-icon color="light-green">mdi-checkbox-marked-circle-outline</v-icon>
          Ok. Alle Spalten haben die gleiche Länge.
        </p>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-else>
        <p class="mb-0">
          <v-icon color="red darken-1">mdi-close-circle-outline</v-icon>
          Achtung! Es gibt Spalten unterschiedlicher Länge. Dies muss in der Lieferung behoben werden.
        </p>
      </v-expansion-panel-content>
      <v-expansion-panel-content>
        <v-simple-table>
          <tr>
            <td v-for ="colName in header" class="column"> {{ colName }}</td>
          </tr>
          <tr>
            <td v-for="value in sub" class="column monospace">{{ value }}</td>
          </tr>
        </v-simple-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
export default {
  props: [
    'profile',
    'sub',
    'header'
  ]
}
</script>
