<template>

  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <h5>Zeichenkodierung</h5>
          <p class="explanation body-2 pt-3 pr-3 mb-0">Dieser Check überprüft, ob die Einträge einer Lieferung unterschiedliche Kodierungen aufweisen.</p>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="profile === true">
        <p class="mb-0">
          <v-icon color="light-green">mdi-checkbox-marked-circle-outline</v-icon>
          Ok.
        </p>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-else>
        <p class="pb-2">
          <v-icon color="red darken-1">mdi-close-circle-outline</v-icon>
          Achtung! Die Kodierung innerhalb der Lieferung ist nicht einheitlich. Dies muss an folgenden Stellen behoben werden:
        </p>
        <v-simple-table>
          <tr v-for="(value, rowIndex) in sub">
            <td class="column">Zeile {{ rowIndex }}</td>
            <td class="column monospace">{{ value }}</td>
          </tr>
        </v-simple-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
export default {
  props: [
    'profile',
    'sub'
  ]
}
</script>
