<template>
  <v-container fluid class="relative header-space container">
    <div>
      <h1>Übersicht der Toplisten</h1>
      <v-row>
        <v-col sm="12">
          <h3>Anleitung</h3>
          <p>Hier können alle existierenden Toplisten eingesehen werden. </p>
          <p>Bevor eine neue Topliste angelegt wird, sollten alle existierenden Toplisten eingehend geprüft werden. Es kann sein, dass eine Topliste für das gleiche Projekt schon mit einem vergleichbaren Namen angelegt wurde.
            Beim Durchsuchen der Toplisten können die Suchfunktion und die Projekt-Filterfunktion weiterhelfen.
          </p>
          <p><b>Anmerkung</b><br> Der Namenslänge des Datenlieferanten muss zwischen 1 bis 30 Zeichen liegen.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" >
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Suche in Toplistname oder Projektkürzel"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="toplistLabelsByName"
                class="elevation-1"
                :items-per-page="50"
                :search="search"
                sort-by="value">
                <template #item.rankingGroup="{ item }">
                  <router-link :to="'/projekt/' + item.rankingGroup.shortCode">
                    {{ item.rankingGroup.name }}
                  </router-link>
                </template>
                <template v-slot:top>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="editDialog" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Bearbeiten</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field v-model="editedItem.value" label="Name"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field v-model="editedItem.sghId" label="SGH Id"></v-text-field>
                            </v-col>
                            <!-- Warning: similarities are found -->
                            <div v-if="similarResults.length !== 0 && !hasExactResultEdit" class="mb-4">
                              <p v-for="result in similarResults" class="mb-1 grey--text text--darken-2 small">
                                <span v-if="typeof result !== 'undefined' && result.value !== editedItem.value">
                                  <v-icon color="amber">mdi-information-outline</v-icon>
                                  Meintest du <span class="bold">"{{ result.value }}"</span>?
                                </span>
                              </p>
                            </div>

                            <div v-if="hasExactResultEdit">
                              <p class="mb-4 grey--text text--darken-2 small">
                                <span>
                                  <v-icon color="red darken-1">mdi-alert-circle-outline</v-icon>
                                  Der Toplisten-Name <span class="bold">"{{ editedItem.value }}"</span> ist bereits vergeben.
                                </span>
                              </p>
                            </div>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="close">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="save">
                          <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
                          <span v-if="loading === false">Save</span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="deleteDialog" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Löschen</span>
                      </v-card-title>
                      <v-card-text>
                        <p>Sicher, dass die Topliste gelöscht werden soll?</p>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="closeDelete">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm">
                          <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
                          <span v-if="loading === false">Delete</span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <small>{{ new Date(item.createdAt).toLocaleString() }}</small>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  <small>{{ item.updatedAt ? new Date(item.updatedAt).toLocaleString() : '' }}</small>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>

              <div v-show="adding">
                <v-row class="px-4">
                  <v-col lg="4">
                    <v-select
                      v-model="newRankingGroupId"
                      v-on:change="loadSimilarResults"
                      label="Projekt"
                      :items="newRankingGroups"
                      item-text="name"
                      item-value="id"
                      required
                    ></v-select>
                  </v-col>
                  <!-- Text field disablen -->
                  <v-col lg="5">
                    <v-text-field
                      v-model="newToplistName"
                      label="Toplisten-Name"
                      required
                    ></v-text-field>

                    <!-- Warning: similarities are found -->
                    <div v-if="similarResults.length !== 0 && !hasExactResult" class="mb-4">
                      <p v-for="result in similarResults" class="mb-1 grey--text text--darken-2 small">
                        <span v-if="typeof result !== 'undefined' && result.value !== newToplistName">
                          <v-icon color="amber">mdi-information-outline</v-icon>
                          Meintest du <span class="bold">"{{ result.value }}"</span>?
                        </span>
                      </p>
                    </div>

                    <div v-if="hasExactResult">
                      <p class="mb-4 grey--text text--darken-2 small">
                        <span>
                          <v-icon color="red darken-1">mdi-alert-circle-outline</v-icon>
                          Der Toplisten-Name <span class="bold">"{{ newToplistName }}"</span> ist bereits vergeben.
                        </span>
                      </p>
                    </div>
                  </v-col>

                  <v-col lg="3" class="text-right">
                    <v-btn
                      v-on:click="dialog=true"
                      class="mt-4"
                      color="primary"
                      :disabled="invalidInput === true"
                      outlined>
                      Speichern
                    </v-btn>
                    <v-btn v-on:click="adding=false" icon class="mt-4 ml-2">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-dialog v-model="dialog" max-width="400">
                      <v-card>
                        <v-card-title class="amber--text" v-if="similarResults.length !== 0">
                            <v-icon color="amber" large left>mdi-information-outline</v-icon>
                            <span class="title">Ähnliche Ergebnisse gefunden</span>
                        </v-card-title>
                        <v-card-title v-else>
                          <span class="title">Neue Topliste anlegen</span>
                        </v-card-title>
                        <v-card-text v-if="similarResults.length !== 0">
                          Es wurden ähnliche Ergebnisse gefunden.<br>
                          Sicher, dass eine Topliste mit einem ähnlichen Namen angelegt werden soll?
                        </v-card-text>
                        <v-card-actions class="px-6 pb-6">
                          <v-btn v-on:click="dialog=false" class="grey--text darken-2" outlined>
                            Abbrechen
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn v-on:click="createToplist" color="primary" outlined>
                            Topliste anlegen
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </div>

              <div v-if="loading" class="pa-4 text-center grey--text text--darken-2" :loading="loading" outlined block>
                <span>Toplisten wird geladen</span>
                <v-progress-circular class="ml-4" color="primary" indeterminate></v-progress-circular>
              </div>

              <div v-show="!adding && !loading" class="text-right px-4 py-4">
                <v-btn v-on:click="adding=true" color="blue darken-3" outlined>
                  <v-icon class="pr-1">mdi-plus</v-icon> Topliste hinzufügen
                </v-btn>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { debounce } from 'lodash'

export default {
  components: {
  //
  },
  data: () => ({
    initiated: false,
    toplistLabels: [],
    rankingGroups: [{ shortCode: null, name: 'Alle Projekte' }],
    newRankingGroups: [],
    adding: false,
    newToplistName: '',
    newRankingGroupId: null,
    selectedRankingGroupShortCode: null,
    loading: false,
    search: '',
    dialog: false,
    similarResults: [],
    exactResult: false,
    lockedNew: false,
    headers: [
      { text: 'Id', value: 'id', sortable: false },
      { text: 'SGH Id', value: 'sghId', sortable: false },
      { text: 'Name', value: 'value' },
      { text: 'Projekt', value: 'rankingGroup', sortable: false },
      { text: 'Projektkürzel', value: 'rankingGroup.shortCode' },
      { text: 'Created At', value: 'createdAt' },
      { text: 'Updated At', value: 'updatedAt' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    editDialog: null,
    deleteDialog: null,
    editedIndex: -1,
    editedItem: {
      value: '',
      sghId: ''
    },
    defaultItem: {
      value: '',
      sghId: ''
    }
  }),
  async created () {
    // checks if user is logged in
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
    }
    const calls = []
    // promise of loaded list of toplists
    const toplistPromise = this.getList()
    calls.push(toplistPromise)
    // promise of loading ranking groups from backend
    const rankingGroupPromise = this.$http.get('/ranking_group/list')
      .then((response) => {
        this.rankingGroups = this.rankingGroups.concat(response.data)
        this.newRankingGroups = response.data
      })
    calls.push(rankingGroupPromise)
    // Collects all promises
    await Promise.all(calls)
    this.initiated = true
  },
  methods: {
    getList: function () {
      this.loading = true
      const urlParams = new URLSearchParams()
      // checks for a selected ranking group
      if (this.selectedRankingGroupShortCode !== null) {
        urlParams.append('rgShortCode', this.selectedRankingGroupShortCode)
      }
      urlParams.append('serializationDepth', '2')
      // promise of loading toplists from backend
      return this.$http.get('/toplist_label/list?' + urlParams.toString())
        .then((response) => {
          this.toplistLabels = response.data.list
          console.log(this.toplistLabels)
          this.loading = false
        })
    },
    createToplist: async function () {
      this.adding = false
      this.dialog = false
      this.loading = true
      // adds a new toplist-item to toplist-list
      const payload = { value: this.newToplistName, rankingGroupId: this.newRankingGroupId }
      await this.$http.post('/toplist_label', payload)
      // clears input and resets select
      this.newToplistName = ''
      this.newRankingGroupId = null
      // loads new toplist list
      await this.getList()
      this.loading = false
    },
    editItem (item) {
      this.editedIndex = this.toplistLabels.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editDialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.toplistLabels.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.deleteDialog = true
    },
    deleteItemConfirm: async function () {
      this.loading = true
      await this.$http.delete('/toplist_label/delete/' + this.editedItem.id)
      this.loading = false
      this.closeDelete()
      await this.getList()
      this.loading = false
    },
    closeDelete () {
      this.deleteDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close () {
      this.editDialog = false
      this.similarResults = []
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save: async function () {
      if (this.editedIndex > -1) {
        this.loading = true
        const payload = {
          value: this.editedItem.value,
          sghId: this.editedItem.sghId
        }
        await this.$http.patch('/toplist_label/update/' + this.editedItem.id, payload)
        this.loading = false
      }
      this.close()
      await this.getList()
    },
    loadSimilarResults: function (field, rankingGroupId) {
      this.similarResults = this.toplistLabels.filter((item) => {
        return new RegExp(item.value + '*', 'i').test(field) && item.rankingGroup.id === rankingGroupId
      })
    },
    newToplistNameDebounce: debounce(function (field, rankingGroupId) {
      this.loadSimilarResults(field, rankingGroupId)
    }, 500)
  },
  computed: {
    toplistLabelsByName () {
      if (this.toplistLabels.length === null) {
        return []
      }
      // sorts a copy of toplist-list alphabetically
      return this.toplistLabels.slice().sort((d1, d2) => d1.value.localeCompare(d2.value))
    },
    hasExactResult () {
      // search for a exact result in similar results
      const exactResultFound = this.similarResults.find((item) => {
        if (typeof item !== 'undefined') {
          return item.value === this.newToplistName && item.rankingGroup.id === this.newRankingGroupId
        }
        return false
      })

      return exactResultFound !== undefined
    },
    hasExactResultEdit () {
      // search for a exact result in similar results
      const exactResultFound = this.similarResults.find((item) => {
        if (typeof item !== 'undefined') {
          return item.value === this.editedItem.value && item.rankingGroup.id === this.editedItem.rankingGroup.id && item.id !== this.editedItem.id
        }
        return false
      })

      return exactResultFound !== undefined
    },
    invalidInput () {
      if (this.lockedNew === true) {
        return true
      }
      if (this.newToplistName.length < 3) {
        return true
      }
      if (this.newRankingGroupId === null) {
        return true
      }
      if (this.hasExactResult === true) {
        return true
      }
      return false
    }
  },
  watch: {
    newToplistName: function () {
      this.newToplistNameDebounce(this.newToplistName, this.newRankingGroupId)
    },
    'editedItem.value': function () {
      if (typeof this.editedItem.id !== 'undefined') {
        this.newToplistNameDebounce(this.editedItem.value, this.editedItem.rankingGroup.id)
      }
    }
  }
}
</script>
