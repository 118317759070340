<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row>
        <v-col sm="12">
          <h1>Übersicht der dynamischen Felder</h1>
          <h3>Anleitung</h3>
          <p>Hier können alle dynamischen Felder eingesehen werden. </p>
          <p>Um neue Felder anzulegen, muss auf den Button am unteren Ende der Seite geklickt werden.</p>
          <p><b>Achtung</b><br> Beim Erstellen eines neuen Feldes muss für <i>Dynamisches Feld</i> ein Name im sogenannten Snake-Case Format vergeben werden.
            Im Snake-Case Format werden alle Buchstaben kleingeschrieben und Wörter werden mithilfe eines Unterstrichs getrennt. Falls der vergebene Name nicht diesem Format entspricht kommt es zu einem Fehler und der neue Eintrag wird nicht erstellt.
            Um zurück zur Liste zu gelangen, muss die Seite neu geladen werden.</p>
        </v-col>
        <v-col class="text-right">
          <p>Hier können dynamische Felder (MDL) importiert und exportiert werden.</p>
          <v-btn class="ml-3 mb-3 primary" v-on:click="importMDLs"><v-icon class="mr-1">mdi-upload</v-icon>Import</v-btn>
          <v-btn class="ml-3 mb-3 primary" v-on:click="exportMDLs"><v-icon class="mr-1">mdi-download</v-icon>Export</v-btn>
          <v-btn class="ml-3 mb-3 primary" v-on:click="importMDLsFromDMSv2Dialog"><v-icon class="mr-1">mdi-api</v-icon>DMSv2 Import</v-btn>
          <v-btn class="ml-3 mb-3 primary" v-on:click="dialogCreate = true"><v-icon class="pr-1">mdi-plus</v-icon>Dynamic Label anlegen</v-btn>
          <p>
            <small><b>Import:</b> existierende MDL Definitionen im Config Verzeichnis werden importiert (platziert durch IT).</small><br>
            <small><b>Export:</b> MDLs werden ins Config Verzeichnis exportiert und zusätzlich als Download zur Verfügung gestellt.</small><br>
            <small><b>DMSv2 Import Import:</b> MDLs werden von DMSv2 über die API importiert.</small><br>
            <small><b>Dynamic Label anlegen:</b> Ein neues MDL manuell anlegen.</small>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-row class="px-4 pt-4">
                <v-col md="6">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Suche nach dynamischem Feld"
                    v-on:keyup.enter="$event.target.blur()"
                  ></v-text-field>
                </v-col>
                <v-col md="6" class="text-right">
                  <small>Anzahl MDLs in aktueller Ansicht: {{ this.mdls.length }}</small>
                </v-col>
              </v-row>

              <v-simple-table fixed-header height="700px">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Dynamisches Feld</th>
                  <th>Name</th>
                  <th>Values</th>
                  <th>Mapped</th>
                  <th>RankingGroup</th>
                  <th>Toplist</th>
                  <th>Period</th>
                  <th>Type</th>
                  <th>Nullable</th>
                  <th>Zero Allowed</th>
                  <th>Intern</th>
                  <th>Other</th>
                  <th>Aktion</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="mdl in mdls">
                  <td>
                    <v-tooltip right max-width="500" color="grey lighten-2">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon color="blue darken-3">
                          <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                      </template>
                      <span class="mono grey--text text--darken-3">{{ mdl.id }}</span><br>
                      <span class="mono grey--text text--darken-2"><b>Erstellt am:</b> {{ mdl.createdAt }}</span><br>
                      <span class="mono grey--text text--darken-2"><b>Aktualisiert am:</b> {{ mdl.updatedAt }}</span><br>
                      <span class="mono grey--text text--darken-2"><b>DMS ID:</b> {{ mdl.mediscreenId }}</span><br>
                    </v-tooltip>
                  </td>
                  <td class="small">{{ mdl.name }}</td>
                  <td class="small">{{ mdl.label }}</td>
                  <td class="small">{{ mdl.fieldsCount }}</td>
                  <td class="small">{{ mdl.usedInMapping }}</td>
                  <td class="small">{{ mdl.rankingGroup === null ? '-' : rankingGroupName(mdl.rankingGroup) }}</td>
                  <td class="small">{{ mdl.toplistLabel === null ? '-' : toplistLabelName(mdl.toplistLabel) }}</td>
                  <td class="small">{{ mdl.period === null ? '-' : periodName(mdl.period) }}</td>
                  <td class="small">{{ mdl.type }}</td>
                  <td class="small">{{ mdl.nullable }}</td>
                  <td class="small">{{ mdl.zeroAllowed }}</td>
                  <td class="small">{{ mdl.internal }}</td>
                  <td>
                    <v-tooltip right max-width="500" color="grey lighten-2">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon color="blue darken-3">
                          <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                      </template>
                      <span class="mono grey--text text--darken-4"><b>Format:</b> {{ mdl.format }}</span><br>
                      <span class="mono grey--text text--darken-4"><b>Symbol:</b> {{ mdl.symbol }}</span><br>
                      <span class="mono grey--text text--darken-4"><b>Font Letter:</b> {{ mdl.customFontLetter }}</span><br>
                      <span class="mono grey--text text--darken-4"><b>Beschreibung:</b> {{ mdl.description }}</span><br>
                      <span class="mono grey--text text--darken-4"><b>Beschreibung in HTML:</b> {{ mdl.descriptionHTML }}</span><br>
                      <span class="mono grey--text text--darken-4"><b>Unit Prefix:</b> {{ mdl.unitPrefix }}</span><br>
                      <span class="mono grey--text text--darken-4"><b>Unit:</b> {{ mdl.unit }}</span><br>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-icon v-on:click="openEditDialog(mdl)"
                      small
                      class="mr-2"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon small v-if="(mdl.fieldsCount === 0) && (mdl.usedInMapping !== true)" v-on:click="deleteMdl(mdl)" class="mr-2">mdi-delete</v-icon>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>

              <!-- I know! We have to implement proper pagination or "load more" logic later down the line! -->
              <v-btn
                block
                v-on:click="getList(3000); allMdlsLoaded = true"
                :disabled="loading || processing || allMdlsLoaded"
              >Load All</v-btn>

              <div v-if="loading" outlined block :loading="loading" class="pa-4 text-center grey--text text--darken-2">
                <span>Liste wird aktualisiert</span>
                <v-progress-circular indeterminate color="primary" class="ml-4"></v-progress-circular>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogCreate" max-width="600px">
        <v-card>
          <v-card-title class="headline">Neues MDL erstellen</v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col class="col-12">
                  <v-text-field
                    :value="newMdlName"
                    v-on:change="newMdlName = $event"
                    label="Dynamisches Feld (Maschinenname)"
                    :counter="30"
                    required
                  ></v-text-field>
                  <!-- Warning: similarities or exact results are found -->
                  <div v-if="hasSimilarResult && !hasExactResult" class="mb-4">
                    <p v-for="result in similarResults" class="mb-1 grey--text text--darken-2 small">
                        <span v-if="result.name !== newMdlName">
                          <v-icon color="amber">mdi-information-outline</v-icon>
                          Meintest du <span class="bold">"{{ result.name }}"</span>?
                        </span>
                    </p>
                  </div>

                  <div v-if="hasExactResult">
                    <p class="mb-4 grey--text text--darken-2 small">
                        <span>
                          <v-icon color="red darken-1">mdi-alert-circle-outline</v-icon>
                          Der Name des dynamischen Feldes <span class="bold">"{{ newMdlName }}"</span> ist bereits vergeben.
                        </span>
                    </p>
                  </div>
                </v-col>

                <v-col class="col-12">
                  <v-text-field
                    :value="newLabel"
                    v-on:change="newLabel = $event"
                    label="Label"
                    :counter="30"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="col-12">
                  <v-select
                    label="Type"
                    v-model="selectedType"
                    :items="types"
                    item-text="text"
                    item-value="type"
                    required
                  ></v-select>
                </v-col>

                <v-col class="col-12">
                  <v-select
                    label="Ranking Group"
                    v-model="selectedRankingGroup"
                    :items="rankingGroups"
                    item-text="name"
                    item-value="id">
                  </v-select>
                </v-col>

                <v-col class="col-12">
                  <v-select
                    label="Period"
                    v-model="selectedPeriod"
                    :items="periods"
                    item-text="name"
                    item-value="id">
                  </v-select>
                </v-col>

                <v-col class="col-12">
                  <v-select
                    label="Topliste"
                    v-model="selectedToplist"
                    :items="rankingGroupToplists(selectedRankingGroup)"
                    item-text="value"
                    item-value="id"
                    :disabled="selectedRankingGroup === null">
                  </v-select>
                </v-col>

                <v-col class="col-12">
                  <v-select
                    label="Scope"
                    v-model="selectedScope"
                    :items="scopes"
                    item-text="text"
                    item-value="scope"
                  ></v-select>
                </v-col>

                <v-col class="col-12">
                  <v-select
                    label="Format"
                    v-model="selectedFormat"
                    :items="formats"
                    item-text="text"
                    item-value="format"
                  ></v-select>
                </v-col>

                <v-col class="col-12">
                  <v-select
                    label="Präfix"
                    v-model="selectedUnitPrefix"
                    :items="unitPrefixes"
                    item-text="text"
                    item-value="unitPrefix"
                  ></v-select>
                </v-col>

                <v-col class="col-12">
                  <v-select
                    label="Einheit"
                    v-model="selectedUnit"
                    :items="units"
                    item-text="text"
                    item-value="unit"
                  ></v-select>
                </v-col>

                <v-col class="col-12">
                  <v-text-field
                    :value="newCustomFontLetter"
                    v-on:change="newCustomFontLetter = $event"
                    label="Font Letter"
                    :counter="1"
                    hint="Der Buchstabe, welcher durch das Custom Font in InDesign (Print) als Symbol dargestellt wird."
                  ></v-text-field>
                </v-col>

                <v-col class="col-12">
                  <v-select
                    label="Symbol"
                    v-model="selectedSymbol"
                    :items="symbols"
                    item-text="text"
                    item-value="symbol"
                  ></v-select>
                </v-col>

                <v-col class="col-12">
                  <v-checkbox
                    label="Nullable"
                    v-model="newNullable"
                  ></v-checkbox>
                </v-col>

                <v-col class="col-12">
                  <v-checkbox
                    label="Zero Allowed"
                    v-model="newZeroAllowed"
                  ></v-checkbox>
                </v-col>

                <v-col class="col-12">
                  <v-checkbox
                    label="Intern"
                    v-model="newInternal"
                  ></v-checkbox>
                </v-col>

                <v-col class="col-12">
                  <v-textarea
                    counter
                    :value="newDescription"
                    v-on:change="newDescription = $event"
                    outlined
                    row-height="2"
                    label="Beschreibung"
                    placeholder="Beschreibung hinzufügen"
                  ></v-textarea>
                </v-col>

                <v-col class="col-12">
                  <v-textarea
                    :value="newDescriptionHTML"
                    v-on:change="newDescriptionHTML = $event"
                    outlined
                    row-height="2"
                    label="Beschreibung in HTML"
                    placeholder="Beschreibung in HTML hinzufügen"
                    hint="Das HTML wird nicht validiert!"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              :disabled="processing"
              @click="dialogCreate = false"
            >abbrechen</v-btn>
            <v-btn
              color="blue darken-1"
              :disabled="loading"
              :loading="processing"
              text
              @click="createMdl"
            >Erstellen</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogEdit" max-width="600px">
        <v-card>
          <v-card-title class="headline">MDL Bearbeiten</v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col class="col-12">
                  <v-text-field
                    label="Dynamisches Feld (Maschinenname)"
                    :disabled="true"
                    :value="editedItem.name"
                  ></v-text-field>
                </v-col>
                <v-col class="col-12">
                  <v-text-field
                    label="Label"
                    :value="editedItem.label"
                    v-on:change="editedItem.label = $event"
                  ></v-text-field>
                </v-col>
                <v-col class="col-12">
                  <v-text-field
                    label="Type"
                    :value="editedItem.type"
                    :disabled="true"
                  ></v-text-field>
                </v-col>
                <v-col class="col-12">
                  <v-text-field
                    label="Ranking Group"
                    :value="editedItem.rankingGroup?.name"
                    :disabled="true"
                  ></v-text-field>
                </v-col>
                <v-col class="col-12">
                  <v-text-field
                    label="Period"
                    :value="editedItem.period?.name"
                    :disabled="true"
                  ></v-text-field>
                </v-col>

                <v-col class="col-12">
                  <v-select
                    label="Topliste"
                    v-model="editedItem.toplistLabel"
                    :items="rankingGroupToplists(editedItem.rankingGroup?.id)"
                    item-text="value"
                    item-value="id"
                    :disabled="editedItem.rankingGroup === null">
                  </v-select>
                </v-col>

                <v-col class="col-12">
                  <v-text-field
                    label="Scope"
                    :value="editedItem.scope"
                    :disabled="true"
                  ></v-text-field>
                </v-col>
                <v-col class="col-12">
                  <v-select
                    label="Format"
                    v-model="editedItem.format"
                    :items="formats"
                    item-text="text"
                    item-value="realKey"
                  ></v-select>
                </v-col>
                <v-col class="col-12">
                  <v-select
                    label="Präfix"
                    v-model="editedItem.unitPrefix"
                    :items="unitPrefixes"
                    item-text="text"
                    item-value="realKey"
                  ></v-select>
                </v-col>
                <v-col class="col-12">
                  <v-select
                    label="Einheit"
                    v-model="editedItem.unit"
                    :items="units"
                    item-text="text"
                    item-value="realKey"
                  ></v-select>
                </v-col>
                <v-col class="col-12">
                  <v-text-field
                    label="Font Letter"
                    :value="editedItem.customFontLetter"
                    v-on:change="editedItem.customFontLetter = $event"
                    :counter="1"
                    hint="Der Buchstabe, welcher durch das Custom Font in InDesign (Print) als Symbol dargestellt wird."
                  ></v-text-field>
                </v-col>
                <v-col class="col-12">
                  <v-select
                    label="Symbol"
                    v-model="editedItem.symbol"
                    :items="symbols"
                    item-text="text"
                    item-value="realKey"
                  ></v-select>
                </v-col>
                <v-col class="col-12">
                  <v-checkbox
                    label="Nullable"
                    v-model="editedItem.nullable"
                  ></v-checkbox>
                </v-col>
                <v-col class="col-12">
                  <v-checkbox
                    label="Zero Allowed"
                    v-model="editedItem.zeroAllowed"
                  ></v-checkbox>
                </v-col>
                <v-col class="col-12">
                  <v-checkbox
                    label="Intern"
                    v-model="editedItem.internal"
                  ></v-checkbox>
                </v-col>
                <v-col class="col-12">
                  <v-textarea
                    label="Description"
                    :value="editedItem.description"
                    v-on:change="editedItem.description = $event"
                  ></v-textarea>
                </v-col>
                <v-col class="col-12">
                  <v-textarea
                    label="Description HTML"
                    :value="editedItem.descriptionHTML"
                    v-on:change="editedItem.descriptionHTML = $event"
                    hint="Das HTML wird nicht validiert!"
                  ></v-textarea>
                </v-col>
                <v-col class="col-12">
                  <v-text-field
                    label="DMS Machine Name"
                    v-model="editedItem.mediscreenMachineName"
                  ></v-text-field>
                </v-col>
                <v-col class="col-12">
                  <v-text-field
                    label="DMS UUID"
                    v-model="editedItem.mediscreenId"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              :disabled="processing"
              v-on:click="dialogEdit = false"
            >abbrechen</v-btn>
            <v-btn
              color="blue darken-1"
              :disabled="loading"
              :loading="processing"
              text
              v-on:click="updateMdl()"
            >Speichern</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogExportImport" max-width="600px">
        <v-card>
          <v-card-title class="green--text">
            <v-icon color="green" large left>mdi-information-outline</v-icon>
            <span class="title">Result</span>
          </v-card-title>
          <v-card-text>
            {{ exportImportResult }}
          </v-card-text>
          <v-card-actions class="px-6 pb-6">
            <v-btn v-on:click="dialogExportImport=false" class="primary--text" outlined>
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="600px">
        <v-card>
          <v-card-title class="headline">Achtung!</v-card-title>
          <v-card-text>Dynamisches Feld "{{ editedItem.name }}" wirklich löschen?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
              <span v-if="loading === false">Löschen</span>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogImportDMS">
        <v-card>
          <v-card-title class="headline">Import vom DMS</v-card-title>
          <v-card-text>
            Hier können MDLs über die Schnittstelle vom DMS importiert werden.
            <div v-if="DMSImportStatus !== null">
              <p>
                Dry-Run: {{ DMSImportStatus['dry-run'] }}<br>
                Analysierte Toplisten: {{ DMSImportStatus['analyzed_toplists'] }}<br>
              </p>
              <h2 v-if="DMSImportStatus['dry-run'] === true">Zu erstellende MDLs ({{ DMSImportStatus['created_mdls'].length }})</h2>
              <h2 v-else>Erstellte MDLs ({{ DMSImportStatus['created_mdls'].length }})</h2>
              <v-data-table
                :headers="DMSImportHeaders"
                :items="DMSImportStatus['created_mdls']"
                :items-per-page="50"
                class="elevation-1">
                <template v-slot:item.toplistLabel="{ item }">
                  {{ item.toplistLabel === null ? '-' : toplistLabelName(item.toplistLabel) }}
                </template>
                <template v-slot:item.createdAt="{ item }">
                  {{ new Date(item.createdAt).toLocaleString() }}
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  {{ item.updatedAt ? new Date(item.updatedAt).toLocaleString() : '' }}
                </template>
              </v-data-table>
              <h2 v-if="DMSImportStatus['dry-run'] === true" class="mt-3">Zu aktualisierende MDLs ({{ DMSImportStatus['updated_mdls'].length }})</h2>
              <h2 v-else>Aktualisierte MDLs ({{ DMSImportStatus['updated_mdls'].length }})</h2>
              <v-data-table
                :headers="DMSImportHeaders"
                :items="DMSImportStatus['updated_mdls']"
                :items-per-page="50"
                class="elevation-1">
                <template v-slot:item.toplistLabel="{ item }">
                  {{ item.toplistLabel === null ? '-' : toplistLabelName(item.toplistLabel) }}
                </template>
                <template v-slot:item.createdAt="{ item }">
                  {{ new Date(item.createdAt).toLocaleString() }}
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  {{ item.updatedAt ? new Date(item.updatedAt).toLocaleString() : '' }}
                </template>
              </v-data-table>
            </div>
            <div v-else>
              <v-select
                label="Projekt*"
                v-model="selectedRankingGroup"
                :items="rankingGroups"
                item-text="name"
                item-value="id"
                :disabled="loading"
              >
              </v-select>
              <v-select
                label="Topliste"
                v-model="selectedToplist"
                :items="rankingGroupToplists(selectedRankingGroup)"
                item-text="value"
                item-value="id"
                :disabled="loading || selectedRankingGroup === null"
              >
              </v-select>
              <v-select
                label="Jahr*"
                v-model="selectedPeriod"
                :items="periods"
                item-text="name"
                item-value="id"
                :disabled="loading"
              >
              </v-select>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              :disabled="processing"
              @click="dialogImportDMS = false"
            >abbrechen</v-btn>
            <v-btn
              v-if="DMSImportStatus === null"
              color="blue darken-1"
              :disabled="loading || selectedRankingGroup === null || selectedPeriod === null"
              :loading="processing"
              text
              @click="importMDLsFromDMSv2(selectedRankingGroup, selectedPeriod, selectedToplist, true)"
            >Dry Run</v-btn>
            <v-btn
              v-else
              color="blue darken-1"
              :disabled="loading || selectedRankingGroup === null || selectedPeriod === null"
              :loading="processing"
              text
              @click="importMDLsFromDMSv2(selectedRankingGroup, selectedPeriod, selectedToplist, false)"
            >Import</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { debounce } from 'lodash'
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    mdls: [],
    allMdlsLoaded: false,
    loading: false, // generic loading flag (mainly for getters)
    processing: false, // processing flag for create/update/import/export
    search: '',
    similarResults: [],
    exactResult: false,
    exportImportResult: '',
    DMSImportStatus: null,
    // Dialogs
    dialogDelete: false,
    dialogCreate: false,
    dialogEdit: false,
    dialogExportImport: false,
    dialogImportDMS: false,
    // Form fields
    newMdlName: null,
    newLabel: null,
    newDescription: null,
    newDescriptionHTML: null,
    selectedScope: null,
    selectedFormat: null,
    selectedUnitPrefix: null,
    selectedUnit: null,
    selectedSymbol: null,
    selectedType: null,
    selectedRankingGroup: null,
    selectedPeriod: null,
    selectedToplist: null,
    newNullable: null,
    newCustomFontLetter: null,
    newZeroAllowed: null,
    newInternal: null,
    // Dynamic content from backend
    rankingGroups: [{ id: null, name: '' }],
    periods: [{ id: null, name: '' }],
    toplists: [{ id: null, value: 'Kein Toplistenbezug' }],
    // Static options for dropdown fields
    formats: [
      { format: null, text: 'Kein Format', realKey: null },
      { format: 'X_OUT_OF_TWO', text: 'Score: X von 2', realKey: 'FORMAT_X_OUT_OF_TWO' },
      { format: 'X_OUT_OF_THREE', text: 'Score: X von 3', realKey: 'FORMAT_X_OUT_OF_THREE' },
      { format: 'X_OUT_OF_FIVE', text: 'Score: X von 5', realKey: 'FORMAT_X_OUT_OF_FIVE' },
      { format: 'X_OUT_OF_SIX', text: 'Score: X von 6', realKey: 'FORMAT_X_OUT_OF_SIX' },
      { format: 'X_OUT_OF_TEN', text: 'Score: X von 10', realKey: 'FORMAT_X_OUT_OF_TEN' },
      { format: 'CURRENCY', text: 'Währung', realKey: 'FORMAT_CURRENCY' },
      { format: 'YEAR', text: 'Jahr', realKey: 'FORMAT_YEAR' }
    ],
    symbols: [
      { symbol: null, text: 'Kein Symbol', realKey: null },
      { symbol: 'circle', text: 'Kreis', realKey: 'SYMBOL_CIRCLE' },
      { symbol: 'diamond', text: 'Diamant', realKey: 'SYMBOL_DIAMOND' },
      { symbol: 'square', text: 'Quadrat', realKey: 'SYMBOL_SQUARE' },
      { symbol: 'star', text: 'Stern', realKey: 'SYMBOL_STAR' },
      { symbol: 'check', text: 'Häkchen', realKey: 'SYMBOL_CHECK' },
      { symbol: 'triangle', text: 'Dreieck', realKey: 'SYMBOL_TRIANGLE' },
      { symbol: 'leaf', text: 'Blatt', realKey: 'SYMBOL_LEAF' }
    ],
    unitPrefixes: [
      { unitPrefix: null, text: 'Kein Präfix', realKey: null },
      { unitPrefix: 1000, text: '1000', realKey: 1000 }
    ],
    units: [
      { unit: null, text: 'Keine Einheit', realKey: null },
      { unit: 'percent', text: 'Prozent', realKey: 'UNIT_PERCENT' },
      { unit: 'EUR', text: 'Währung: Euro', realKey: 'UNIT_EURO' },
      { unit: 'kg', text: 'Maß: Kilogramm', realKey: 'UNIT_KILOGRAM' },
      { unit: 'l', text: 'Maß: Liter', realKey: 'UNIT_KILOGRAM' },
      { unit: 'km', text: 'Maß: Kilometer', realKey: 'UNIT_KILOMETER' },
      { unit: 'm', text: 'Maß: Meter', realKey: 'UNIT_METER' },
      { unit: 'mm', text: 'Maß: Millimeter', realKey: 'UNIT_MILLIMETER' },
      { unit: 'Wh', text: 'Maß: Wattstunde', realKey: 'UNIT_WATT_HOUR' },
      { unit: 'W', text: 'Maß: Watt', realKey: 'UNIT_WATT' },
      { unit: 'km/h', text: 'Maß: Kilometer pro Stunde', realKey: 'UNIT_KILOMETER_PER_HOUR' },
      { unit: 's', text: 'Maß: Sekunden', realKey: 'UNIT_SECONDS' }
    ],
    types: [
      { type: 'string', text: 'String' },
      { type: 'integer', text: 'Integer' },
      { type: 'double', text: 'Double/Float' },
      { type: 'boolean', text: 'Boolean' }
    ],
    scopes: [
      { scope: 'global', text: 'Global' },
      { scope: 'health', text: 'Health' },
      { scope: 'business', text: 'Business' },
      { scope: 'special', text: 'Special' },
      { scope: 'money', text: 'Money' }
    ],
    defaultItem: {
      name: '',
      parent: null
    },
    editedItem: {
      id: null,
      name: null,
      label: null,
      type: null,
      description: null,
      descriptionHTML: null,
      scope: null,
      format: null,
      symbol: null,
      nullable: null,
      zeroAllowed: null,
      internal: null,
      customFontLetter: null,
      unitPrefix: null,
      unit: null,
      mediscreenId: null,
      mediscreenMachineName: null,
      toplistLabel: null,
      rankingGroup: [{ id: null, name: '' }],
      period: [{ id: null, name: '' }]
    },
    DMSImportHeaders: [
      { text: 'Id', value: 'id' },
      { text: 'Maschinenname', value: 'name' },
      { text: 'Label', value: 'label' },
      { text: 'Format', value: 'format' },
      { text: 'Typ', value: 'type' },
      { text: 'Symbol', value: 'symbol' },
      { text: 'Bereich', value: 'scope' },
      { text: 'Topliste', value: 'toplistLabel' },
      { text: 'Null erlaubt', value: 'nullable' },
      { text: '0 erlaubt', value: 'zeroAllowed' },
      { text: 'Internes Feld', value: 'internal' },
      { text: 'erstellt am', value: 'createdAt' },
      { text: 'aktualisiert am', value: 'updatedAt' }
    ]
  }),
  async created () {
    // checks if user is logged in
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
    }
    this.getList()
    this.getRankingGroups()
    this.getPeriods()
    this.getToplistLabels()

    this.initiated = true
  },
  methods: {
    getList: function (limit) {
      this.mdls = []
      this.loading = true
      const urlParams = new URLSearchParams()
      // checks for input in text field
      if (this.search !== '') {
        urlParams.append('search', this.search)
      }
      // append limit
      if (typeof limit !== 'undefined') {
        urlParams.append('limit', limit)
      }
      // promise of loading mdls from backend
      return this.$http.get('/mdl/list?' + urlParams.toString())
        .then((response) => {
          this.mdls = response.data.list
          console.log(this.mdls)
          this.loading = false
        })
    },
    getRankingGroups: function () {
      this.loading = true
      this.$http.get('/ranking_group/list')
        .then((response) => {
          this.rankingGroups = response.data
          this.loading = false
          console.log(this.rankingGroups)
        })
    },
    getPeriods: function () {
      this.loading = true
      this.$http.get('/period/list')
        .then((response) => {
          this.periods = response.data
          this.loading = false
          console.log(this.periods)
        })
    },
    getToplistLabels: function () {
      this.loading = true
      const urlParams = new URLSearchParams()
      // promise of loading toplists from backend
      return this.$http.get('/toplist_label/list?' + urlParams.toString())
        .then((response) => {
          this.toplists = this.toplists.concat(response.data.list)
          this.loading = false
          console.log(this.toplists)
        })
    },
    openEditDialog: function (mdl) {
      this.dialogEdit = true
      Object.assign(this.editedItem, mdl)

      // Find RankingGroup object by ID, so that User sees proper name instead of UUID.
      const rankingGroup = this.rankingGroups.filter(obj => {
        return obj.id === this.editedItem.rankingGroup
      })
      if (rankingGroup.length > 0) {
        this.editedItem.rankingGroup = rankingGroup[0]
      }

      // Find Period object by ID, so that User sees proper name instead of UUID.
      const period = this.periods.filter(obj => {
        return obj.id === this.editedItem.period
      })
      if (period.length > 0) {
        this.editedItem.period = period[0]
      }
    },
    updateMdl: async function () {
      if (this.editedItem.id !== null) {
        this.loading = true
        this.processing = true

        const format = this.formats.filter((elem) => {
          return elem.realKey === this.editedItem.format
        })
        const symbol = this.symbols.filter((elem) => {
          return elem.realKey === this.editedItem.symbol
        })
        const unitPrefix = this.unitPrefixes.filter((elem) => {
          return elem.realKey === this.editedItem.unitPrefix
        })
        const unit = this.units.filter((elem) => {
          return elem.realKey === this.editedItem.unit
        })
        const payload = {
          label: this.editedItem.label,
          description: this.editedItem.description,
          descriptionHTML: this.editedItem.descriptionHTML,
          format: format[0].realKey,
          symbol: symbol[0].realKey,
          unitPrefix: unitPrefix[0].realKey,
          unit: unit[0].realKey,
          customFontLetter: this.editedItem.customFontLetter === '' ? null : this.editedItem.customFontLetter,
          mediscreenId: this.editedItem.mediscreenId,
          mediscreenMachineName: this.editedItem.mediscreenMachineName,
          toplistLabelId: this.editedItem.toplistLabel,
          nullable: this.editedItem.nullable,
          zeroAllowed: this.editedItem.zeroAllowed,
          internal: this.editedItem.internal
        }
        await this.$http.patch('/mdl/update/' + this.editedItem.id, payload)
          .then((response) => {
            this.loading = false
            this.processing = false
            this.dialogEdit = false

            // Filter list by updated MDL
            this.search = response.data.name
            this.getList()
          })
          .catch((e) => {
            this.loading = false
            this.processing = false
          })
      }
    },
    deleteMdl: async function (item) {
      this.editedIndex = this.mdls.indexOf(item)
      console.log(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm: async function () {
      this.loading = true
      await this.$http.delete('/mdl/delete/' + this.editedItem.id)
      this.loading = false
      this.closeDelete()
      await this.getList()
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    createMdl: async function () {
      this.loading = true
      this.processing = true

      const payload = {
        name: this.newMdlName,
        scope: this.selectedScope,
        description: this.newDescription,
        descriptionHTML: this.newDescriptionHTML,
        type: this.selectedType,
        format: this.selectedFormat,
        symbol: this.selectedSymbol,
        label: this.newLabel,
        nullable: this.newNullable,
        zeroAllowed: this.newZeroAllowed,
        internal: this.newInternal,
        customFontLetter: this.newCustomFontLetter,
        rankingGroupId: this.selectedRankingGroup,
        periodId: this.selectedPeriod,
        toplistLabelId: this.selectedToplist,
        unitPrefix: this.selectedUnitPrefix,
        unit: this.selectedUnit
      }
      await this.$http.post('/mdl', payload)
        .then((response) => {
          this.loading = false
          this.processing = false
          this.dialogCreate = false

          // Filter list by newly created MDL
          this.search = response.data.name
          this.getList()

          this.resetFields()
        })
        .catch((e) => {
          this.loading = false
          this.processing = false
        })
    },
    loadSimilarResults: function () {
      const urlParams = new URLSearchParams()
      this.similarResults = []
      // checks for new mdl name input in text field
      if (this.newMdlName === '' || this.newMdlName === null) {
        return
      }
      let searchString = this.newMdlName
      if (this.newMdlName.length < 4) {
        return
      }
      if (this.newMdlName.length > 5) {
        searchString = searchString.slice(0, searchString.length - 3)
      }
      urlParams.append('search', searchString)
      // promise of loading mdl from backend
      return this.$http.get('/mdl/list?' + urlParams.toString())
        .then((response) => {
          this.similarResults = response.data.list
        })
    },
    searchInputDebounce: debounce(function () {
      this.getList()
    }, 400),
    newMdlNameDebounce: debounce(function () {
      this.loadSimilarResults()
    }, 400),
    importMDLs: async function () {
      const response = await this.$http.get('/mdl/import_mdls')
      this.exportImportResult = 'Importierte oder aktualisierte MDLs: ' + response.data.length
      this.dialogExportImport = true
      await this.getList()
    },
    importMDLsFromDMSv2Dialog: async function () {
      this.dialogImportDMS = true

      // Load RankingGroups and Periods if they were not loaded yet.
      if (this.rankingGroups.length === 1) {
        this.getRankingGroups()
      }
      if (this.periods.length === 1) {
        this.getPeriods()
      }
    },
    importMDLsFromDMSv2: async function (rankingGroupId, periodId, toplistLabelId, dryrun) {
      this.loading = true
      this.processing = true
      const payload = {
        strategyAlias: 'dmsv2',
        rankingGroup: rankingGroupId,
        period: periodId,
        toplistLabelId,
        dryrun
      }
      await this.$http.post('/api-import/mdls', payload)
        .then((response) => {
          this.DMSImportStatus = response.data
          this.loading = false
          this.processing = false

          if (dryrun === false) {
            this.dialogImportDMS = false
            this.DMSImportStatus = null
            this.resetFields()
            this.getList()
          }
        })
        .catch((e) => {
          this.resetFields()
          this.dialogImportDMS = false
          this.DMSImportStatus = null
          this.loading = false
          this.processing = false
        })
    },
    exportMDLs: async function () {
      await this.$http.get(
        '/mdl/export_mdls',
        { responseType: 'blob' }
      ).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'mdl_export.zip')
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
      })
    },
    resetFields: function () {
      this.selectedRankingGroup = null
      this.selectedPeriod = null
      this.selectedToplist = null
      this.selectedType = null
      this.selectedFormat = null
      this.selectedSymbol = null
      this.selectedUnitPrefix = null
      this.selectedUnit = null
      this.selectedScope = null

      this.newMdlName = null
      this.newLabel = null
      this.newDescription = null
      this.newDescriptionHTML = null
      this.newCustomFontLetter = null
      this.newZeroAllowed = null
      this.newNullable = null
      this.newInternal = null
    },
    rankingGroupName (uuid) {
      if (uuid === null || uuid === '') {
        return uuid
      }

      const rankingGroup = this.rankingGroups.filter(obj => {
        return obj.id === uuid
      })
      if (rankingGroup.length > 0) {
        return rankingGroup[0].name
      }
      return uuid
    },
    periodName (uuid) {
      if (uuid === null || uuid === '') {
        return uuid
      }

      const period = this.periods.filter(obj => {
        return obj.id === uuid
      })
      if (period.length > 0) {
        return period[0].name
      }
      return uuid
    },
    toplistLabelName (uuid) {
      if (uuid === null || uuid === '') {
        return uuid
      }

      const toplistLabel = this.toplists.filter(obj => {
        return obj.id === uuid
      })
      if (toplistLabel.length > 0) {
        return toplistLabel[0].value
      }
      return uuid
    },
    rankingGroupToplists (uuid) {
      if (uuid === null || uuid === '') {
        return []
      }

      const rankingGroup = this.rankingGroups.filter(obj => {
        return obj.id === uuid
      })
      if (rankingGroup.length > 0) {
        const toplists = [{ id: null, value: 'Kein Toplistenbezug' }]
        rankingGroup[0].toplistLabels.forEach(elem => {
          const toplistLabel = this.toplists.filter(obj => {
            return obj.id === elem
          })

          if (toplistLabel) {
            toplists.push(toplistLabel[0])
          }
        })

        return toplists
      }
      return []
    }
  },
  computed: {
    hasExactResult () {
      // search for a exact result in similar results
      return this.similarResults.find((item) => {
        return item.name === this.newMdlName
      })
    },
    hasSimilarResult () {
      if (this.similarResults.length !== 0) {
        return true
      }
      return false
    }
  },
  watch: {
    search: function () {
      this.searchInputDebounce()
    },
    newMdlName: function () {
      this.newMdlNameDebounce()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  }
}
</script>
