<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row>
        <v-col sm="12">
          <h1>Elasticsearch</h1>
          <p>Eine Übersicht zum Status von Elasticsearch.</p>
        </v-col>
        <v-col class="text-right">
          <v-btn class="ml-3 mb-3 primary" v-on:click="updateESMapping" :loading="updatingMapping"><v-icon class="mr-1">mdi-refresh</v-icon>Update ElasticSearch Mapping</v-btn>
          <p>
            <small>Aktualisiere das ElasticSearch Mapping entsprechend der Definition im ReleaseService.</small><br>
            <span>{{ esMappingUpdateResponse }}</span>
          </p>
          <p>
            <v-text-field v-model="indexToDelete" label="Index zum löschen"></v-text-field>
            <small><b>Achtung:</b> Nur wenn du weißt was du da tust!</small><br>
            <v-btn class="red white--text mt-3 mb-3" v-on:click="deleteIndex" :loading="deletingIndex">Löschen</v-btn><br>
            <span>{{ indexDeletionResponse }}</span>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-simple-table v-if="!loading">
                <thead>
                <tr>
                  <th class="text-left column-width" style="min-width: 250px;">Name</th>
                  <th class="text-left column-width">Wert</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="metric in systemInformation" v-bind:key="metric.name">
                  <td>{{ metric.name }}</td>
                  <td>{{ metric.value }}</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    esMappingUpdateResponse: null,
    indexDeletionResponse: null,
    systemInformation: [],
    indexToDelete: null,
    loading: false,
    deletingIndex: false,
    updatingMapping: false
  }),
  methods: {
    getList: async function () {
      const response = await this.$http.get('/elasticsearch/status')
      this.systemInformation = response.data
    },
    updateESMapping: async function () {
      this.updatingMapping = true
      const response = await this.$http.post('/elasticsearch/apply-mapping')
      this.esMappingUpdateResponse = response.data
      await this.getList()
      this.updatingMapping = false
    },
    deleteIndex: async function () {
      this.deletingIndex = true
      const response = await this.$http.delete('/elasticsearch/delete/' + this.indexToDelete)
      this.indexDeletionResponse = response.data
      this.indexToDelete = null
      await this.getList()
      this.deletingIndex = false
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    await this.getList()
    this.initiated = true
  }
}
</script>
