<template>
  <v-container fluid class="relative header-space container">
    <div>
      <h1>Upload</h1>
      <v-row>
        <v-col md="8" sm="12" class="pr-12">
            <FileUpload/>
        </v-col>
        <v-col md="4" sm="12" >
          <h3>Uploadbereich</h3>
          <p>Hier können Daten aus CSV und Excel Dateien hochgeladen werden.
          </p>
          <h3>Anleitung</h3>
          <p>
            Bevor die gewünschte Datei hochgeladen werden kann, müssen mithilfe der Felder bestimmte Informationen bezüglich des Kontextes der Daten ausgewählt werden:
          </p>
          <ul>
            <li>Lieferant der Daten</li>
            <li>Zugehörigem Projekt</li>
            <li>Bezugsjahr der Daten <br>(Beispiel: <i>Top Tierärzte 2022</i> ist dem Jahr 2022 zugeordnet)</li>
          </ul>
          <br>
          <p>Im Anschluss kann die gewünschte Datei auswählt und hochgeladen werden.</p>
          <p>Der Upload kann und sollte auch bei ungesehenen Lieferungen genutzt werden. So wird die Lieferung revisionssicher dokumentiert.</p>
          <h3>Fehlerhinweise</h3>
          <p>Falls im letzten Schritt der "Upload starten" Button nicht funktioniert, sollte vorerst überprüft werden, ob die ausgewählte Datei den Grundvoraussetzungen
            für hochladbare Dateien entspricht. Zu beachten sind hierbei insbesondere das richtige Dateiformat, sowie die maximale Dateigröße von <b>100 MB</b>.
          </p>
          <p>
            Dateien mit alternativen Excel-Formaten (z.B. xls, xlsm) werden nicht unterstützt. Lediglich Dateien mit der Endung <i>.xlsx</i> oder <i>.csv</i> sind hochladbar.
          </p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import FileUpload from './../components/FileUpload'

export default {
  components: {
    FileUpload
  },
  data: () => ({
    //
  })
}
</script>
