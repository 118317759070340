<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row class="mb-xl">
        <v-col class="position-relative col-12 col-md-7" style="background-image: linear-gradient(-180deg, #BBDEFB 5%, #1565C0 60%); background-size: 200% 100%; min-height: 420px; padding-right: 100px;">
          <div v-if="$auth.isAuthenticated" class="pl-6 pb-5 position-absolute bottom" style="max-width: 420px;">
            <p class="overline white--text mb-0">FLE</p>
            <h1 class="white--text">Data Warehouse</h1>
            <p class="white--text">
              Das FLE Data Warehouse ist ein zentrales Datenbank-Verwaltungssystem, das relevante Daten aus verschiedenen heterogenen Datenquellen extrahiert, sammelt, sichert und nachgelagerte Systeme versorgt.
            </p>
          </div>
          <div v-if="!$auth.isAuthenticated" class="pa-5 mb-5 ml-1 position-absolute bottom white--text">
            <p class="overline white--text mb-0">login</p>
            <h1>Zugriff erforderlich</h1>
            <p>Zugangsdaten erhalten Sie <br>bei ihrer zuständigen IT.</p>
            <v-btn v-on:click="$auth.loginWithPopup()" color="white" block outlined>
              Anmelden
            </v-btn>
          </div>
        </v-col>
        <v-col lg="3" class="pa-5">
          <v-img class="data-visual" src="/img/data-visual.png" style="width: 600px; position: absolute; top: 130px; left: 48%;"></v-img>
        </v-col>
      </v-row>
      <ProjectOverview v-if="$auth.isAuthenticated"></ProjectOverview>
    </div>
  </v-container>
</template>

<script>
import ProjectOverview from './../components/ProjectOverview'

export default {
  components: {
    ProjectOverview
  },
  data: () => ({
    //
  }),
  created () {
    //
  },
  methods: {
    //
  }
}
</script>

<style lang="scss">
  @media (max-width: 960px) {
    .data-visual {
      display: none;
    }
  }
</style>
