<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row>
        <v-col sm="12">
          <h1>Dictionary</h1>
          <h3>Anleitung</h3>
          <p>Hier können Dictionaries gepflegt werden. Ein Dictionary ist eine Sammlung an Translations.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-data-table
                :headers="headersDictionaries"
                :items="dictionaries"
                :items-per-page="50"
                class="elevation-1"
                sort-by="value">
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogDictionary" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="pr-1">mdi-plus</v-icon> Dictionary hinzufügen
                        </v-btn>
                      </template>
                      <v-card class="pa-3">
                        <v-card-title>
                          <span class="headline">Dictionary Bearbeiten</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container fluid>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.label"
                                  label="Bezeichnung"
                                  hint="Der Name des Dictionaries. Hat keine technische Relevanz."
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            @click="close">
                            abbrechen
                          </v-btn>
                          <v-btn
                            color="success"
                            @click="save('dictionary')">
                            <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
                            <span v-if="loading === false">Speichern</span>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDictionaryDelete" max-width="500px">
                      <v-card class="pa-3">
                        <v-card-title class="headline">Achtung!</v-card-title>
                        <v-card-text>Dictionary "{{ editedItem.label }}" wirklich löschen? Dadurch werden alle zugehörigen Translationen ebenfalls gelöscht!</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" @click="closeDelete">abbrechen</v-btn>
                          <v-btn color="error" @click="deleteItemConfirm('dictionary')">
                            <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
                            <span v-if="loading === false">Löschen</span>
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <small>{{ new Date(item.createdAt).toLocaleString() }}</small>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  <small>{{ item.updatedAt ? new Date(item.updatedAt).toLocaleString() : '' }}</small>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, dictionaries); dialogDictionary = true;">
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item, dictionaries); dialogDictionaryDelete = true;"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row>
        <v-col sm="12">
          <h1>Translation</h1>
          <h3>Anleitung</h3>
          <p>Hier können Abkürzungs- bzw. Ersetzungsregeln von Strings gepflegt werden.</p>
          <p>An welchen Stellen dann diese Regeln angewendet werden, muss mit der IT ausgemacht werden.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-card-title>
                <v-col>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    :items="dictionariesOptions"
                    v-model="dictionaryFilterValue"
                    item-text="label"
                    item-value="label"
                    label="Dictionary"
                    single-line
                    hide-details
                  ></v-select>
                </v-col>
              </v-card-title>
              <v-data-table
                :headers="headersTranslations"
                :items="translations"
                :items-per-page="50"
                :search="search"
                class="elevation-1"
                sort-by="value">
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogTranslation" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="pr-1">mdi-plus</v-icon> Begriff hinzufügen
                        </v-btn>
                      </template>
                      <v-card class="pa-3">
                        <v-card-title>
                          <span class="headline">Translation Bearbeiten</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container fluid>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.value"
                                  label="Wert"
                                  hint="Der zu übersetzende String"
                                ></v-text-field>
                                <v-text-field
                                  v-model="editedItem.translatedValue"
                                  label="Translation"
                                  hint="Die Übersetzung des Strings."
                                ></v-text-field>
                                <v-select
                                  v-model="editedItem.dictionary"
                                  :items="dictionaries"
                                  item-value="id"
                                  item-text="label"
                                  label="Dictionary"
                                  hint="Das Dictionary, zu welchem diese Übersetzung gehört."
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            @click="close">
                            abbrechen
                          </v-btn>
                          <v-btn
                            color="success"
                            @click="save('stringtranslation')">
                            <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
                            <span v-if="loading === false">Speichern</span>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogTranslationDelete" max-width="500px">
                      <v-card class="pa-3">
                        <v-card-title class="headline">Achtung!</v-card-title>
                        <v-card-text>Translation für Begriff "{{ editedItem.value }}" wirklich löschen?</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" @click="closeDelete">abbrechen</v-btn>
                          <v-btn color="error" @click="deleteItemConfirm('stringtranslation')">
                            <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
                            <span v-if="loading === false">Löschen</span>
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <small>{{ new Date(item.createdAt).toLocaleString() }}</small>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  <small>{{ item.updatedAt ? new Date(item.updatedAt).toLocaleString() : '' }}</small>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, translations); dialogTranslation = true;">
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item, translations); dialogTranslationDelete = true;"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    loading: false,
    translations: [],
    dictionaries: [],
    headersDictionaries: [
      { text: 'Bezeichnung', value: 'label' },
      { text: 'Anzahl Translationen', value: 'translationsCount' },
      { text: 'Created At', value: 'createdAt' },
      { text: 'Updated At', value: 'updatedAt' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    dialogTranslation: false,
    dialogTranslationDelete: false,
    dialogDictionary: false,
    dialogDictionaryDelete: false,
    editedIndex: -1,
    editedItem: {
      value: '',
      translatedValue: '',
      label: '',
      dictionary: ''
    },
    defaultItem: {
      value: '',
      translatedValue: '',
      label: '',
      dictionary: ''
    },
    search: '',
    dictionaryFilterValue: ''
  }),
  computed: {
    headersTranslations () {
      return [
        { text: 'Wert', value: 'value' },
        { text: 'Translation', value: 'translatedValue' },
        {
          text: 'Dictionary',
          value: 'dictionary.label',
          filter: value => {
            if (!this.dictionaryFilterValue || this.dictionaryFilterValue === 'Alle') return true
            return value === this.dictionaryFilterValue
          }
        },
        { text: 'Created At', value: 'createdAt' },
        { text: 'Updated At', value: 'updatedAt' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    },
    dictionariesOptions () {
      return [{ label: 'Alle' }].concat(this.dictionaries)
    }
  },
  methods: {
    getTranslationList: async function () {
      const response = await this.$http.get('/stringtranslation')
      this.translations = response.data
    },
    getDictionaryList: async function () {
      const response = await this.$http.get('/dictionary')
      this.dictionaries = response.data
    },
    editItem (item, targetEntities) {
      this.editedIndex = targetEntities.indexOf(item)
      this.editedItem = Object.assign({}, item)
    },
    close () {
      this.dialogDictionary = false
      this.dialogTranslation = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItem (item, targetEntities) {
      this.editedIndex = targetEntities.indexOf(item)
      this.editedItem = Object.assign({}, item)
    },
    deleteItemConfirm: async function (entityType) {
      this.loading = true
      await this.$http.delete('/' + entityType + '/delete/' + this.editedItem.id)
      this.loading = false
      this.closeDelete()
      await this.getDictionaryList()
      await this.getTranslationList()
    },
    closeDelete () {
      this.dialogTranslationDelete = false
      this.dialogDictionaryDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save: async function (entityType) {
      this.loading = true

      // Generic payload working for both, translations and dictionaries.
      const payload = {
        value: this.editedItem.value,
        translatedValue: this.editedItem.translatedValue,
        label: this.editedItem.label,
        dictionaryId: this.editedItem.dictionary
      }

      if (this.editedIndex > -1) {
        await this.$http.patch('/' + entityType + '/update/' + this.editedItem.id, payload)
      } else {
        // Create new.
        await this.$http.post('/' + entityType, payload)
      }

      this.loading = false
      this.close()
      await this.getDictionaryList()
      await this.getTranslationList()
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    await this.getDictionaryList()
    await this.getTranslationList()
    this.initiated = true
  }
}
</script>
