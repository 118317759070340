<template>
  <div style="max-width: 40px;" class="text-center" v-if="target.isApproved || target.excludedByEditor">
    <v-menu offset-y v-if="!loading" v-model="closeOnContentClick">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon>
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>

      <v-list dense nav>
        <!-- Metadata -->
        <v-list-item two-line>
          <v-list-item-icon>
            <v-icon>mdi-card-account-details</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>ID</v-list-item-title>
            <v-list-item-subtitle class="mono">
              {{ target.id }}
              <v-icon @click="copyToClipboard(target.id)" class="cursor-pointer">mdi-content-copy</v-icon>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item two-line>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Erstellt am:</v-list-item-title>
            <v-list-item-subtitle class="mono">{{ new Date(target.createdAt).toLocaleString() }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-icon>
            <v-icon>mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Aktualisiert am:</v-list-item-title>
            <v-list-item-subtitle class="mono">{{ target.updatedAt ? new Date(target.updatedAt).toLocaleString() : '' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="releasable"></v-divider>
        <v-list-item two-line v-if="releasable">
          <v-list-item-icon>
            <v-icon>mdi-database-search</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Elasticsearch erstmals synchronisiert:</v-list-item-title>
            <v-list-item-subtitle class="mono">{{ target.elasticSearchFirstReleasedAt ? new Date(target.elasticSearchFirstReleasedAt).toLocaleString() : '-' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line v-if="releasable">
          <v-list-item-icon>
            <v-icon>mdi-database-search</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Elasticsearch zuletzt synchronisiert:</v-list-item-title>
            <v-list-item-subtitle class="mono">{{ target.elasticSyncExecuted ? new Date(target.elasticSyncExecuted).toLocaleString() : '-' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line v-if="releasable">
          <v-list-item-icon>
            <v-icon>mdi-database-search</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Elasticsearch markiert für Synchronisation:</v-list-item-title>
            <v-list-item-subtitle class="mono">{{ target.elasticSyncRequested ? new Date(target.elasticSyncRequested).toLocaleString() : '-' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="releasable"></v-divider>
        <v-list-item two-line v-if="releasable">
          <v-list-item-icon>
            <v-icon>mdi-salesforce</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Salesforce erstmals synchronisiert:</v-list-item-title>
            <v-list-item-subtitle class="mono">{{ target.salesForceFirstReleasedAt ? new Date(target.salesForceFirstReleasedAt).toLocaleString() : '-' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line v-if="releasable">
          <v-list-item-icon>
            <v-icon>mdi-salesforce</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Salesforce zuletzt synchronisiert:</v-list-item-title>
            <v-list-item-subtitle class="mono">{{ target.salesForceSyncExecuted ? new Date(target.salesForceSyncExecuted).toLocaleString() : '-' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line v-if="releasable">
          <v-list-item-icon>
            <v-icon>mdi-salesforce</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Salesforce markiert für Synchronisation:</v-list-item-title>
            <v-list-item-subtitle class="mono">{{ target.salesForceSyncRequested ? new Date(target.salesForceSyncRequested).toLocaleString() : '-' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- Exclude -->
        <v-divider v-if="releasable"></v-divider>
        <v-list-item v-if="releasable && !target.excludedByEditor" v-on:click.stop="showDialog = true" v-on:click="closeOnContentClick = false" class="entry red white--text">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>redaktionell ausschließen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <ExcludeDialog v-model="showDialog" :targetClass="targetClass" :id="id"></ExcludeDialog>

        <v-list-item v-if="releasable && target.excludedByEditor" v-on:click="returnExcluding" class="entry green white--text">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-restart</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>wiederherrstellen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Award change Period -->
        <v-divider v-if="targetClass === 'award'"></v-divider>
        <v-list-item v-if="targetClass === 'award'" v-on:click.stop="changePeriodDialog = true" class="entry primary white--text">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Jahr ändern</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-dialog v-model="changePeriodDialog" max-width="450">
          <v-card>
            <v-card-title>
              <v-icon large left>mdi-information-outline</v-icon>
              <span class="title">Result</span>
            </v-card-title>
            <v-card-text>
              Hier kann das Jahr des Awards geändert werden.
              <v-text-field
                v-model="newPeriod"
                label="Jahr"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="px-6 pb-6">
              <v-btn v-on:click="changePeriodDialog=false" class="primary--text" outlined>
                Abbrechen
              </v-btn>
              <v-btn v-on:click="changePeriod(id, newPeriod)" class="primary--text" outlined>
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-list>
    </v-menu>

    <!-- Accept all -->
    <v-tooltip bottom v-if="hasChanges">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on:click="acceptAll" v-on="on" v-bind="attrs" icon :disabled="acceptAllLock" :loading="acceptAllLock">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </template>
      <span>Alle Änderungen annehmen</span>
    </v-tooltip>

    <!-- Mark for sync -->
    <v-tooltip bottom v-if="targetClass === 'account' || targetClass === 'contact' || targetClass === 'award'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on:click="markForSync" v-on="on" v-bind="attrs" icon>
          <v-icon>mdi-cloud-upload-outline</v-icon>
        </v-btn>
      </template>
      <span>Markieren zur Elasticsearch und Salesforce Aktualisierung</span>
    </v-tooltip>

    <!-- Follow Up (Rückfrage) -->
    <v-tooltip bottom v-if="targetClass === 'account' || targetClass === 'contact'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on:click="toggleFollowUp" v-on="on" v-bind="attrs" icon>
          <v-icon v-if="target.markedForFollowUp === false">mdi-help-circle-outline</v-icon>
          <v-icon v-else>mdi-progress-question</v-icon>
        </v-btn>
      </template>
      <span v-if="target.markedForFollowUp === false">Zum Follow Up (Rückfrage) markieren</span>
      <span v-else>Follow Up (Rückfrage) Markierung zurücksetzen</span>
    </v-tooltip>

    <!-- View in Google Maps (via coordinates) -->
    <v-tooltip bottom v-if="targetClass === 'account'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :href="getGoogleMapsLink" target="_blank" v-on="on" v-bind="attrs" icon>
          <v-icon class="grey--text text--darken-1">mdi-google-maps</v-icon>
        </v-btn>
      </template>
      <span>In Google Maps ansehen (via hinterlegte Koordinaten)</span>
    </v-tooltip>

    <!-- Open in new tab -->
    <v-tooltip bottom v-if="(targetClass === 'account' || targetClass === 'contact') && this.$route.path !== targetPath">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :to="targetPath" target="_blank" v-on="on" v-bind="attrs" icon>
          <v-icon class="grey--text text--darken-1">mdi-open-in-new</v-icon>
        </v-btn>
      </template>
      <span>Im neuen Tab öffnen</span>
    </v-tooltip>

    <v-btn v-if="loading" icon right color="primary" outlined loading></v-btn>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card class="pa-3">
        <v-card-title class="headline">Hinweis</v-card-title>
        <v-card-text>{{ dialogText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" v-on:click="reload">Ok</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ExcludeDialog from './ExcludeDialog'

export default {
  components: { ExcludeDialog },
  props: {
    targetClass: String,
    id: String
  },
  data: () => ({
    excluded: false,
    loading: false,
    showDialog: false,
    closeOnContentClick: false,
    changes: {},
    acceptAllLock: false,
    changePeriodDialog: false,
    newPeriod: null,
    dialog: false,
    dialogText: null
  }),
  created () {
    this.target = this.$state.getFromRegistry(this.id)
    this.changes = this.$changeService.getPendingChangesByTarget(this.id, this.targetClass)
  },
  methods: {
    exclude: async function () {
      this.loading = true
      const change = {
        targetClass: this.targetClass,
        targetId: this.id,
        attributeName: 'excludedByEditor',
        newValue: true
      }
      await this.$http.post('/change', change)
      this.target.excludedByEditor = true
      this.loading = false
    },
    returnExcluding: async function () {
      this.loading = true
      const change = {
        targetClass: this.targetClass,
        targetId: this.id,
        attributeName: 'excludedByEditor',
        newValue: false
      }
      await this.$http.post('/change', change)
      this.target.excludedByEditor = false
      this.loading = false
      this.closeOnContentClick = false
    },
    acceptAll: async function () {
      this.acceptAllLock = true
      await this.$changeService.bulkAccept(this.id, this.targetClass)
      this.acceptAllLock = false
    },
    changePeriod: async function (id, period) {
      this.loading = true
      await this.$http.post('/award/change-period/' + id + '/' + period)
      this.changePeriodDialog = false
      this.closeOnContentClick = false
      this.loading = false
    },
    toggleFollowUp: async function () {
      this.loading = true
      const state = this.target.markedForFollowUp === false
      const stateAsInt = +state
      await this.$http.put('/flag/follow-up/' + this.targetClass + '/' + this.id + '/' + stateAsInt)
      this.target.markedForFollowUp = state
      this.loading = false
    },
    markForSync: async function () {
      this.loading = true
      const response = await this.$http.put('/flag/sync/' + this.targetClass + '/' + this.id)
      this.dialog = true
      this.dialogText = response.data
      this.loading = false
    },
    reload: function () {
      window.location.reload()
    },
    copyToClipboard: function (value) {
      navigator.clipboard.writeText(value)
    }
  },
  computed: {
    hasChanges: function () {
      return Object.keys(this.changes).length !== 0
    },
    targetPath: function () {
      return '/' + this.targetClass + '/' + this.id
    },
    releasable: function () {
      return this.targetClass !== 'score' && this.targetClass !== 'product'
    },
    getGoogleMapsLink: function () {
      return ('https://www.google.de/maps/place/').concat(this.target.latitude, ',', this.target.longitude)
    }
  }
}
</script>

<style scoped>
.v-list-item.entry {
   min-height: 30px;
 }
</style>
