<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row>
        <v-col sm="12">
          <h1>Templates für Datenlieferungen</h1>
          <p>Hier können die aktuellen Templates für verschiedenste Datenlieferungen heruntergeladen werden.</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="12" class="pt-5">
          <h2>Allgemeine Templates</h2>
          <p>Diese Templates sind allgemeingültig. Das heißt: sie können verwendet werden, ganz egal um welches Projekt es sich handelt.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Use Case</th>
                  <th class="text-left">Name</th>
                  <th class="text-left">Version</th>
                  <th class="text-left">Download</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="template in templates"
                  :key="template.name"
                >
                  <td>{{ template.useCase }}</td>
                  <td>{{ template.name }}</td>
                  <td>{{ template.version }}</td>
                  <td>
                    <v-btn class="primary" v-if="template.name !== null" v-on:click="downloadFile(template.name)"><v-icon class="mr-1">mdi-download</v-icon>download</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="12" class="pt-5">
          <h2>Projektspezifische Templates</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header><span class="monospace">AGM - Arbeitgeber Mittelstand (KMU)</span></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">Use Case</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Version</th>
                      <th class="text-left">Download</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="template in templates_AGM"
                      :key="template.name"
                    >
                      <td>{{ template.useCase }}</td>
                      <td>{{ template.name }}</td>
                      <td>{{ template.version }}</td>
                      <td>
                        <v-btn class="primary" v-if="template.name !== null" v-on:click="downloadFile(template.name)"><v-icon class="mr-1">mdi-download</v-icon>download</v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header><span class="monospace">DLT - Deutschland Test</span></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">Use Case</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Version</th>
                      <th class="text-left">Download</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="template in templates_DLT"
                      :key="template.name"
                    >
                      <td>{{ template.useCase }}</td>
                      <td>{{ template.name }}</td>
                      <td>{{ template.version }}</td>
                      <td>
                        <v-btn class="primary" v-if="template.name !== null" v-on:click="downloadFile(template.name)"><v-icon class="mr-1">mdi-download</v-icon>download</v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header><span class="monospace">SMA - Produkttest - Smartphones (Deutschland Test)</span></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">Use Case</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Version</th>
                      <th class="text-left">Download</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="template in templates_SMA"
                      :key="template.name"
                    >
                      <td>{{ template.useCase }}</td>
                      <td>{{ template.name }}</td>
                      <td>{{ template.version }}</td>
                      <td>
                        <v-btn class="primary" v-if="template.name !== null" v-on:click="downloadFile(template.name)"><v-icon class="mr-1">mdi-download</v-icon>download</v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header><span class="monospace">TKV - Tierversicherung</span></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">Use Case</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Version</th>
                      <th class="text-left">Download</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="template in templates_TKV"
                      :key="template.name"
                    >
                      <td>{{ template.useCase }}</td>
                      <td>{{ template.name }}</td>
                      <td>{{ template.version }}</td>
                      <td>
                        <v-btn class="primary" v-if="template.name !== null" v-on:click="downloadFile(template.name)"><v-icon class="mr-1">mdi-download</v-icon>download</v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    loading: false,
    // For now, we hardcode the available templates. Let's see if we even need something more sophisticated.
    templates: [
      {
        useCase: 'Ich möchte Accounts neu anlegen oder aktualisieren (ohne Relationen)',
        name: 'generic_account_template-2023-08-15.xlsx',
        version: '2023-08-15'
      },
      {
        useCase: 'Ich möchte Accounts neu anlegen oder aktualisieren (ohne Relationen, mit Markenbezug)',
        name: 'generic_account_brand_template-2023-08-15.xlsx',
        version: '2023-08-15'
      },
      {
        useCase: 'Ich möchte Accounts neu anlegen oder aktualisieren (mit "mainContact")',
        name: 'generic_account_with_main_contact_template-2023-08-15.xlsx',
        version: '2023-08-15'
      },
      {
        useCase: 'Ich möchte Accounts mit mainContacts verknüpfen (nur Relationen, keine Felder)',
        name: 'generic_account_main_contact_relationship_template-2022-08-08.xlsx',
        version: '2022-08-08'
      },
      {
        useCase: 'Ich möchte Account-Koordinaten zurücksetzen lassen.',
        name: 'generic_account_coordinate_reset-2023-11-16.xlsx',
        version: '2023-11-16'
      },
      {
        useCase: 'Ich möchte Account-Koordinaten zurücksetzen lassen.',
        name: 'generic_account_coordinate_reset-2023-11-16.xlsx',
        version: '2023-11-16'
      },
      {
        useCase: 'Ich möchte Konten auf den Status account_excluded_by_editor setzen',
        name: 'generic_exclude_account_template-2024-04-26.xlsx',
        version: '2024-04-25'
      },
      {
        useCase: 'Ich möchte Contacts neu anlegen oder aktualisieren (mit "mainAccount")',
        name: 'generic_contact_with_main_account_template-2023-08-15.xlsx',
        version: '2023-08-15'
      },
      {
        useCase: 'Ich möchte Contacts mit mainAccounts verknüpfen (nur Relationen, keine Felder)',
        name: 'generic_contact_main_account_relationship_template-2022-10-27.xlsx',
        version: '2022-10-27'
      }
    ],
    templates_AGM: [
      {
        useCase: 'Ich möchte Arbeitgeber-Mittelstand-Awards neu anlegen oder aktualisieren',
        name: 'agm_award_template-2023-07-21.xlsx',
        version: '2023-07-21'
      }
    ],
    templates_DLT: [
      {
        useCase: 'Ich möchte Awards neu anlegen oder aktualisieren',
        name: 'dlt_award_template-2023-04-25.xlsx',
        version: '2023-04-25'
      }
    ],
    templates_SMA: [
      {
        useCase: 'Ich möchte Smartphone-Awards neu anlegen oder aktualisieren',
        name: 'sma_award_template-2023-04-25.xlsx',
        version: '2023-04-25'
      }
    ],
    templates_TKV: [
      {
        useCase: 'Ich möchte Tierversicherung-Awards (Tierhaftpflicht) neu anlegen oder aktualisieren',
        name: 'tkv_award_tierhaftpflicht_template-2023-08-23.xlsx',
        version: '2023-08-23'
      },
      {
        useCase: 'Ich möchte Tierversicherung-Awards (Tierkrankenschutz) neu anlegen oder aktualisieren',
        name: 'tkv_award_tierkrankenschutz_template-2023-08-23.xlsx',
        version: '2023-08-23'
      }
    ]
  }),
  methods: {
    downloadFile: async function (filename) {
      if (filename === null) {
        return
      }

      await this.$http.get(
        '/download/supplier_templates/' + filename,
        { responseType: 'blob' }
      ).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        const fileName = response.headers['content-disposition'].split('filename=').pop()
        fileLink.href = fileURL
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
      })
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    this.initiated = true
  }
}
</script>
