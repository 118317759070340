<template>
  <v-container fluid class="relative header-space container-full">
    <div>
      <h1>Import</h1>
      <v-row v-if="initiated">
        <v-col md="3" sm="12"  class="pr-0">
          <v-stepper class="importStepper" v-model="currentStep" vertical>
            <v-stepper-step step="1" :complete="responseMessage" class="pl-0">Daten-Mapping Check</v-stepper-step>
            <v-stepper-content step="1" class="ml-2 mr-7 pr-2">
              <p class="small grey--text text--darken-2">
                Um zu Überprüfen, ob der hochgeladene Datensatz im Data Warehouse verarbeitbar ist,
                muss ein passendes Mapping ausgewählt werden.
                Der Daten-Mapping Check kann genutzt werden, um eine Lieferung auf konkrete Form zu prüfen.
              </p>
              <v-select
                v-model="selectedMappingId"
                v-on:change="selectMapping"
                class="pb-0"
                color="blue darken-3"
                label="Daten-Mapping"
                :items="availableMappings"
                item-text="name"
                item-value="id"
                :disabled="dryRunResult !== null || sampleProcessed === false"
                item-color="blue darken-3"
                hide-details
              ></v-select>

              <!-- Clone selected Mapping-->
              <div v-if="selectedMappingId && !openCloneMapping" align="right">
                <p class="mb-0 mt-3 small grey--text text--darken-2 text-left">
                  Müssen an dem oben ausgewählten Mapping Änderungen vorgenommen werden?
                </p>
                <v-btn
                  v-if="selectedMappingId && !openCloneMapping"
                  v-on:click="openCloneMapping = true"
                  class="px-0 my-1"
                  color="grey darken-2"
                  :disabled="loading"
                  text
                >
                  <v-icon class="pr-1">mdi-plus-box-multiple-outline</v-icon>
                  Mapping kopieren
                </v-btn>
              </div>
              <v-row v-show="openCloneMapping">
                <v-col md="12">
                  <p class="mb-0 mt-3 small grey--text text--darken-2">
                    Für das ausgewählte Mapping soll ein kopiertes Mapping mit folgendem Namen erstellt werden:
                  </p>
                </v-col>
                <v-col md="12">
                  <v-text-field
                    v-model="newName"
                    label="Mapping-Name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col md="8">
                  <v-btn
                    v-if="!loading"
                    v-on:click="cloneMapping"
                    class="mb-2"
                    color="blue darken-3"
                    :disabled="newName.length <= 5"
                    outlined
                    block
                  >
                    Speichern
                  </v-btn>
                  <v-btn v-if="loading" color="blue darken-3" block outlined>
                    <v-progress-circular color="blue darken-2" :size="25" :width="2" indeterminate>
                    </v-progress-circular>
                  </v-btn>
                </v-col>
                <v-col md="4" class="pl-0">
                  <v-btn
                    v-on:click="openCloneMapping = false"
                    class="mb-2 px-2"
                    color="grey darken-2"
                    align="right"
                    text
                    block
                  >
                    Abbrechen
                  </v-btn>
                </v-col>
              </v-row>

              <!-- Add new Mapping-->
              <div align="right" v-if="!openNewMapping">
                <p class="mb-0 mt-3 small grey--text text--darken-2 text-left">
                  Existiert noch kein passendes Mapping und kann auch kein vergleichbares Mapping kopiert werden?
                </p>
                <v-btn
                  v-on:click="openNewMapping = true"
                  class="px-0 my-1"
                  color="grey darken-2"
                  :disabled="loading"
                  text
                >
                  <v-icon>mdi-plus</v-icon>
                  Neues Mapping
                </v-btn>
              </div>
              <v-row v-show="openNewMapping">
                <v-col md="12">
                  <p class="mb-0 mt-3 small grey--text text--darken-2">
                    Es soll ein neues Mapping mit folgendem Namen erstellt werden:
                  </p>
                </v-col>
                <v-col md="12">
                  <v-text-field
                    v-model="newName"
                    label="Mapping-Name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col md="12">
                  <v-select
                    v-model="selectedTargetClass"
                    class="pt-0"
                    label="Kontextbezogene Gruppe"
                    :items="targetClasses"
                    required
                  ></v-select>
                </v-col>
                <v-col md="8" class="mb-2">
                  <v-btn
                    v-if="!loading"
                    v-on:click="addNewMapping"
                    color="blue darken-3"
                    :disabled="newName.length < 4"
                    outlined
                    block
                  >
                    Speichern
                  </v-btn>
                  <v-btn v-if="loading" color="blue darken-3" block outlined>
                    <v-progress-circular color="blue darken-3" :size="25" :width="2" indeterminate>
                    </v-progress-circular>
                  </v-btn>
                </v-col>
                <v-col md="4" class="pl-0">
                  <v-btn
                    v-on:click="openNewMapping = false"
                    class="mb-2 px-2"
                    color="grey darken-2"
                    align="right"
                    text
                    block
                  >
                    Abbrechen
                  </v-btn>
                </v-col>
              </v-row>

              <!-- Shows selected Mapping on Account/Contact in first Row of uploaded file -->
              <v-btn
                v-if="readyToCheckSampleRow"
                v-on:click="leaveStepOne"
                class="mb-2"
                color="blue darken-3"
                :disabled="startCheckSampleRow === false"
                block
                outlined
              >
                Test-Eintrag prüfen
              </v-btn>
              <p v-if="dryRunResult !== null" class="grey--text text--darken-2 pt-4 small">
                Anzahl der vorgeschlagegen Änderungen: {{ dryRunResult.count_of_suggested_changes }}
              </p>
            </v-stepper-content>

            <v-stepper-step step="2" :complete="dryRunResult !== null" class="pl-0">Dry Run</v-stepper-step>
            <v-stepper-content step="2" class="ml-2 mr-7 pr-2">
              <p class="small grey--text text--darken-2">
                Entspricht der Test-Eintrag den Erwartungen kann der Dry Run ausgeführt werden.
                Während des Dry-Runs wird die übergebene Lieferung vollständig durch den Import-Prozess geführt.
                Allerdings werden dabei keine Änderungen am System oder den Daten vorgenommen.
                Ein Dry Run kann jederzeit, vollständig ohne Risiko durchgeführt werden.
              </p>
              <v-btn
                v-if="!loading"
                v-on:click="executeDryRun"
                color="blue darken-3"
                outlined
                block
              >
                Dry Run Import
              </v-btn>
              <v-btn v-if="loading" color="blue darken-3" block outlined>
                <v-progress-circular indeterminate color="blue darken-3" :size="25" :width="2"></v-progress-circular>
              </v-btn>
              <p v-if="dryRunResult !== null" class="grey--text text--darken-2 pt-4 small">
                Anzahl der vorgeschlagegen Änderungen: {{ dryRunResult.count_of_suggested_changes }}
              </p>
            </v-stepper-content>

            <v-stepper-step step="3" :complete="importResult !== null" class="pl-0">Import durchführen</v-stepper-step>
            <v-stepper-content step="3" class="ml-2 mr-7">
              <p class="small grey--text text--darken-2">
                Ein Dry Run wurde durchgeführt.<br>
                Ist die konkrete Form geprüft und entspricht den Anforderungen, können die Daten importiert werden,
                und stehen der Verifikation zur Verfügung.
              </p>
              <v-switch v-model="matchByFieldsInRelations" label="Match by Fields in Relations" class="ml-3" :disabled="importIsReady"></v-switch>
              <p class="small grey--text text--darken-2">
                Versucht beim Import nach Feldern im Subset der bereits referenzierten Entities der Root Entity zu matchen (wenn es keine IDs gibt).
              </p>
              <v-switch v-model="force" label="Force Import" class="ml-3" :disabled="importIsReady"></v-switch>
              <p class="small grey--text text--darken-2">
                Markiert alle Inhalte dieser Datei zur Verifikation, auch dann,
                wenn der importierte Wert dem System bereits bekannten gleicht.
              </p>
              <v-switch v-model="matchByFields" label="Match by Fields" class="ml-3" :disabled="importIsReady"></v-switch>
              <p class="small grey--text text--darken-2">
                Versucht beim Import global nach Feldern zu matchen (wenn es keine IDs gibt). Unterstützt aktuell nur Contacts.
              </p>
              <v-switch v-model="skipInserts" label="Skip Inserts" class="ml-3" :disabled="importIsReady"></v-switch>
              <p class="small grey--text text--darken-2">
                Ignoriert unbekannte Entitäten. Es werden nur Updates für bereits existierende Entitäten importiert.
              </p>
              <v-select
                label="Mapping Regel"
                v-model="selectedMappingRule"
                :items="mappingRules"
                item-text="label"
                item-value="mappingRule"
              ></v-select>
              <p class="small grey--text text--darken-2">
                Eine Mapping Regel wendet bestimmte Sonderlogiken während des Imports an.
                Diese Regeln müssen zuvor mit der IT besprochen und auch durch diese angelegt werden.
              </p>
              <v-btn
                v-if="!importIsReady && !loading"
                v-on:click="executeImport"
                class="mr-4"
                color="blue darken-3"
                outlined
                block
              >
                Import starten
              </v-btn>
              <v-btn v-if="loading" color="blue darken-3" block outlined>
                <v-progress-circular color="blue darken-3" :size="25" :width="2" indeterminate></v-progress-circular>
              </v-btn>
              <v-btn
                v-if="importIsReady"
                to="/verifikation"
                class="mr-4"
                color="blue darken-3"
                outlined
                block
              >
                Zur Verifikation
              </v-btn>
            </v-stepper-content>
          </v-stepper>
        </v-col>

        <v-col md="9" sm="12" >
          <!-- Feedback-Cards for actions pressed in the stepper -->
          <div v-if="showFeedback">
            <!-- Visible if no "Daten-Mapping" is selected -->
            <v-card v-if="selectedMappingId === null && !loading" class="account-placeholder">
              <h3 class="text-center grey--text">
                <v-icon color="grey" large>mdi-information-outline</v-icon>
                <br>
                <span v-if="sampleProcessed === false">
                  <v-progress-circular color="grey" class="mr-2" :width="3" indeterminate></v-progress-circular>
                  Import Sample wird vorbereitet... bitte warten.
                </span>
                <span v-else>Um einen Test-Eintrag zu sehen, muss zuerst ein Daten-Mapping ausgewählt werden</span>
              </h3>
            </v-card>
            <!-- Visible while loading -->
            <v-card v-if="loading" class="account-placeholder">
              <h3 class="text-center grey--text">
                <v-progress-circular color="grey" class="mr-2" :width="3" indeterminate></v-progress-circular>
                <br>
                Daten werden geprüft.
              </h3>
            </v-card>
            <!-- Visible if inspection for mapping is matching -->
            <v-card v-if="matchingMappingFeedback" class="account-placeholder mb-4 center" align="center">
              <h3 class="text-center grey--text">
                <v-icon color="light-green" large>mdi-checkbox-marked-circle-outline</v-icon>
                <br>
                Das ausgewählte Mapping passt zum hochgeladenen Original-Datensatz.<br>
                <span v-if="startCheckSampleRow === false">
                  <v-progress-circular color="grey" class="mr-2" :width="3" indeterminate></v-progress-circular>
                  Test-Eintrag wird gemapped...
                </span>
                <span v-else>Bitte im nächsten Schritt den Test-Eintrag prüfen.</span>
              </h3>
              <v-btn
                v-if="selectedMappingId && responseMessage && !openCloneMapping"
                v-on:click="leaveStepOne"
                class="mb-2 mt-2"
                color="light-green darken-1"
                :disabled="startCheckSampleRow === false"
                text
              >
                Test-Eintrag prüfen
              </v-btn>
            </v-card>
            <!-- Visible if inspection for mapping is not matching -->
            <v-card v-if="noMatchingMappingFeedback" class="account-placeholder">
              <h3 class="text-center grey--text">
                <v-icon color="amber" large>mdi-alert-circle-outline</v-icon>
                <br>Das ausgewählte Daten-Mapping lässt sich nicht auf den hochgeladenen Datensatz anwenden:
              </h3>
              <div v-for="notification in $state.notifications" v-bind:key="notification.tid">
                <!-- explicit error with message and status -->
                <p v-if="notification.data && notification.data.message" class="body-2 explanation text-center mb-0">
                  Status Code {{ notification.status }}: {{ notification.data.message }}
                </p>
              </div>
            </v-card>
            <!-- Visible if DryRun is finished -->
            <v-card v-if="dryRunIsReadyFeedback" class="account-placeholder" align="center">
              <h3 class="text-center grey--text">
                <v-icon color="light-green" large>mdi-checkbox-marked-circle-outline</v-icon>
                <br>Der Dry Run war erfolgreich. <br>Die Daten können jetzt importiert werden.
              </h3>
              <v-btn
                v-on:click="executeImport"
                color="light-green darken-1"
                text
                class="mr-4 mt-1"
              >
                Import starten
              </v-btn>
            </v-card>
            <!-- Visible if Import is finished -->
            <v-card v-if="importIsReady" class="account-placeholder" align="center">
              <h3 class="text-center grey--text">
                <v-icon color="light-green" large>mdi-checkbox-marked-circle-outline</v-icon>
                <br>Die Daten wurden erfolgreich importiert.<br>
                <span v-if="importResult !== null">Es wurden {{ importResult.changes_created }} Changes erstellt.</span><br>
              </h3>
              <span class="mb-0 mt-3 small grey--text text--darken-2">Dauer des Importvorgangs: {{ importDuration }}s</span><br>
              <v-btn to="/verifikation" color="light-green darken-1" lass="mr-4 mt-2" text>
                Zur Verifikation
              </v-btn>
            </v-card>
          </div>

          <!-- Shows exemplary first Row of uploaded file to make Mapping more transparent -->
          <!-- Visible if Account/Contact matches to mapping and before DryRun has started-->
          <div v-if="showDetailInfo">
            <v-card class="pa-4 mb-4">
              <h5>Original-Daten</h5>
              <v-row>
                <v-col>
                  <p class="explanation body-2">
                    An dieser Stelle wird eine Reihe des Original-Datensatzes angezeigt.<br>
                    Man kann diese mit dem ausgewählten Mapping vergleichen.
                  </p>
                </v-col>
                <v-col>
                  <v-btn
                    v-if="!showMapping"
                    v-on:click="showMapping = true"
                    class="mapping-detail-button"
                    color="grey darken-2"
                    outlined
                    rounded
                  >
                    Mapping anzeigen
                  </v-btn>
                  <v-btn
                    v-if="showMapping"
                    v-on:click="showMapping = false"
                    class="mapping-detail-button"
                    color="grey darken-2"
                    outlined
                    icon
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-simple-table>
                <tr>
                  <td v-for="(headerValue, headerName) in partialData" style="vertical-align: top;" class="px-0 pb-2">
                    <div class="bg-white body-2 bold grey--text text--darken-2 fake-header px-3">{{ headerName }}</div>
                    <v-divider class="divider"></v-divider>
                    <div class="fake-row monospace px-3">{{ headerValue }}</div>
                    <div v-show="showMapping">
                      <v-divider class="divider"></v-divider>
                      <div>
                        <p class="mb-0 px-3" v-for="(mappingItemValue, mappingItemName) in getMappingForColumn(headerName)">
                          <v-icon class="small" color="blue darken-3">mdi-arrow-right-bold</v-icon>
                          <span class="blue--text text--darken-3"> {{ mappingItemName }}: </span>
                          <span class="mono">{{ mappingItemValue }}</span>
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              </v-simple-table>
            </v-card>

            <v-alert type="info" prominent outlined color="blue darken-3" class="account-Info pl-6">
              <p class="pl-5 mb-0">Dieser Eintrag ist exemplarisch für den gelieferten Import.<br>
                Diese temporäre Daten, dienen zur Überprüfung des Mappings und sind noch nicht im System gespeichert. <br>
                Bei Bestätigung des Imports werden alle Daten der Lieferung im gleichen Muster verarbeitet.
              </p>
            </v-alert>
            <!-- Here should be displayed the first Account/Contact of the uploaded import -->
            <Detail v-if="inspectionRootId" :propId="inspectionRootId" :inspectionMode="true" :propRootType="rootType" class="pt-3"></Detail>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Detail from '../views/Detail'
import { find } from 'lodash'

export default {
  components: {
    Detail
  },
  data: () => ({
    initiated: false,
    sampleId: null,
    dryRunResult: null,
    importResult: null,
    selectedMappingId: null,
    availableMappings: [],
    rowIndex: 0,
    inspectionResult: {},
    currentStep: 1,
    showMapping: false,
    loading: false,
    hasError: false,
    responseMessage: false,
    message: null,
    leave: true,
    showDetailInfo: false,
    partType: 'row',
    partialData: {},
    matchingMapping: {},
    inspectionRootId: null,
    openCloneMapping: false,
    newName: '',
    mappingId: null,
    showTemporaryDialog: false,
    importIsReady: false,
    dryRunIsReady: false,
    selectedTargetClass: null,
    targetClasses: ['account', 'contact'],
    openNewMapping: false,
    rootType: null,
    force: false,
    matchByFields: false,
    matchByFieldsInRelations: true,
    skipInserts: false,
    showFeedback: true,
    startCheckSampleRow: false,
    importDuration: 0,
    sampleProcessed: false,
    mappingRules: [
      { mappingRule: null, label: 'Default - (Keine MappingRule anwenden)' },
      { mappingRule: 'CreateMainRelationOnlyIfAwardeeNew', label: 'CreateMainRelationOnlyIfAwardeeNew' },
      { mappingRule: 'SetOtherAsMainContactIfMainIsMissing', label: 'SetOtherAsMainContactIfMainIsMissing' }
    ],
    selectedMappingRule: null
  }),
  async created () {
    // Firewall
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }
    this.sampleId = this.$route.params.sampleId
    // Run pre-processing
    this.$http.post('/sample/' + this.sampleId + '/process')
      .then((response) => {
        this.sampleProcessed = true
      })
    // Load all active mappings
    const response = await this.$http.get('/mapping/list/0')
    this.availableMappings = response.data
    this.initiated = true
  },
  methods: {
    executeDryRun: async function () {
      // Execute Dry-Run
      this.showFeedback = true
      this.showDetailInfo = false
      this.loading = true

      // Init payload
      const payload = {
        sampleId: this.sampleId,
        mappingId: this.selectedMappingId,
        force: this.force,
        matchByFields: this.matchByFields,
        matchByFieldsInRelations: this.matchByFieldsInRelations,
        skipInserts: this.skipInserts
      }
      if (this.selectedMappingRule !== null) {
        payload.mappingRule = this.selectedMappingRule
      }

      const response = await this.$http.post('/mapping/dryrun', payload)
      this.dryRunResult = response.data
      this.currentStep = 3
      this.loading = false
      this.dryRunIsReady = true
    },
    executeImport: async function () {
      this.loading = true
      const start = Date.now()

      // Init payload
      const payload = {
        sampleId: this.sampleId,
        mappingId: this.selectedMappingId,
        force: this.force,
        matchByFields: this.matchByFields,
        matchByFieldsInRelations: this.matchByFieldsInRelations,
        skipInserts: this.skipInserts
      }
      if (this.selectedMappingRule !== null) {
        payload.mappingRule = this.selectedMappingRule
      }

      const response = await this.$http.post('/mapping/import', payload)
      this.importResult = response.data
      this.importDuration = (Date.now() - start) / 1000
      this.loading = false
      this.importIsReady = true
    },
    selectMapping: function () {
      // Run an inspection
      this.loading = true
      this.hasError = false
      this.responseMessage = false
      this.$changeService.reset()

      // Init payload
      const payload = {
        sampleId: this.sampleId,
        mappingId: this.selectedMappingId,
        force: this.force,
        matchByFields: this.matchByFields,
        matchByFieldsInRelations: this.matchByFieldsInRelations,
        skipInserts: this.skipInserts,
        rowIndex: this.rowIndex
      }
      if (this.selectedMappingRule !== null) {
        payload.mappingRule = this.selectedMappingRule
      }
      const responsePromise = this.$http.post('/mapping/inspect', payload)
      responsePromise.then(async (response) => {
        this.inspectionResult = response.data
        console.log(this.inspectionResult)

        if (this.inspectionResult.root === null) {
          this.loading = false
          this.hasError = true
          this.$state.addNotification(
            {
              data: {
                message: 'Es wurde keine Root-Entity (= Awardee) gefunden und es gibt nicht genügend Felder im Sample um eine neue Root-Entity anzulegen. Wahrscheinlich wurde nur eine Awardee ID angegeben, aber ein entsprechender Awardee kann nicht gefunden werden.'
              },
              status: 422
            }
          )
          return
        }

        // Pre Hydrate registry
        this.$state.addToRegistry(this.inspectionResult.root)
        // Hydrate registry with changes
        this.$changeService.addChanges(this.inspectionResult.changes)
        this.inspectionRootId = this.inspectionResult.root.id
        this.loading = false
        this.responseMessage = true
        // Load matching Mapping
        const mappingResponse = await this.$http.get('/mapping/' + this.selectedMappingId)
        this.matchingMapping = mappingResponse.data
        // Search for matching rootType to pass as a prop to Detail.vue
        if (this.matchingMapping.targetClass.endsWith('Account')) {
          this.rootType = 'account'
        } else {
          this.rootType = 'contact'
        }
        // Load partial
        const partialResponse = await this.$http.get('/sample/' + this.sampleId + '/partial/' + this.partType + '/' + this.rowIndex)
        this.partialData = partialResponse.data
        this.startCheckSampleRow = true
      },
      (reason) => {
        // Inspection failed.
        this.message = reason.message
        console.log(reason.message)
        this.loading = false
        this.hasError = true
      }
      )
    },
    leaveStepOne () {
      this.leave = false
      this.currentStep = 2
      this.showFeedback = false
      this.showDetailInfo = true
    },
    getMappingForColumn (headerName) {
      // Get the column from Mapping where headerName equals source
      return find(this.matchingMapping.columns, { source: headerName })
    },
    cloneMapping: async function () {
      this.openCloneMapping = true
      this.loading = true
      const payload = { name: this.newName, mappingId: this.selectedMappingId }
      await this.$http.post('/mapping/clone', payload)
      // Reset and close input
      this.newName = ''
      this.mappingId = null
      this.openCloneMapping = false
      this.loading = false
      this.openEditor()
    },
    addNewMapping: async function () {
      this.openNewMapping = true
      this.loading = true
      const payload = { name: this.newName, targetClass: this.selectedTargetClass }
      await this.$http.post('/mapping/create', payload)
      this.openNewMapping = false
      this.loading = false
      this.openEditor()
    },
    async openEditor () {
      await this.$router.push({ path: '/mapping-editor' })
    }
  },
  computed: {
    readyToCheckSampleRow () {
      if (this.selectedMappingId === true) {
        return true
      }
      if (this.responseMessage === true) {
        return true
      }
      if (this.openCloneMapping === false) {
        return true
      }
      if (this.loading === false) {
        return true
      }
      return false
    },
    // Show feedback if selected Mapping is matching to uploaded file
    matchingMappingFeedback () {
      return this.responseMessage && !this.loading && this.leave
    },
    // Show feedback if selected Mapping is not matching to uploaded file
    noMatchingMappingFeedback () {
      return this.hasError && !this.responseMessage && !this.loading
    },
    // Show feedback if dryRun is ready and import can be started
    dryRunIsReadyFeedback () {
      return this.dryRunIsReady && !this.loading && !this.importIsReady
    }
  }
}
</script>

<style scoped>
  .account-placeholder {
    height: 300px;
    padding-top: 110px;
  }

  .account-Info {
    border: 3px solid !important;
  }

  .v-data-table td {
    padding-top: 10px;
  }

  .mapping-detail-button {
    display: block;
    margin-right: 0;
    margin-left: auto;
  }

  .fake-header {
    min-height: 20px;
    width: 300px;
  }

  .fake-row {
    min-height: 70px;
  }

  .divider {
    margin-top: 10px;
    margin-bottom: 15px;
  }
</style>
