<template>
  <v-container fluid class="relative header-space container">
    <div v-if="initiated">
      <v-alert v-if="showAlert" type="info" prominent color="blue darken-2">
        <v-row class="ma-0">
          <v-col class="pl-5 mt-auto mb-auto">
            Dieser Eintrag ist neu und wartet auf eine erstmalige  <b>redaktionelle Verifikation</b>.
            <p class="mb-0">
              Ohne initiale, redaktionelle Freigabe taucht ein Eintrag weder in Exporten noch in Salesforce auf.
              Änderungen können erst dann eingepflegt werden, wenn der Eintrag erstmalig verifiziert wird.
            </p>
          </v-col>
          <v-col lg="5" class="text-right mt-auto mb-auto">

            <v-btn
              v-if="!actionsActive"
              v-on:click="actionsActive=true"
              class="mr-2"
              color="white"
              :loading="loadingActive"
              light
              rounded
              outlined
              icon>
              <v-icon>mdi-lock-outline</v-icon>
            </v-btn>

            <v-dialog v-if="showExcludeAllAndApproveAllButtons" v-model="dialog" persistent max-width="300">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" rounded color="white" outlined class="approve-buttons mt-3 mr-2 ">
                  Alles ausschließen <v-icon class="ml-1" style="margin-right: -6px">mdi-close</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">
                  <v-icon class="ml-1 amber--text" style="margin-right: 10px">mdi-alert-outline</v-icon>
                  Achtung!
                </v-card-title>
                <v-card-text class="pb-0">
                  Alle Elemente sollen abgelehnt werden. Sie stehen dann einer weiteren Bearbeitung nicht mehr zur Verfügung.
                  <p class="bold pt-2">Sicher, dass alles redaktionell ausgeschlossen werden soll?</p>
                </v-card-text>
                <div class="pt-1 pb-5 px-6">
                  <v-card-actions class="pa-0">
                    <v-btn
                      v-on:click="excludeAll"
                      @click="dialog = false"
                      :loading="loadingActive"
                      color="red darken-1"
                      block
                      rounded
                      outlined>
                      Ja, alles ausschließen
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions class="pt-3 pb-0 px-0">
                    <v-btn
                      @click="dialog = false"
                      class="mr-0 ml-auto"
                      color="grey"
                      text>Abbrechen</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-dialog>

            <v-btn v-if="showExcludeAllAndApproveAllButtons"
                   v-on:click="approveAll"
                   class="mr-2 mt-2 blue--text text--darken-3"
                   color="white"
                   :loading="loadingActive"
                   light
                   rounded>
              Alles verifizieren<v-icon class="ml-2" style="margin-right: -2px">mdi-lock-open-outline</v-icon>
            </v-btn>

            <v-btn v-if="loadingActive" class="mr-2" color="white" light loading rounded outlined icon></v-btn>

          </v-col>
        </v-row>
      </v-alert>

      <v-row>
        <v-col lg="6">
          <div v-if="rootType === 'account'">
            <h1 class="mb-2 label-space">{{ root.name }}</h1>
            <Account :account="root" :readonly="readOnlyMode" :inspectionMode="inspectionMode" rootType="account"></Account>
            <div v-if="root.products.length > 0">
              <h2>Zugehörige Produkte</h2>
              <ul v-for="product in root.products">
                <li>
                  <span>{{ product.brand }} - {{ product.label }} - {{ product.modelYear }}</span>
                  <v-btn :to="'/product/' + product.id" target="_blank" icon>
                    <v-icon class="grey--text text--darken-1">mdi-open-in-new</v-icon>
                  </v-btn>
                </li>
              </ul>
            </div>
            <div v-if="similarRoots.length > 0">
              <h2>Ähnliche Accounts</h2>
              <ul v-for="similarAccount in similarRoots">
                <li>
                  <span>{{ similarAccount.name }} (erstellt: {{ new Date(similarAccount.createdAt).toLocaleString() }}, {{ similarAccount.awards.length }} Awards, {{ similarAccount.products.length }} Produkte, {{ similarAccount.scores.length }} Scores)</span>
                  <v-btn :to="'/account/' + similarAccount.id" target="_blank" icon>
                    <v-icon class="grey--text text--darken-1">mdi-open-in-new</v-icon>
                  </v-btn>
                </li>
              </ul>
              <p>
                <small>Hier sind <b>identisch</b> benannte Accounts aufgelistet. Auch wenn keine Treffer gefunden wurden, könnte es trotzdem Dubletten geben, die aber minimal anders benannt sind.</small>
              </p>
            </div>
          </div>
          <div v-if="rootType === 'contact'">
            <h1 class="mb-2 label-space">{{ root.firstName }} {{ root.surname }}</h1>
            <Contact :contact="root" :readonly="readOnlyMode" :inspectionMode="inspectionMode" rootType="contact"></Contact>
          </div>
          <div v-if="rootType === 'product'">
            <h1 class="mb-2 label-space"><span v-if="root.account">{{ root.account.name }} - </span>{{ root.label }}</h1>
            <Product :product="root" :readonly="readOnlyMode" :inspectionMode="inspectionMode" rootType="product"></Product>
          </div>
          <div v-if="rootType === 'account' || rootType === 'contact'">
            <h3 class="mt-6 mb-1">Logbuch für "{{ rootType === 'account' ? root.name : root.firstName + ' ' + root.surname }}"</h3>
            <Notes :id="root.id" :rootClass="root.class" :notes="root.messages" :read-only="inspectionMode"></Notes>
          </div>
        </v-col>

        <v-col lg="6" class="pl-6 mt-3 pt-1">
          <div v-if="rootType === 'account'">
            <h2 class="label-space">Ansprechpartner</h2>
            <template v-if="root.mainContact">
              <Contact :main="true" :contact="root.mainContact" :readonly="readOnlyMode" :inspectionMode="inspectionMode" rootType="account"></Contact>
            </template>
            <Contact v-for="contact in otherContacts" :key="contact.id" :contact="contact" :readonly="readOnlyMode" :inspectionMode="inspectionMode" rootType="account"></Contact>
            <div v-if="root.contacts.length === 0" class="relative">
              <p class="overline light-green--text label opacity-70 mb-0 text-right absolute">Contact</p>
              <v-card class="pa-4 mb-2 grey--text text--darken-2">
                Es liegen keine Ansprechpartner vor.
              </v-card>
            </div>
          </div>
          <div v-if="rootType === 'contact'">
            <h2 class="label-space">Unternehmen</h2>
            <template v-if="root.mainAccount">
              <Account :main="true" :account="root.mainAccount" :readonly="readOnlyMode" :inspectionMode="inspectionMode" rootType="contact"></Account>
            </template>
            <Account v-for="account in otherAccounts" :key="account.id" :account="account" :readonly="readOnlyMode" :inspectionMode="inspectionMode" rootType="contact"></Account>
            <div v-if="root.accounts.length === 0" class="relative">
              <p class="overline amber--text label opacity-70 mb-0 text-right absolute">Account</p>
              <v-card class="pa-4 mb-2 grey--text text--darken-2">
                Es liegen keine Unternehmen vor.
              </v-card>
            </div>
          </div>
          <div v-if="rootType === 'product'">
            <h2 class="label-space">Hersteller</h2>
            <template v-if="root.account">
              <Account :account="root.account" :readonly="readOnlyMode" :inspectionMode="inspectionMode" rootType="account"></Account>
            </template>
            <div v-if="root.account === null" class="relative">
              <p class="overline amber--text label opacity-70 mb-0 text-right absolute">Hersteller</p>
              <v-card class="pa-4 mb-2 grey--text text--darken-2">
                Das Produkt ist keinem Hersteller zugeordnet.
              </v-card>
            </div>
          </div>

          <div v-if="'awards' in root">
            <h2 class="mt-6 label-space">Auszeichnungen</h2>
            <div class="relative">
              <p class="overline deep-purple--text label opacity-70 text-right mb-0 absolute">Award</p>
              <v-card v-if="typeof root.awards !== 'undefined' && root.awards.length === 0" class="pa-4 mb-2 grey--text text--darken-2 pr-12">
                Es liegen keine Auszeichnungen vor.
              </v-card>
            </div>
            <Award v-for="award in awardsSortedByPeriod" :award="award" v-bind:key="award.id" :readonly="readOnlyMode" :inspectionMode="inspectionMode"></Award>
          </div>

          <div v-if="'scores' in root">
            <h2 class="mt-6 label-space">Erhebungen</h2>
            <div class="relative">
              <p class="overline teal--text label opacity-70 mb-0 text-right absolute">Score</p>
              <v-card v-if="root.scores.length === 0" class="pa-4 mb-2 grey--text text--darken-2 pr-12">
                Es liegen keine Erhebungsdaten vor.
              </v-card>
            </div>
            <Score v-for="score in scoresSortedByPeriod" :score="score" v-bind:key="score.id" :readonly="readOnlyMode" :inspectionMode="inspectionMode"></Score>
          </div>

          <!-- skip to next item, which is not approved in filtered verification-list -->
          <p v-if="$state.hasNextVerifictaionItem() && !inspectionMode" class="mt-10 body-2 grey--text text--darken-2">
            Bei Klick gelangt man zum nächsten Element mit ausstehender Freigabe in der Verifikations-Liste.
            <v-btn class="mt-6" v-on:click="showNextVerificationItem" color="blue darken-3" block outlined>
              Zum nächsten zu verifizierenden Element
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </p>
          <!-- skip to previous item in filtered verification list -->
          <v-btn v-if="$state.hasPreviousVerifictaionItem()" class="mt-6" v-on:click="showPreviousVerificationItem" color="grey darken-3" block outlined>
            <v-icon>mdi-chevron-left</v-icon>
            Zum vorherigen Element
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import Account from './../components/Account'
import Contact from './../components/Contact'
import Product from './../components/Product'
import Award from './../components/Award'
import Score from './../components/Score'
import Notes from './../components/Notes'
import { sortBy } from 'lodash'

export default {
  components: {
    Product,
    Account,
    Score,
    Contact,
    Award,
    Notes
  },
  props: {
    propId: {
      default: null
    },
    propRoot: {
      default: null
    },
    inspectionMode: {
      default: false
    },
    propRootType: {
      default: null
    }
  },
  data: () => ({
    initiated: false,
    actionsActive: false,
    loadingActive: false,
    dialog: false,
    root: {},
    rootType: null,
    similarRoots: []
  }),
  async created () {
    // Firewall
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }
    let id = null
    if (this.propId !== null) {
      // Hydrate registry via prop
      id = this.propId
      this.rootType = this.propRootType
    } else {
      id = this.$route.params.id
      this.rootType = this.$route.params.rootType
      // Refresh registries.
      this.$state.resetRegistry()
      this.$changeService.reset()
    }
    // Only allow contacts and accounts as root.
    if (this.rootType !== 'account' && this.rootType !== 'contact' && this.rootType !== 'product') {
      return
    }
    // Load root data.
    await this.loadRoot(this.rootType, id)
    await this.loadSimilar(this.rootType, id)
    this.initiated = true
  },
  methods: {
    loadRoot: async function (rootType, id) {
      // Load account and contact data from registry.
      const root = this.$state.getFromRegistry(id)
      if (root !== null) {
        this.root = this.$state.setRoot(root)
        return Promise.resolve(root)
      }
      // Load root data from backend.
      return this.$http.get(rootType + '/' + id)
        .then((response) => {
          this.root = this.$state.setRoot(response.data)
          console.log(this.root)
        })
    },
    loadSimilar: async function (rootType, id) {
      // During inspection mode (import preview) we don't need to make this request.
      // Not yet existing Accounts don't have an ID, so the request would not make sense anyway.
      if (this.inspectionMode === true) {
        return
      }

      // Currently only the Account endpoint exists, so we ignore everything else.
      if (rootType !== 'account') {
        return
      }

      return this.$http.get(rootType + '/similar/' + id)
        .then((response) => {
          this.similarRoots = response.data
        })
        .catch((e) => {
        })
    },
    approveAll: async function () {
      this.loadingActive = true
      const promises = []
      // Approve the account
      const rootPromise = this.$http.put(this.rootType + '/' + this.root.id + '/approve').then(() => {
        this.$changeService.getPendingChangesByTarget(this.root.id, this.rootType, true)
      })
      promises.push(rootPromise)
      // Go through all related items and approve all
      this.relations.forEach((relation) => {
        const relatedItems = this.root[relation + 's']
        relatedItems.forEach((relatedItem) => {
          if (relatedItem.isApproved === true) {
            return
          }
          const url = relation + '/' + relatedItem.id + '/approve'
          const promise = this.$http.put(url).then(() => {
            this.$changeService.getPendingChangesByTarget(relatedItem.id, relation, true)
            relatedItem.isApproved = true
          })
          promises.push(promise)
        })
      })
      // Go through all main relations and approve all
      this.mainRelations.forEach((relation) => {
        const relatedItem = this.root['main' + relation.charAt(0).toUpperCase() + relation.slice(1)]
        if (relatedItem === null || relatedItem.isApproved === true) {
          return
        }
        const url = relation + '/' + relatedItem.id + '/approve'
        const promise = this.$http.put(url).then(() => {
          this.$changeService.getPendingChangesByTarget(relatedItem.id, relation, true)
          relatedItem.isApproved = true
        })
        promises.push(promise)
      })
      await Promise.all(promises)
      this.root.isApproved = true
      this.loadingActive = false
    },
    excludeAll: async function () {
      this.loadingActive = true
      const rootChange = {
        targetClass: this.rootType,
        targetId: this.root.id,
        attributeName: 'excludedByEditor',
        newValue: true
      }
      // Exclude Root
      await this.$http.post('/change', rootChange)
      this.root.excludedByEditor = true
      const promises = []
      // Go through all related items and exclude them
      this.relations.forEach((relation) => {
        const relatedItems = this.root[relation + 's']
        relatedItems.forEach((relatedItem) => {
          if (relatedItem.excludedByEditor === true) {
            return
          }
          const change = {
            targetClass: relation,
            targetId: relatedItem.id,
            attributeName: 'excludedByEditor',
            newValue: true
          }
          const promise = this.$http.post('/change', change).then(() => {
            relatedItem.excludedByEditor = true
          })
          promises.push(promise)
        })
      })
      await Promise.all(promises)
      this.loadingActive = false
    },
    showNextVerificationItem: function () {
      const nextItem = this.$state.getNextVerifictaionItem()
      let nextRootType = 'account'
      if (nextItem.rootType.endsWith('Contact')) {
        nextRootType = 'contact'
      } else if (nextItem.rootType.endsWith('Product')) {
        nextRootType = 'product'
      }

      const url = '/' + nextRootType + '/' + nextItem.id
      this.$router.push(url)
    },
    showPreviousVerificationItem: function () {
      const previousItem = this.$state.getPreviousVerifictaionItem()
      let previousRootType = 'account'
      if (previousItem.rootType.endsWith('Contact')) {
        previousRootType = 'contact'
      } else if (previousItem.rootType.endsWith('Product')) {
        previousRootType = 'product'
      }
      const url = '/' + previousRootType + '/' + previousItem.id
      this.$router.push(url)
    }
  },
  computed: {
    relations: function () {
      let relations = []
      if (this.rootType === 'account') {
        relations = ['contact', 'score', 'award']
      }
      if (this.rootType === 'contact') {
        relations = ['account', 'score', 'award']
      }

      return relations
    },
    mainRelations: function () {
      let relations = ['contact', 'account']
      if (this.rootType === 'product') {
        relations = []
      }

      return relations
    },
    otherAccounts: function () {
      // Return all accounts that are not the main one.
      if (this.rootType !== 'contact') {
        return []
      }
      if (this.root.mainAccount === null) {
        return this.root.accounts
      }
      const mainId = this.root.mainAccount.id
      return this.root.accounts.filter((item) => {
        return item.id !== mainId
      })
    },
    otherContacts: function () {
      // Return all contacts that are not the main one.
      if (this.rootType !== 'account') {
        return []
      }
      if (this.root.mainContact === null) {
        return this.root.contacts
      }
      const mainId = this.root.mainContact.id
      return this.root.contacts.filter((item) => {
        return item.id !== mainId
      })
    },
    readOnlyMode: function () {
      return !this.initiated || this.inspectionMode
    },
    showExcludeAllAndApproveAllButtons: function () {
      return this.actionsActive && !this.loadingActive
    },
    showAlert: function () {
      if (this.root.isApproved === true) {
        return false
      }
      if (this.root.excludedByEditor === true) {
        return false
      }
      if (this.inspectionMode === true) {
        return false
      }
      return true
    },
    awardsSortedByPeriod: function () {
      if (typeof this.root.awards === 'undefined') {
        return []
      }

      return sortBy(this.root.awards, (award) => {
        return award.period.name
      }).reverse()
    },
    scoresSortedByPeriod: function () {
      if (typeof this.root.scores === 'undefined') {
        return []
      }

      return sortBy(this.root.scores, (item) => {
        return item.period.name
      }).reverse()
    }
  }
}
</script>

<style scoped>
  h2.label-space {
    font-size: 22px
  }
</style>
