export default function Change (data) {
  const change = {}
  Object.assign(change, data)

  let rootTargetShortClass = null
  let rootTarget = null
  let isOrphan = false

  change.getTargetShortClass = () => {
    return rootTargetShortClass
  }

  change.getRootTarget = () => {
    return rootTarget
  }

  change.isOrphan = () => {
    return isOrphan
  }

  change.getTargetLink = () => {
    const shortClass = change.getTargetShortClass().toLowerCase()
    if (shortClass === null) {
      return null
    }

    return shortClass + '/' + rootTarget.id
  }

  // Find the root target of this change.
  if (change.targetClass.endsWith('Account')) {
    rootTargetShortClass = 'Account'
    rootTarget = change.target
  } else if (change.targetClass.endsWith('Contact')) {
    rootTargetShortClass = 'Contact'
    rootTarget = change.target
  } else if (change.targetClass.endsWith('Product')) {
    rootTargetShortClass = 'Product'
    rootTarget = change.target
  } else if (change.targetClass.endsWith('Award') || change.targetClass.endsWith('Score')) {
    if (change.target.account !== null) {
      rootTargetShortClass = 'Account'
      rootTarget = change.target.account
    } else if (change.target.contact !== null) {
      rootTargetShortClass = 'Contact'
      rootTarget = change.target.contact
    } else {
      isOrphan = true
    }
  }

  return change
}
