<template>
  <v-container fluid class="relative header-space container">
    <h1 class="mb-4">Konfliktbeseitigung</h1>
    <v-row>
      <v-col class="col-12">
        <p>
          Unter Konfliken verstehen wir Probleme in den Daten wie Dubletten oder fehlende Eindeutigkeiten in Lieferungen. <br>
          Hier werden Konflikte angezeigt, die das System durch Automatismen erkannt hat aber nicht selbtständig lösen kann.
        </p>
      </v-col>
      <v-col class="col-12">
        <v-card class="px-6 py-5">
          <v-card-title>Filter</v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="4" sm="12">
                <v-select
                  v-model="$state.filters.conflictType"
                  item-text="name"
                  item-value="shortCode"
                  :items="conflictTypes"
                  label="Konflikttypen"></v-select>
              </v-col>
              <v-col md="2" sm="12">
                <v-select
                  v-model="$state.filters.minSimilarity"
                  item-text="name"
                  item-value="shortCode"
                  :items="similarities"
                  label="Minimale Ähnlichkeit"></v-select>
              </v-col>
              <v-col class="col-12">
                <v-btn class="ml-3 mb-3 primary" v-on:click="loadCurrentTab" >Filtern</v-btn>
                <v-btn class="ml-3 mb-3 warning" v-on:click="resetFilter" >Zurücksetzen</v-btn>
              </v-col>
              <v-col class="col-12 text-right" v-if="listType === 'unresolved'">
                <p>Aktionen zur Stapelverarbeitung:</p>
                <v-btn class="ml-3 mb-3 error" v-if="$auth.isAdmin" v-on:click="batchIgnore()">Batch ignore</v-btn>
                <v-btn class="ml-3 mb-3 error" v-if="$auth.isAdmin" v-on:click="triggerAutoSolve()">Trigger Autosolve</v-btn>
                <p>
                  <small><b>Batch ignore:</b> Wendet auf alle offenen Konflikte die Lösung "ignore" an.</small><br>
                  <small><b>Trigger Autosolve:</b> Wendet auf alle offnen Konflikte das Autosolving an.</small>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-tabs
          background-color="primary"
          center-active
          dark
          grow
        >
          <v-tab v-on:click="loadUnresolvedConflicts">
            <v-icon class="mr-3">mdi-format-list-checkbox</v-icon>
            ungelöst
          </v-tab>

          <v-tab v-on:click="loadResolvedConflicts">
            <v-icon class="mr-3">mdi-check-all</v-icon>
            gelöst (letzte 500)
          </v-tab>
        </v-tabs>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left column-width">Konflikt Typ</th>
              <th class="text-left column-width">Konflikt ID</th>
              <th class="text-left column-width">Quelle</th>
              <th class="text-left column-width">Lösung</th>
              <th class="text-left column-width">Erstellt am</th>
              <th class="text-left column-width">Hash</th>
              <th class="text-left column-width">Ähnlichkeit</th>
              <th class="text-left column-width">Sonstiges</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="conflicts.length === 0">
              <td colspan="100%" class="text-center">
                <span>Keine Konflikte vorhanden.</span>
              </td>
            </tr>
            <tr v-for="conflict in conflicts">
              <td class="small">
                {{ conflict.type }}
              </td>
              <td class="small">
                <router-link v-if="conflict.approachedWith === 'MULTI_MINQ_IDS_SOLUTION_STRATEGY'" :to="'/minq-konflikt/' + conflict.id">
                  {{ conflict.id }}
                </router-link>
                <router-link v-else-if="conflict.approachedWith === 'ENTITY_AMBIGUITY_SOLUTION_STRATEGY'" :to="'/ea-konflikt/' + conflict.id">
                  {{ conflict.id }}
                </router-link>
              </td>
              <td v-if="conflict.approachedWith === 'MULTI_MINQ_IDS_SOLUTION_STRATEGY'" class="small">{{ conflict.conflictMembers[0].sourceType }}:<br>{{ conflict.conflictMembers[0].sourceId }} - Zeile {{ conflict.conflictMembers[0].sampleRowNumber }}</td>
              <td v-else-if="conflict.approachedWith === 'ENTITY_AMBIGUITY_SOLUTION_STRATEGY'" class="small">{{ conflict.conflictMembers[0].sourceType }}:<br>{{ conflict.conflictMembers[0].sourceId }}<br>{{ conflict.conflictMembers[1].sourceId }}</td>
              <td class="small">{{ conflict.choice }}</td>
              <td class="small">{{ new Date(conflict.createdAt).toLocaleString() }}</td>
              <td class="small">{{ conflict.hashIdentifier }}</td>
              <td class="small">{{ conflict.chanceIsConflict}}</td>
              <td>
                <v-tooltip right max-width="500" color="grey lighten-2">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon color="blue darken-3">
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <span class="mono grey--text text--darken-2"><b>Typ: </b>{{ conflict.type }}</span><br>
                  <span class="mono grey--text text--darken-2"><b>Detected mit: </b>{{ conflict.detectedWith }}</span><br>
                  <span class="mono grey--text text--darken-2"><b>Approached mit: </b>{{ conflict.approachedWith }}</span><br>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    conflicts: [],
    listType: '',
    conflictTypes: [],
    similarities: []
  }),
  methods: {
    loadCurrentTab: async function () {
      // Loads either unresolved or resolved conflicts after filter changes "reload" conflicts.
      if (this.listType === 'unresolved') {
        await this.loadUnresolvedConflicts()
      } else {
        await this.loadResolvedConflicts()
      }
    },
    resetFilter: async function () {
      // Resets filter options and reloads either unresolved or resolved conflicts.
      this.$state.filters.conflictType = null
      this.$state.filters.minSimilarity = null

      if (this.listType === 'unresolved') {
        await this.loadUnresolvedConflicts()
      } else {
        await this.loadResolvedConflicts()
      }
    },
    loadUnresolvedConflicts: async function () {
      const response = await this.$http.get('/conflict/list/unresolved')
      this.conflicts = response.data
      this.conflicts.forEach((conflict) => {
        const type = conflict.type
        if (!this.conflictTypes.includes(type)) {
          this.conflictTypes.push(type)
        }
      })
      // Apply filter "conflict-type".
      if (this.$state.filters.conflictType !== null) {
        this.conflicts = this.conflicts.filter((conflict) => conflict.type === this.$state.filters.conflictType)
      }
      // Apply filter "minimal similarity".
      const minSim = this.$state.filters.minSimilarity
      if (minSim !== null) {
        const similarityValue = parseInt(minSim.substr(0, minSim.indexOf('%')))
        this.conflicts = this.conflicts.filter((conflict) => conflict.chanceIsConflict >= similarityValue)
      }

      this.listType = 'unresolved'
      console.log(this.conflicts)
      console.log(this.conflictTypes)
    },
    loadResolvedConflicts: async function () {
      const response = await this.$http.get('/conflict/list/resolved')
      this.conflicts = response.data
      this.conflicts.forEach((conflict) => {
        const type = conflict.type
        if (!this.conflictTypes.includes(type)) {
          this.conflictTypes.push(type)
        }
      })
      // Apply filter "conflict-type".
      if (this.$state.filters.conflictType !== null) {
        this.conflicts = this.conflicts.filter((conflict) => conflict.type === this.$state.filters.conflictType)
      }
      // Apply filter "minimal similarity".
      const minSim = this.$state.filters.minSimilarity
      if (minSim !== null) {
        const similarityValue = parseInt(minSim.substr(0, minSim.indexOf('%')))
        this.conflicts = this.conflicts.filter((conflict) => conflict.chanceIsConflict >= similarityValue)
      }

      this.listType = 'resolved'
      console.log(this.conflicts)
      console.log(this.conflictTypes)
    },
    batchIgnore: function () {
      const batchedConflicts = this.conflicts.slice(0, 10)
      console.log(batchedConflicts)
      batchedConflicts.forEach((item) => {
        this.$http.post('/conflict/decide/' + item.id, { command: 'ignore' })
        console.log('ignoring: ' + item.id)
      })
    },
    triggerAutoSolve: async function () {
      console.log('Solving conflicts based on similar solved conflicts.')
      const response = await this.$http.get('/conflict/triggerAutoSolve')
      console.log('Solved Conflicts: ' + response.data)
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      this.$router.push('/')
    }

    // Filters set to null at the start.
    this.$state.filters.conflictType = null
    this.$state.filters.minSimilarity = null

    // Provide options for min. similarity filter.
    let i = 100
    while (i > 0) {
      this.similarities.push(i + '%')
      i = i - 10
    }

    // Load Conflicts
    await this.loadUnresolvedConflicts()
  }
}
</script>
