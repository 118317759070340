<template>
  <v-container fluid class="relative header-space container">
    <v-row>
      <v-col sm="12">
        <h1>API Import</h1>
      </v-col>
      <v-col class="text-right">
        <v-btn v-if="this.fetchRunning === true" class="ml-3 mb-3 primary" v-on:click="checkFetchStatus"><v-icon class="mr-1">mdi-refresh</v-icon>Refresh</v-btn><br>
        <p v-if="this.fetchRunning === true">
          <v-progress-circular size="15" :width="2" indeterminate></v-progress-circular>
          Ein API Fetch läuft gerade.
        </p>
      </v-col>
    </v-row>
    <div>
      <v-row>
        <v-col md="8" sm="12" class="pr-12">
          <div v-show="initiated">
            <v-card>
              <v-stepper class="uploadStepper" v-model="currentStep" vertical>
                <v-stepper-step step="1" :complete="api != null">API auswählen</v-stepper-step>
                <v-stepper-content step="1">
                  <v-select
                    v-model="api"
                    v-on:change="apiSelected"
                    color="primary"
                    label="API"
                    :items="apis"
                    item-text="text"
                    item-value="id"
                    item-color="primary"></v-select>
                </v-stepper-content>
                <v-stepper-step step="2" :complete="rankingGroup != null">Projekt auswählen</v-stepper-step>
                <v-stepper-content step="2">
                  <v-select
                    v-model="rankingGroup"
                    v-on:change="rankingGroupSelected"
                    color="primary"
                    label="Projekt"
                    :items="rankingGroups"
                    item-text="name"
                    item-value="id"
                    item-color="primary">
                  </v-select>
                </v-stepper-content>
                <v-stepper-step step="3" :complete="toplist !== false">Topliste auswählen</v-stepper-step>
                <v-stepper-content step="3">
                  <v-select
                    v-model="toplist"
                    v-on:change="toplistSelected"
                    color="primary"
                    label="Topliste"
                    :items="toplists"
                    item-text="value"
                    item-value="id"
                    item-color="primary">
                  </v-select>
                </v-stepper-content>
                <v-stepper-step step="4" :complete="period != null">Jahrgang auswählen</v-stepper-step>
                <v-stepper-content step="4">
                  <v-select
                    v-if="!showPeriodAdder"
                    v-model="period"
                    v-on:change="periodSelected"
                    label="Jahrgang"
                    :items="periods"
                    item-text="name"
                    item-value="id">
                    <v-btn v-if="$auth.isAdmin()" slot="append-outer" v-on:click="showPeriodAdder=true" icon><v-icon>mdi-plus</v-icon></v-btn>
                  </v-select>
                  <v-text-field label="Periodenname" v-if="showPeriodAdder" v-model="newPeriodName" :disabled="lockPeriodAdder">
                    <v-btn slot="append-outer" small v-on:click="makePeriod" :loading="lockPeriodAdder" :disabled="lockPeriodAdder">Erstellen</v-btn>
                  </v-text-field>
                </v-stepper-content>
                <v-stepper-step step="5">Import starten</v-stepper-step>
                <v-stepper-content step="5">
                  <p class="explanation body-2 pb-2">Im nächsten Schritt können die importierten Daten auf Plausibilität geprüft werden.<br>
                    Hier wird sichtbar, ob eine Weiterverabeitung des Datensatzes möglich ist.
                  </p>
                  <v-btn
                    v-on:click="startImport"
                    class="mt-2"
                    color="primary"
                    :loading="importing"
                    outlined>
                    Import starten
                  </v-btn>
                </v-stepper-content>
              </v-stepper>
            </v-card>
          </div>
        </v-col>
        <v-col md="4" sm="12" >
          <h3>API Import Bereich</h3>
          <p>Hier können Daten über eine angebundene Schnittstelle (API) importiert werden.</p>
          <h3>Anleitung</h3>
          <p>
            Bevor die gewünschte API abgefragt werden kann, müssen mithilfe der Felder bestimmte Informationen bezüglich des Kontextes der Daten ausgewählt werden:
          </p>
          <ul>
            <li>Zugehöriges Projekt</li>
            <li>Bezugsjahr der Daten <br>(Beispiel: <i>Top Tierärzte 2022</i> ist dem Jahr 2022 zugeordnet)</li>
          </ul>
          <br>
          <p>Im Anschluss kann der Import gestartet werden.</p>
        </v-col>
      </v-row>
      <v-dialog v-model="errorDialog" max-width="400">
        <v-card class="pa-4">
          <v-card-text>
            <h4 class="amber--text my-4">Achtung</h4>
            <p>
              Es gab ein Problem bei der Verarbeitung der API Abfrage. Bitte kontaktieren Sie die Data Warehouse IT-Abteilung.<br>
              Machen Sie am besten auch einen Screenshot dieser Fehlermeldung.
            </p>
            <p class="mb-0 small grey--text text--darken-2">Original-Nachricht: {{ this.errorMessage }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              href="javascript:history.go(0)"
              color="blue darken-3"
              class="px-2"
              block
              outlined>
              Verstanden
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    api: null,
    apis: [
      { id: 'dmsv1', text: 'DMSv1' },
      { id: 'dmsv2', text: 'DMSv2' }
    ],
    currentStep: 1,
    errorDialog: false,
    errorMessage: '',
    fetchRunning: false,
    importing: false,
    initiated: false,
    lockPeriodAdder: false,
    newPeriodName: '',
    period: null,
    periods: [],
    toplists: [{ id: null, value: 'Alle' }],
    toplist: false,
    rankingGroup: null,
    rankingGroups: [{ id: null, name: '' }],
    sampleId: null,
    showPeriodAdder: false
  }),
  created: async function () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    await this.checkFetchStatus()

    const calls = []
    // promise of loading ranking groups from backend
    const rankingGroupPromise = this.$http.get('/ranking_group/list')
      .then((response) => {
        this.rankingGroups = response.data
      })
    calls.push(rankingGroupPromise)
    // promise of loading periods from backend
    const periodPromise = this.$http.get('/period/list')
      .then((response) => {
        this.periods = response.data
      })
    calls.push(periodPromise)
    // Collects all promises
    await Promise.all(calls)
    this.initiated = true
  },
  methods: {
    loadToplistLabels: function () {
      this.loading = true
      const urlParams = new URLSearchParams()
      const selectedRG = this.rankingGroups.filter(obj => {
        return obj.id === this.rankingGroup
      })
      urlParams.append('rgShortCode', selectedRG[0].shortCode)
      // promise of loading toplists from backend
      return this.$http.get('/toplist_label/list?' + urlParams.toString())
        .then((response) => {
          this.toplists = this.toplists.concat(response.data.list)
          this.loading = false
        })
    },
    async checkFetchStatus () {
      const response = await this.$http.get('/api-import/fetch_status')
      this.fetchRunning = response.data
    },
    apiSelected () {
      this.currentStep = 2
    },
    rankingGroupSelected () {
      this.currentStep = 3
      this.loadToplistLabels()
    },
    toplistSelected () {
      this.currentStep = 4
    },
    periodSelected () {
      this.currentStep = 5
    },
    startImport () {
      this.importing = true

      const payload = {
        strategyAlias: this.api,
        rankingGroup: this.rankingGroup,
        period: this.period,
        toplistLabelId: this.toplist
      }
      this.$http.post('/api-import/fetch-sample', payload)
        .then((response) => {
          this.importing = false
          this.sampleId = response.data.sample
          this.importFinished()
        })
        .catch((e) => {
          this.errorMessage = e.response.data
          this.errorDialog = true
        })
    },
    async makePeriod () {
      this.lockPeriodAdder = true
      await this.$http.post('/period', { name: this.newPeriodName })
      const response = await this.$http.get('/period/list')
      this.periods = response.data
      this.showPeriodAdder = false
      this.lockPeriodAdder = false
    },
    async importFinished () {
      // await this.$router.push({ path: '/analyse/' + this.sampleId })
      await this.$router.push({ path: '/verwaltung/samples' })
    }
  }
}
</script>

<style lang="scss">
.theme--light.v-stepper {
  background: transparent;
}
.v-stepper {
  box-shadow: none;
}
.checkUpload {
  border: 5px solid #2196F3;
  color: #2196F3;
  padding: 50px;
  margin-top: 40px;
}
.v-application span .primary {
  background-color: #2196F3 !important;
  border-color: #2196F3 !important;
}
</style>
