<template>
  <div class="relative mb-10">
    <p class="overline light-green--text label mb-0 text-right absolute">{{ main ? 'Main ' : '' }}Contact</p>
    <v-card class="pa-12 mb-2">
      <div class="absolute context-menu right mr-2 ml-auto" style="top: 8px;" v-if="!inspectionMode">
        <Approve
          v-if="!contact.isApproved && !contact.excludedByEditor"
          v-model="contact.isApproved"
          targetClass="contact"
          :id="contact.id"></Approve>
        <DetailContext
          v-if="contact.isApproved || contact.excludedByEditor"
          targetClass="contact"
          :id="contact.id"></DetailContext>
      </div>

      <div v-if="contact.excludedByEditor">
        <v-chip class="mb-2 grey--text text--darken-2" outlined>
          <v-icon left>mdi-alert-circle-outline</v-icon>
          Ansprechpartner redaktionell ausgeschlossen
        </v-chip>
      </div>
      <div v-if="contact.markedForFollowUp">
        <v-chip class="mb-2 grey--text text--darken-2" outlined>
          <v-icon left>mdi-help-circle-outline</v-icon>
          Ansprechpartner für Follow Up (Rückfrage) markiert
        </v-chip>
      </div>

      <InputSwitch
        label="Anrede"
        v-model="contact.salutation"
        :counter="50"
        :readonly="readOnlyMode"
        required
        :entryId="contact.id"
        targetClass="contact"
        field="salutation"
        :inspectionMode="inspectionMode"
        :selectables="[{text:'Herr', value: 'SALUTATION_MALE'}, {text:'Frau', value: 'SALUTATION_FEMALE'}]"
      ></InputSwitch>

      <InputSwitch
        label="Titel"
        v-model="contact.academicTitle"
        :counter="50"
        :readonly="readOnlyMode"
        required
        :entryId="contact.id"
        targetClass="contact"
        field="academicTitle"
        :inspectionMode="inspectionMode"
      ></InputSwitch>

      <InputSwitch
        label="Funktion"
        v-model="contact.role"
        :counter="50"
        :readonly="readOnlyMode"
        required
        :entryId="contact.id"
        targetClass="contact"
        field="role"
        :inspectionMode="inspectionMode"
      ></InputSwitch>

      <InputSwitch
        label="Vorname"
        v-model="contact.firstName"
        :counter="255"
        :readonly="readOnlyMode"
        required
        :entryId="contact.id"
        targetClass="contact"
        field="firstName"
        :inspectionMode="inspectionMode"
      ></InputSwitch>

      <InputSwitch
        v-model="contact.surname"
        :counter="255"
        label="Nachname"
        :readonly="readOnlyMode"
        required
        :entryId="contact.id"
        targetClass="contact"
        field="surname"
        :inspectionMode="inspectionMode"
      ></InputSwitch>

      <InputSwitch
        v-model="contact.street"
        :counter="255"
        label="Straße (inkl. Hausnummer)"
        :readonly="readOnlyMode"
        :entryId="contact.id"
        targetClass="contact"
        field="street"
        :inspectionMode="inspectionMode"
      ></InputSwitch>

      <InputSwitch
        v-model="contact.city"
        :counter="50"
        label="Stadt"
        :entryId="contact.id"
        targetClass="contact"
        :readonly="readOnlyMode"
        field="city"
        :inspectionMode="inspectionMode"
      ></InputSwitch>

      <InputSwitch
        v-model="contact.zipCode"
        :counter="5"
        label="Postleitzahl"
        :entryId="contact.id"
        :readonly="readOnlyMode"
        targetClass="contact"
        field="zipCode"
        :inspectionMode="inspectionMode"
      ></InputSwitch>

      <InputSwitch
        v-if="rootType === 'account'"
        v-model="contact.emailAddress"
        :counter="100"
        label="E-Mail"
        :readonly="readOnlyMode"
        :entryId="contact.id"
        targetClass="contact"
        field="emailAddress"
        :inspectionMode="inspectionMode"
      ></InputSwitch>

      <InputSwitch
        v-model="contact.salesforceId"
        label="SalesforceID"
        :entryId="contact.id"
        targetClass="contact"
        :readonly="readOnlyMode"
        field="salesforceId"
        :inspectionMode="inspectionMode"
      ></InputSwitch>

      <InputSwitch
        v-model="contact.salesforceRecordTypeId"
        label="Salesforce RecordType"
        :entryId="contact.id"
        targetClass="contact"
        :readonly="readOnlyMode"
        field="salesforceRecordTypeId"
        :inspectionMode="inspectionMode"
        :selectables="[
          { text: '(unset)', value: null },
          { text: 'RECORD_TYPE_CONTACT_GESUNDHEIT', value: '012080000015CgXAAU' },
          { text: 'RECORD_TYPE_CONTACT_MERCHANDISING', value: '01224000000kN6yAAE' },
          { text: 'RECORD_TYPE_CONTACT_ARZTSUCHE', value: '01224000000kN6xAAE' },
          { text: 'RECORD_TYPE_CONTACT_ITALY', value: '01208000000oZtdAAE' }
        ]"
      ></InputSwitch>

      <InputSwitch
        v-model="contact.salesforceDestinationMarket"
        label="Salesforce Destination Market"
        :entryId="contact.id"
        targetClass="contact"
        :readonly="readOnlyMode"
        field="salesforceDestinationMarket"
        :inspectionMode="inspectionMode"
        :selectables="[
          { text: '(unset)', value: null },
          { text: '🇩🇪 Deutschland', value: 'DE' },
          { text: '🇮🇹 Italien', value: 'IT' },
          { text: '🇫🇷 Frankreich', value: 'FR' },
        ]"
      ></InputSwitch>

      <InputSwitch
        v-model="contact.excludedFromSalesforce"
        label="state_SF_inaktiv"
        :entryId="contact.id"
        targetClass="contact"
        :readonly="readOnlyMode"
        field="excludedFromSalesforce"
        :inspectionMode="inspectionMode"
        :selectables="[{text:'true', value: true}, {text:'false', value: false}]"
      ></InputSwitch>

      <InputSwitch
        v-if="inspectionMode"
        v-model="contact.excludedByEditor"
        label="excludedByEditor"
        :entryId="contact.id"
        targetClass="contact"
        :readonly="readOnlyMode"
        field="excludedByEditor"
        :inspectionMode="inspectionMode"
        :selectables="[{text:'true', value: true}, {text:'false', value: false}]"
      ></InputSwitch>

      <InputSwitch
        v-model="contact.mailOptIn"
        label="E-Mail-Opt-In (Gelbes Opt-In)"
        :entryId="contact.id"
        targetClass="contact"
        :readonly="readOnlyMode"
        field="mailOptIn"
        :inspectionMode="inspectionMode"
        :selectables="[{text:'true', value: true}, {text:'false', value: false}]"
      ></InputSwitch>

      <InputSwitch
        v-model="contact.phoneOptIn"
        label="Telefon-Opt-In (Grünes Opt-In)"
        :entryId="contact.id"
        targetClass="contact"
        :readonly="readOnlyMode"
        field="phoneOptIn"
        :inspectionMode="inspectionMode"
        :selectables="[{text:'true', value: true}, {text:'false', value: false}]"
      ></InputSwitch>

      <div class="text-center" v-if="!showMoreInfoForChanges">
        <v-btn
          v-if="!showMoreInfoToggle"
          v-on:click="showMoreInfoToggle = true"
          class="my-5"
          text>
          Mehr Informationen <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          v-if="showMoreInfoToggle"
          v-on:click="showMoreInfoToggle = false"
          class="my-5"
          text>
          Weniger Informationen <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </div>

      <!-- Decides whether Information are shown on first sight(if contact is root Element) or Information are shown after click-->
      <div class="grey lighten-4 mb-4" v-show="showMoreInfo">
        <InputSwitch
          v-model="contact.region"
          :counter="50"
          label="Bundesland"
          :entryId="contact.id"
          :readonly="readOnlyMode"
          targetClass="contact"
          field="region"
          :inspectionMode="inspectionMode"
        ></InputSwitch>

        <InputSwitch
          v-model="contact.countryCode"
          :counter="2"
          label="Land (ISO 3166-2 Code)"
          required
          :entryId="contact.id"
          :readonly="readOnlyMode"
          targetClass="contact"
          field="countryCode"
          :inspectionMode="inspectionMode"
        ></InputSwitch>

        <InputSwitch
          v-model="contact.telephoneNumber"
          :counter="50"
          label="Telefon"
          :readonly="readOnlyMode"
          :entryId="contact.id"
          targetClass="contact"
          field="telephoneNumber"
          :inspectionMode="inspectionMode"
          :data-type="'TYPE_STRING'"
        ></InputSwitch>

        <InputSwitch
          v-if="rootType === 'contact'"
          v-model="contact.emailAddress"
          :counter="100"
          label="E-Mail"
          :readonly="readOnlyMode"
          :entryId="contact.id"
          targetClass="contact"
          field="emailAddress"
          :inspectionMode="inspectionMode"
        ></InputSwitch>

        <InputSwitch
          v-model="contact.faxNumber"
          :counter="50"
          label="Fax"
          :readonly="readOnlyMode"
          :entryId="contact.id"
          targetClass="contact"
          field="faxNumber"
          :inspectionMode="inspectionMode"
          :data-type="'TYPE_STRING'"
        ></InputSwitch>

        <InputSwitch
          v-model="contact.shortWebsite"
          :counter="50"
          label="Webseite (Druckversion, kurz)"
          :readonly="readOnlyMode"
          :entryId="contact.id"
          targetClass="contact"
          field="shortWebsite"
          :inspectionMode="inspectionMode"
        ></InputSwitch>

        <InputSwitch
          v-model="contact.website"
          :counter="255"
          label="Webseite (Quelle)"
          :readonly="readOnlyMode"
          :entryId="contact.id"
          targetClass="contact"
          field="website"
          :inspectionMode="inspectionMode"
        ></InputSwitch>
      </div>

      <v-btn
        v-if="!showSupplier"
        v-on:click="showSupplier = true"
        class="px-0 mx-0 mb-0 grey--text text--darken-2 mono"
        elevation="0"
        color="white">
        Lieferanten <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
      <v-btn
        v-if="showSupplier"
        v-on:click="showSupplier = false"
        class="px-0 mx-0 mb-0 grey--text text--darken-2 mono"
        elevation="0"
        color="white">
        Lieferanten <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
      <div v-show="showSupplier" class="mb-0 pt-3">
        <p v-for="item in contact.supplierIds" :key="item.value + item.scope" class="grey--text text--darken-2 small">
          <span>
            <v-icon
              small
              @click="deleteSupplierId(item)"
            >mdi-delete</v-icon>
          </span>
          <span class="mb-1">{{ item.supplier.name }} ({{ item.scope }}): </span>
          <span class="mono mb-0">{{ item.value }}</span>
        </p>
      </div>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline red--text">Achtung!</v-card-title>
          <v-card-text>
            <p>SupplierIdentification wirklich löschen? Diese Operation ist nicht umkehrbar, nur bei 100% Sicherheit ausführen!</p>
            <p>Um den Löschvorgang fortzusetzen bitte "LÖSCHEN" in das untenstehende Textfeld eingeben und bestätigen.</p>
            <v-text-field v-model="confirmString" label="Bestätigungsstring"></v-text-field>
            <p v-if="this.confirmationInvalid === true" class="red--text">Bestätigungsstring ist nicht korrekt.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteSupplierIdCancel">abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="deleteSupplierIdConfirm(confirmString)">
              <v-progress-circular v-if="deleting === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
              <span v-if="deleting === false">Löschen</span>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card>
  </div>
</template>

<script>
import Approve from './Approve'
import DetailContext from './DetailContext'
import InputSwitch from './InputSwitch'

export default {
  props: {
    contact: Object,
    readonly: {
      type: Boolean,
      default: false
    },
    inspectionMode: {
      default: false
    },
    rootType: {
      default: ''
    },
    main: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Approve,
    DetailContext,
    InputSwitch
  },
  data: () => ({
    showMoreInfoToggle: false,
    showSupplier: false,
    changes: {},
    dialogDelete: false,
    supplierIdForDeletion: null,
    deleting: false,
    confirmString: '',
    confirmationInvalid: null
  }),
  created () {
    this.$state.addToRegistry(this.contact)
    console.log(this.contact)
    this.changes = this.$changeService.getPendingChangesByTarget(this.contact.id, 'contact')
    if (this.rootType === 'contact') {
      this.showMoreInfoToggle = true
    }
  },
  methods: {
    deleteSupplierId (item) {
      this.dialogDelete = true
      this.supplierIdForDeletion = item
    },
    deleteSupplierIdConfirm: async function (confirmString) {
      if (confirmString === 'LÖSCHEN') {
        this.deleting = true
        await this.$http.delete('/supplier-identification/delete/' + this.supplierIdForDeletion.id)
          .finally(() => {
            this.deleting = false
            this.dialogDelete = false
            this.supplierIdForDeletion = null
          })
      } else {
        this.confirmationInvalid = true
      }
    },
    deleteSupplierIdCancel () {
      this.dialogDelete = false
      this.supplierIdForDeletion = null
    }
  },
  computed: {
    readOnlyMode: function () {
      if (this.readonly === true) {
        return true
      }
      if (this.contact.excludedByEditor === true) {
        return true
      }
      if (this.contact.isApproved === false) {
        return true
      }
      return false
    },
    showMoreInfoForChanges: function () {
      return Object.keys(this.changes).length !== 0
    },
    showMoreInfo: function () {
      if (this.showMoreInfoToggle) {
        return true
      }
      if (this.showMoreInfoForChanges) {
        return true
      }
      return false
    }
  }
}
</script>
