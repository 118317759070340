<template>
    <v-container class="relative header-space container-full">
      <h1 class="mb-8">Konfliktbeseitigung</h1>
      <div v-if="conflict">
        <v-alert v-if="conflict.solvedAt !== null" type="success">Dieser Konflikt ist gelöst.</v-alert>
        <v-card class="px-6 py-5">
          <v-card-title>Duplikatsverdacht</v-card-title>
          <div class="px-4 pt-4">
            <b>Datentyp:</b> {{ member[0].class.substr(member[0].class.lastIndexOf('\\')+1, member[0].class.length) }}<br>
            <b>Ähnlichkeit der Daten:</b> {{ conflict.chanceIsConflict }}<br>
          </div>
        </v-card>
        <v-row v-if="conflict" class="my-0">
          <v-col md="10">
            <v-row v-if="member[0].class === 'App\\Entity\\Account'"  class="my-0">
              <v-col v-for="account in member" v-bind:key="account.id">
                <router-link :to="'/account/' + account.id" target="_blank">
                  <v-icon size="16" class="grey--text text--darken-1">mdi-open-in-new</v-icon>
                  <v-btn class="pa-0 mr-1"  tile small text><span class="font-body">ansehen: {{ account.id }}</span></v-btn>
                </router-link>
                <Account
                  :account="account"
                  :inspectionMode="true"
                  :readonly="true"
                  :loadChanges="false"
                  rootType="contact"
                  class="mb-4">
                </Account>
              </v-col>
            </v-row>
            <v-row v-if="member[0].class === 'App\\Entity\\Contact'"  class="my-0">
              <v-col v-for="contact in member" v-bind:key="contact.id">
                <router-link :to="'/contact/' + contact.id" target="_blank">
                  <v-icon size="16" class="grey--text text--darken-1">mdi-open-in-new</v-icon>
                  <v-btn class="pa-0 mr-1"  tile small text><span class="font-body">ansehen: {{ contact.id }}</span></v-btn>
                </router-link>
                <Contact
                  :contact="contact"
                  :inspectionMode="true"
                  :readonly="true"
                  :loadChanges="false"
                  rootType="contact"
                  class="mb-4">
                </Contact>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="2" class="pl-6">
            <br>
            <p>Beim <b>Verbinden</b> wird ein neuer Account bzw. Kontakt erstellt indem beide Datensätze manuell zu einem Dritten verknüpft.</p>
            <v-btn v-on:click="merge()" block class="mb-8" color="primary">Verbinden</v-btn>
            <p>Falls die beiden Datensätze keine Duplikate voneinander sind, kann hiermit der Konflikt aufgelöst werden. In diesem Fall werden beide Datensätze in der Datenbank behalten.</p>
            <v-btn v-on:click="notAConflict()" block class="mb-8" color="primary">Kein Konflikt</v-btn>
            <p></p>
          </v-col>
        </v-row>
      </div>
    </v-container>
</template>

<script>
import Account from './../components/Account'
import Contact from './../components/Contact'

export default {
  components: {
    Account,
    Contact
  },
  data: () => ({
    id: null,
    conflict: null,
    member: []
  }),
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 4)
    }
  },
  methods: {
    loadConflict: async function () {
      const response = await this.$http.get('/conflict/' + this.id)
      this.conflict = response.data.conflict
      this.member.push(response.data.decisionParameters[0])
      this.member.push(response.data.decisionParameters[1])
      console.log(this.conflict)
      console.log(this.member)
    },
    merge: async function () {
      // TODO: Handle merge option.
      console.log('Merge')
    },
    notAConflict: async function () {
      const decision = { command: 'notAConflict' }
      const response = await this.$http.post('/conflict/decide/' + this.id, decision)
      this.closeConflict()
      console.log(response)
    },
    closeConflict: function () {
      this.conflict.solvedAt = true
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      this.$router.push('/')
    }

    this.id = this.$route.params.id
    // Load Conflicts
    await this.loadConflict()
  }
}
</script>
