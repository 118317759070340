<template>
  <div class="input-switch">
    <div v-if="!writeMode" class="row">
      <label class="col-6 text-right fake-label cursor-pointer">
        <a v-if="isWebsite" :href="getExternalLink" target="_blank">
          <v-icon size="20" class="grey--text text--darken-1 mr-2">mdi-open-in-new</v-icon>
        </a>
        {{ label }}
        <v-icon v-on:click="showHistory = !showHistory" class="history-icon" small grey hidden>mdi-clock-outline</v-icon>
      </label>
      <span
        v-bind:class="{'color-hover-blue': !readonly, 'cursor-pointer': !readonly, 'hover-icon': !readonly}"
        v-on:click="startWriteMode"
        class="col-6"
      >
        <span v-if="value !== null">{{ getRawValue }}</span>
        <span class="font-italic grey--text" v-else>leer</span>
        <v-icon
          v-if="!readonly"
          size="20"
          color="blue darken-3"
          class="position-absolute ml-1 mb-1 onhover"
        >mdi-pencil-circle-outline</v-icon>
        <v-tooltip bottom v-if="showWarning">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              class="ml-1"
              color="amber"
              size="18"
            >
              mdi-alert-outline
            </v-icon>
          </template>
          <span>Für dieses Feld existieren unverarbeitete Änderungen in der Verifikation.</span>
        </v-tooltip>
      </span>
    </div>

    <div v-if="writeMode">
      <v-select
        v-if="selectables !== null"
        v-model="workingValue"
        :items="selectables"
        :label="label"
        ref="input"
      >
        <div slot="append-outer" class="text-center" style="min-width: 80px;">
          <v-btn v-on:click="commit" :disabled="!acceptable" icon>
            <v-icon size="28">mdi-check-circle-outline</v-icon>
          </v-btn>
          <v-btn v-on:click="cancel" :disabled="loading" icon>
            <v-icon size="28">mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </v-select>
      <v-text-field
        v-if="selectables === null"
        v-model="workingValue"
        class="verify pa-5"
        ref="input"
        :counter="counterValue"
        :label="label"
        :required="required"
        :readonly="readonly"
        :loading="loading"
        :disabled="loading"
        :type="this.dataType === 'TYPE_INTEGER' || this.dataType === 'TYPE_DOUBLE' ? 'number' : 'text'"
        outlined
        dense
      >
        <div v-if="originalType !== null && originalType !== newType" slot="append" >
          <v-tooltip bottom max-width="300">
            <template v-slot:activator="{ on }">
              <v-icon color="amber" v-on="on">
                mdi-alert-outline
              </v-icon>
            </template>
            <span>
              Der Typ des Feldes wird verändert von <b>{{ originalType }}</b> zu <b>{{ newType }}</b>.
              Bitte sorgfältig prüfen.
            </span>
          </v-tooltip>
        </div>
        <div slot="append-outer" class="text-center" style="min-width: 80px;">
          <v-btn v-on:click="commit" :disabled="!acceptable" icon>
            <v-icon size="28">mdi-check-circle-outline</v-icon>
          </v-btn>
          <v-btn v-on:click="cancel" :disabled="loading" icon>
            <v-icon size="28">mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </v-text-field>
    </div>

    <ChangeList
      :value="value"
      :target-class="targetClass"
      :target-id="entryId"
      :target-field="field"
      :readonly="readonly"
      :loadChanges="loadChanges"
      :inspectionMode="inspectionMode"
    ></ChangeList>

    <ChangeHistory
      v-if="showHistory"
      :entryId="entryId"
      :field="field"
      :targetClass="targetClass">
      <v-btn icon v-on:click="showHistory = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </ChangeHistory>

  </div>

</template>

<script>

import ChangeList from './ChangeList'
import ChangeHistory from './ChangeHistory'
import { toNumber } from 'lodash'

export default {
  props: {
    label: String,
    value: {},
    readonly: {
      type: Boolean,
      default: false
    },
    entryId: String,
    targetClass: String,
    field: String,
    required: {
      type: Boolean,
      default: false
    },
    counter: {
      default: false
    },
    inspectionMode: {
      default: false
    },
    selectables: {
      default: null
    },
    loadChanges: {
      type: Boolean,
      default: true
    },
    dataType: {
      type: String,
      default: null
    }
  },
  components: {
    ChangeList,
    ChangeHistory
  },
  data: () => ({
    writeMode: false,
    hover: false,
    writeModeIconColor: 'gray',
    committedValue: null,
    workingValue: null,
    loading: false,
    showHistory: false,
    changes: null
  }),
  created () {
    if (this.loadChanges) {
      this.changes = this.$changeService.getPendingChangesByTarget(this.entryId, this.targetClass)
    }
  },
  methods: {
    startWriteMode: function () {
      if (this.readonly) {
        return
      }
      this.writeMode = true
      this.committedValue = this.value
      this.workingValue = this.value
      setTimeout(() => {
        this.$refs.input.focus()
      }, 10)
    },
    commit: async function () {
      this.loading = true
      if (typeof this.workingValue === 'string') {
        this.workingValue = this.workingValue.trim()
      }
      if (this.workingValue === '') {
        this.workingValue = null
      }
      const change = {
        targetClass: this.targetClass,
        targetId: this.entryId,
        attributeName: this.field,
        newValue: this.workingValue
      }
      // Query to backend and make a new change for this field
      await this.$http.post('/change', change)
      this.writeMode = false
      this.loading = false
      this.committedValue = this.workingValue
      this.$emit('input', this.committedValue)
    },
    cancel: function () {
      this.writeMode = false
      this.workingValue = this.value
    }
  },
  watch: {
    workingValue: function (newWorkingValue) {
      if (typeof newWorkingValue !== 'string') {
        return
      }
      if (newWorkingValue.trim() === '') {
        return
      }
      // If the value can be interpreted as a number but is currently a string
      // Then convert the type into a number (only if allowed via "dataType" prop)
      if (!isNaN(newWorkingValue)) {
        if (this.dataType && (this.dataType === 'TYPE_INTEGER' || this.dataType === 'TYPE_DOUBLE')) {
          this.workingValue = toNumber(newWorkingValue)
        }
      }
    }
  },
  computed: {
    dirty: function () {
      return this.workingValue !== this.committedValue
    },
    acceptable: function () {
      if (this.required === true) {
        if (this.workingValue === null) {
          return false
        }
        if (this.workingValue.length === 0) {
          return false
        }
      }
      if (!this.dirty) {
        return false
      }
      if (this.loading) {
        return false
      }
      return true
    },
    originalType: function () {
      if (this.value === null) {
        // "null" is of type "object", that leads to a misleading message.
        // For now just return null to indicate that we don't really know the type.
        // For this whole type checking feature we need better connection to the backend anyway, we can't just
        // "guess" the type in the frontend. Doesn't properly work with null, boolean, doubles, MDFs, etc.
        return null
      } else {
        return typeof this.value
      }
    },
    newType: function () {
      if (this.workingValue === null) {
        return typeof this.value
      }
      return typeof this.workingValue
    },
    getRawValue: function () {
      if (this.selectables !== null) {
        const selected = this.selectables.find((item) => {
          return item.value === this.value
        })

        if (typeof selected !== 'undefined' && 'text' in selected) {
          return selected.text
        }

        // Helper to identify faulty values in DB, edge case.
        return this.value + '(type mismatch)'
      }
      return this.value
    },
    // Display a warning when being in readonly mode but having changes
    showWarning: function () {
      if (this.inspectionMode === true) {
        return false
      }
      if (this.readonly === false) {
        return false
      }
      if (typeof this.changes[this.field] === 'undefined') {
        return false
      }
      if (this.changes[this.field].length === 0) {
        return false
      }
      return true
    },
    counterValue: function () {
      if (typeof this.workingValue !== 'string') {
        return false
      }
      return this.counter
    },
    isWebsite () {
      if (typeof this.value === 'string') {
        if (this.field === 'website' || this.field === 'shortWebsite' || this.field === 'salesforceId') {
          return true
        }
        if (this.value.includes('http://') || this.value.includes('https://') || this.value.includes('www.') || this.value.includes('.html')) {
          return true
        }
      }
      return false
    },
    getExternalLink: function () {
      if (typeof this.value === 'string') {
        if (this.field === 'salesforceId') {
          return ('https://burdanews.lightning.force.com/lightning/r/').concat(this.value, '/view')
        }
        if (this.value.startsWith('http')) {
          return this.value
        }
        return ('https://').concat(this.value)
      }
      return false
    }
  }
}
</script>

<style scoped lang="scss">
  .fake-label {
    display: block;
    font-weight: 900;
  }
  .fake-label {
    .history-icon {
      visibility: hidden;
    }
    &:hover .history-icon {
      visibility: visible;
    }
  }
  .hover-icon {
    i.onhover {
      visibility: hidden;
    }
    &:hover i.onhover {
      visibility: visible;
    }
  }

  .input-switch {
    font-size: 14px;

    .v-text-field .v-label {
      overflow: visible;
    }
  }
</style>
