<template>
  <div>
    <div class="pb-4 pt-4">
      <h5 class="grey--text">Form: Ganze Zahlen</h5>
      <p class="explanation body-2 pt-3 pr-3 mb-0">
        Prüfung, ob alle Einträge innerhalb einer Spalte einheitlich als ganze Zahlen vorliegen.</p>
    </div>
    <div v-if="profile === true">
      <p>
        <v-icon color="light-green">mdi-checkbox-marked-circle-outline</v-icon>
        Ok.
      </p>
    </div>
    <div v-else>
      <p class="pb-2">
        <v-icon color="red darken-1">mdi-close-circle-outline</v-icon>
        Achtung! Eine Mischung in der Form liegt vor. Dies muss in der Lieferung behoben werden.
      </p>
      <v-simple-table>
        <tr>
          <td v-for ="colName in header" class="column"> {{ colName }}</td>
        </tr>
        <tr>
          <td v-for="value in sub" class="column monospace">
            <p v-if="value === true"><v-icon size="18" class="small-icon">mdi-check</v-icon></p>
            <p v-else><v-icon size="18" class="small-icon" color="red darken-1">mdi-close</v-icon></p>
          </td>
        </tr>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'profile',
    'sub',
    'header'
  ]
}
</script>
