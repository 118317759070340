<template>
  <div>
    <div v-if="hasChanges">
      <ul class="changelist">
        <li class="py-1 pl-0 white center" v-for="change in changes[targetField]" :key="change.id">

          <span
            v-if="changesInVerificationMode"
            class="blue--text text--darken-3 mr-1 font-bigger">
            <span v-if="change.newValue !== null">{{ change.newValue }}</span>
            <span class="font-italic grey--text" v-else>leer</span>
          </span>

          <span
            v-if="importedChangesInInspectionMode"
            class="grey--text text--darken-2 mr-1 font-bigger">
            <v-icon color="grey" small class="pr-0">mdi-arrow-right-bold</v-icon>
            <span v-if="change.newValue !== null">{{ change.newValue }}</span>
            <span class="font-italic grey--text" v-else>leer</span>
          </span>

          <span
            v-if="newChangesInInspectionMode || changesInVerificationUnapprovedMode"
            class="grey--text text--darken-2 mr-1 font-bigger">
            <v-icon color="grey" small class="pr-0">mdi-arrow-right-bold</v-icon>
            <span v-if="change.newValue !== null">{{ change.newValue }}</span>
            <span class="font-italic grey--text" v-else>leer</span>
          </span>

          <span class="ml-1" v-if="change.oldValue !== value">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-if="!inspectionMode" v-on="on" size="16" color="amber">mdi-alert-circle-outline</v-icon>
              </template>
              <span>
                Die vorgeschlagene Änderung ist möglicherweise veraltet.<br>
                Zum Zeitpunkt des Imports lag dies als Wert vor: <b>{{ change.oldValue }}</b><br>
                Hier wird eine sorgfältige Prüfung empfohlen.
              </span>
            </v-tooltip>
          </span>

          <div v-if="changesInVerificationMode" class="buttons ml-1">
            <div class="d-inline-block">
              <v-icon size="16" class="light-green--text">mdi-check</v-icon>
              <v-btn v-on:click="accept(change)" class="pa-0 mr-3" :disabled="locked" tile small text>
                <span class="accept font-body">annehmen</span>
              </v-btn>
            </div>
            <div class="d-inline-block">
              <v-icon size="16" class="red--text text--darken-1">mdi-close</v-icon>
              <v-btn v-on:click="decline(change)" class="pa-0 mr-3" :disabled="locked" tile small text>
                <span class="decline font-body">ablehnen</span>
              </v-btn>
            </div>
            <div class="d-inline-block" v-if="isWebsite">
              <a :href="getExternalLink" target="_blank">
                <v-icon size="16" class="grey--text text--darken-1">mdi-open-in-new</v-icon>
                <v-btn class="pa-0 mr-1"  tile small text><span class="font-body">Website</span></v-btn>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    readonly: {
      type: Boolean,
      default: false
    },
    targetId: null,
    targetClass: null,
    targetField: null,
    inspectionMode: {
      default: false
    },
    loadChanges: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    locked: false,
    changes: {}
  }),
  async created () {
    if (this.loadChanges) {
      this.changes = this.$changeService.getPendingChangesByTarget(this.targetId, this.targetClass)
    }
  },
  methods: {
    accept: async function (change) {
      this.locked = true
      // Accept API call.
      await this.$changeService.accept(change)
      this.locked = false
    },
    decline: async function (change) {
      this.locked = true
      // Decline API call.
      await this.$changeService.reject(change)
      this.locked = false
    }
  },
  computed: {
    changesInVerificationUnapprovedMode () {
      return this.readonly && !this.inspectionMode
    },
    changesInVerificationMode () {
      return !this.readonly && !this.inspectionMode
    },
    newChangesInInspectionMode () {
      return this.readonly && this.inspectionMode
    },
    importedChangesInInspectionMode () {
      return !this.readonly && this.inspectionMode
    },
    hasChanges () {
      if (typeof this.changes[this.targetField] === 'undefined') {
        return false
      }
      return this.changes[this.targetField].length !== 0
    },
    isWebsite () {
      if (typeof this.changes[this.targetField][0].newValue === 'string') {
        if (this.targetField === 'website' || this.targetField === 'shortWebsite') {
          return true
        }
        if (this.changes[this.targetField][0].newValue.includes('http://') || this.changes[this.targetField][0].newValue.includes('https://') || this.changes[this.targetField][0].newValue.includes('www.') || this.changes[this.targetField][0].newValue.includes('.html')) {
          return true
        }
      }
      return false
    },
    getExternalLink: function () {
      if (typeof this.changes[this.targetField][0].newValue === 'string') {
        if (this.changes[this.targetField][0].newValue.startsWith('http')) {
          return this.changes[this.targetField][0].newValue
        }
        return ('https://').concat(this.changes[this.targetField][0].newValue)
      }
      return false
    }
  }
}
</script>

<style scoped lang="scss">
  .accept {
    color: #8bc34a;
  }
  .decline {
    color: #E53935;
  }
</style>
