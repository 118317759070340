<template>
  <div v-show="initiated">
    <v-card>

      <div v-show="!uploadComplete">
        <v-stepper class="uploadStepper" v-model="currentStep" vertical>
        <v-stepper-step step="1" :complete="provider != null">Lieferant auswählen</v-stepper-step>
        <v-stepper-content step="1">
          <v-select
            v-model="provider"
            v-on:change="providerSelected"
            color="primary"
            label="Daten-Lieferant"
            :items="providers"
            item-text="name"
            item-value="id"
            item-color="primary"></v-select>
        </v-stepper-content>
        <v-stepper-step step="2" :complete="rankingGroup != null">Projekt auswählen</v-stepper-step>
        <v-stepper-content step="2">
          <v-select
            v-model="rankingGroup"
            v-on:change="rankingGroupSelected"
            color="primary"
            label="Projekt"
            :items="rankingGroups"
            item-text="name"
            item-value="id"
            item-color="primary">
          </v-select>
        </v-stepper-content>
        <v-stepper-step step="3" :complete="period != null">Jahrgang auswählen</v-stepper-step>
        <v-stepper-content step="3">
          <v-select
            v-if="!showPeriodAdder"
            v-model="period"
            v-on:change="periodSelected"
            label="Jahrgang"
            :items="periods"
            item-text="name"
            item-value="id">
            <v-btn v-if="$auth.isAdmin()" slot="append-outer" v-on:click="showPeriodAdder=true" icon><v-icon>mdi-plus</v-icon></v-btn>
          </v-select>
          <v-text-field label="Periodenname" v-if="showPeriodAdder" v-model="newPeriodName" :disabled="lockPeriodAdder">
            <v-btn slot="append-outer" small v-on:click="makePeriod" :loading="lockPeriodAdder" :disabled="lockPeriodAdder">Erstellen</v-btn>
          </v-text-field>
        </v-stepper-content>
        <v-stepper-step step="4" :complete="file != null">Datei auswählen</v-stepper-step>
        <v-stepper-content step="4" class="mr-2">
          <v-file-input v-on:change="changeFile" class="monospace" truncate-length="50" color="primary" show-size/>
          <p class="body-2 text-right grey--text text--darken-2 mb-1">
            <span v-if="md5" class="mono pr-2 small text-left">MD5:{{ md5 }}</span>
            CSV, XLSX bis zu 100MB
          </p>
        </v-stepper-content>
        <v-stepper-step step="5" :complete="uploadComplete">Daten hochladen</v-stepper-step>
        <v-stepper-content step="5">
          <p class="explanation body-2 pb-2">Im nächsten Schritt kann die hochgeladene Datei auf Plausibilität geprüft werden.<br>
            Hier wird sichtbar, ob eine Weiterverabeitung des Datensatzes <span class="mono">(MD5:{{ md5 }})</span> möglich ist.
          </p>
          <v-col></v-col>
          <v-progress-linear v-show="uploading" :value="percentage" height="1" style="width: 171px"/>
          <v-btn
            v-on:click="startUpload"
            class="mt-2"
            color="primary"
            :disabled="!uploadable"
            :loading="uploading"
            outlined>
            Upload Starten
          </v-btn>
        </v-stepper-content>
        </v-stepper>
        <!--
        <v-row>
          <v-col lg="5">
            <v-select
              v-model="provider"
              v-on:change="providerSelected"
              color="primary"
              label="Daten-Lieferant"
              :items="providers"
              item-text="name"
              item-value="id"
              item-color="primary"></v-select>
          </v-col>
          <v-col lg="5">
            <v-select
              v-model="rankingGroup"
              v-on:change="rankingGroupSelected"
              color="primary"
              label="Projekt"
              :items="rankingGroups"
              item-text="name"
              item-value="shortCode"
              item-color="primary">
            </v-select>
          </v-col>
          <v-col lg="2">
            <v-select
              v-model="period"
              label="Jahrgang"
              :items="periodNames">
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col lg="8" md="8" sm="8" xs="12" class="pb-0">
            <v-file-input v-on:change="changeFile" class="monospace" truncate-length="50" color="primary" show-size/>
            <p class="body-2 text-right grey--text text--darken-2 mb-2">
              <span v-if="md5" class="mono pr-2 small text-left">MD5:{{ md5 }}</span>
              CSV, XLSV bis zu 100MB
            </p>
          </v-col>
          <v-col lg="4" md="4" sm="4" xs="12" class="pb-0">
            <v-progress-linear v-show="uploading" :value="percentage" height="1"/>
            <v-btn
              v-on:click="startUpload"
              class="mt-3"
              color="primary"
              :disabled="!uploadable"
              :loading="uploading"
              outlined
              block>
              Upload Starten
            </v-btn>
          </v-col>
        </v-row>
        -->
      </div>
      <!-- throw error if dublicate header check fails -->
      <v-dialog v-model="errorDialog" max-width="400">
        <v-card class="pa-4">
          <v-card-text>
            <h4 class="amber--text my-4">Achtung</h4>
            <p>
              Der Datensatz kann nicht verarbeitet werden.<br>
              Ein Tabellenkopf liegt mehrfach vor.<br>
              Bitte die hochgeladene Datei überprüfen.
            </p>
            <p class="mb-0 small grey--text text--darken-2">Original-Nachricht: {{ this.errorMessage }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              href="javascript:history.go(0)"
              color="blue darken-3"
              class="px-2"
              block
              outlined>
              Verstanden
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card>
  </div>
</template>

<script>
const maxUploadSize = 1024 * 1024 * 100 // 100MB
const SparkMD5 = require('spark-md5')
let spark = null

export default {
  data: () => ({
    initiated: false,
    file: null,
    uploading: false,
    provider: null,
    rankingGroup: null,
    rankingGroups: [{ id: null, name: '' }],
    periods: [],
    period: null,
    uploadComplete: false,
    currentStep: 1,
    percentage: 0,
    sampleId: null,
    hash: null,
    stamp: null,
    providers: [],
    sizeOk: false,
    typeOk: false,
    md5: null,
    errorMessage: '',
    errorDialog: false,
    showPeriodAdder: false,
    newPeriodName: '',
    lockPeriodAdder: false
  }),
  created: async function () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    const calls = []
    // promise of loading supllier from backend
    const supplierPromise = this.$http.get('/supplier')
      .then((response) => {
        this.providers = response.data
      })
    calls.push(supplierPromise)
    // promise of loading ranking groups from backend
    const rankingGroupPromise = this.$http.get('/ranking_group/list')
      .then((response) => {
        this.rankingGroups = response.data
      })
    calls.push(rankingGroupPromise)
    // promise of loading periods from backend
    const periodPromise = this.$http.get('/period/list')
      .then((response) => {
        this.periods = response.data
      })
    calls.push(periodPromise)
    // Collects all promises
    await Promise.all(calls)
    this.initiated = true
  },
  methods: {
    changeFile (fileReference) {
      this.file = fileReference
      this.hash = null
      this.sizeOk = false
      this.typeOk = false
      this.currentStep = 4
      // A file is selected, initialize
      if (this.file) {
        this.currentStep = 5
        this.calculateMD5()
        this.stamp = new Date().getFullYear() + '-' + Date.now().toString()
        // Check file restrictions
        if (this.file.size < maxUploadSize) {
          this.sizeOk = true
        }
        if (this.file.type === 'text/csv' || this.file.name.endsWith('.xlsx')) {
          this.typeOk = true
        }
      }
    },
    startUpload () {
      this.uploading = true
      this.uploadChunk(0)
    },
    providerSelected () {
      this.currentStep = 2
    },
    rankingGroupSelected () {
      this.currentStep = 3
    },
    periodSelected () {
      this.currentStep = 4
    },
    async startAnalysis () {
      await this.$router.push({ path: '/analyse/' + this.sampleId })
    },
    async uploadFinished () {
      await this.$router.push({ path: '/analyse/' + this.sampleId })
    },
    async makePeriod () {
      this.lockPeriodAdder = true
      await this.$http.post('/period', { name: this.newPeriodName })
      const response = await this.$http.get('/period/list')
      this.periods = response.data
      this.showPeriodAdder = false
      this.lockPeriodAdder = false
    },
    uploadChunk (chunkId) {
      const vm = this
      const chunkSize = 1024 * 512 // 512kb
      const chunkCount = Math.ceil(this.file.size / chunkSize)
      const reader = new FileReader()
      const chunkBlob = this.file.slice(chunkId * chunkSize, chunkId * chunkSize + chunkSize)

      reader.onload = (event) => {
        const chunk = event.target.result.replace('data:application/octet-stream;base64,', '')
        const request = {
          stamp: vm.stamp,
          supplier: this.provider,
          rankingGroup: this.rankingGroup,
          period: this.period,
          chunkId,
          chunkCount,
          fileOriginalName: vm.file.name,
          fileOriginalType: vm.file.type,
          fileHash: vm.md5,
          fileContent: chunk
        }
        // Upload chunk
        this.$http.post('/upload', request)
          .then((response) => {
            // Calculate progress from finished chunks
            vm.percentage = ((chunkId + 1) / chunkCount) * 100
            if ((chunkId + 1) < chunkCount) {
              // Upload next chunk
              vm.uploadChunk(chunkId + 1)
            } else {
              // Upload of all chunks finished
              vm.uploading = false
              vm.uploadComplete = true
              vm.sampleId = response.data.sample
              this.uploadFinished()
            }
          })
          .catch((e) => {
            if (e.response.data.class === 'App\\Exception\\FileOperation\\DuplicateHeaderException') {
              // Display error message, if duplicate headers are found.
              this.errorMessage = e.response.data.message
              this.errorDialog = true
            }
            throw e
          })
      }
      reader.readAsDataURL(chunkBlob)
    },
    calculateMD5: function (chunkId) {
      if (typeof chunkId === 'undefined') {
        chunkId = 0
        spark = new SparkMD5.ArrayBuffer()
      }
      const vm = this
      const blobSlice = File.prototype.slice
      const chunkSize = 1024 * 512 // 512kb
      const chunkCount = Math.ceil(this.file.size / chunkSize)
      const reader = new FileReader()
      const start = chunkId * chunkSize
      const end = ((start + chunkSize) >= this.file.size) ? this.file.size : start + chunkSize

      reader.onload = (event) => {
        spark.append(event.target.result)
        if ((chunkId + 1) === chunkCount) {
          vm.md5 = spark.end()
        } else {
          vm.calculateMD5(chunkId + 1)
        }
      }
      reader.readAsArrayBuffer(blobSlice.call(this.file, start, end))
    }
  },
  computed: {
    uploadable: function () {
      if (this.file === null) {
        return false
      }
      if (this.provider === null) {
        return false
      }
      if (this.rankingGroup === null) {
        return false
      }
      if (this.period === null) {
        return false
      }
      if (this.sizeOk === false) {
        return false
      }
      if (this.typeOk === false) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss">
  .theme--light.v-stepper {
    background: transparent;
  }
  .v-stepper {
    box-shadow: none;
  }
  .checkUpload {
    border: 5px solid #2196F3;
    color: #2196F3;
    padding: 50px;
    margin-top: 40px;
  }
  .v-application span .primary {
    background-color: #2196F3 !important;
    border-color: #2196F3 !important;
  }
</style>
