import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

let instance

/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) {
    return instance
  }

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data () {
      return {
        loading: true,
        isAuthenticated: false,
        user: null,
        auth0Client: null,
        popupOpen: false,
        error: null,
        email: null,
        picture: null
      }
    },
    methods: {
      setAuth: function (claims) {
        this.$http.defaults.headers.common.Authorization = 'bearer ' + claims.__raw
        this.email = claims.email
        this.user = null
        this.picture = claims.picture
        // Set our authentication flag to true after bearer token was set. Our Vue components rely on "isAuthenticated" variable,
        // and it shouldn't be set to true before bearer token is really set.
        this.isAuthenticated = true
        // Load current user from backend
        this.$http.get('/user/me')
          .then((response) => {
            this.user = response.data
          })
      },
      removeAuth: function () {
        this.$http.defaults.headers.common.Authorization = ''
        this.user = null
      },
      isAdmin: function () {
        if (this.user === null || typeof this.user === 'undefined') {
          return false
        }
        return this.user.roles.includes('ROLE_ADMIN')
      },
      waitForAuth: function () {
        const vm = this
        // Return a promise when waiting for the authentication process to finish.
        return new Promise(function (resolve, reject) {
          const isAuthLoaded = function (count) {
            // Add a limit to prevent an endless loop.
            if (count > 100000) {
              reject(new Error('Could not resolve auth. Aborting.'))
            } else if (vm.loading) {
              setTimeout(() => {
                isAuthLoaded(count + 1)
              }, 200)
            } else {
              resolve()
            }
          }
          // Start waiting for auth.
          isAuthLoaded(1)
        })
      },
      /** Authenticates the user using a popup window */
      async loginWithPopup (o) {
        this.popupOpen = true

        try {
          await this.auth0Client.loginWithPopup(o)
          // Set axios header
          const claims = await this.getIdTokenClaims()
          this.setAuth(claims)
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        } finally {
          this.popupOpen = false
        }
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback () {
        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          // Set axios header
          const claims = await this.getIdTokenClaims()
          this.setAuth(claims)
        } catch (e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect (o) {
        return this.auth0Client.loginWithRedirect(o)
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims (o) {
        return this.auth0Client.getIdTokenClaims(o)
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently (o) {
        return this.auth0Client.getTokenSilently(o)
      },
      /** Gets the access token using a popup window */
      getTokenWithPopup (o) {
        return this.auth0Client.getTokenWithPopup(o)
      },
      /** Logs the user out and removes their session on the authorization server */
      logout (o) {
        this.removeAuth()

        this.email = null
        return this.auth0Client.logout(o)
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created () {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: redirectUri
      })

      try {
        // If the user is returning to the app after authentication.
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          // Handle the redirect and retrieve tokens
          const { appState } = await this.handleRedirectCallback()
          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e
      } finally {
        // Initialize our internal authentication state
        const auth0Authenticated = await this.auth0Client.isAuthenticated()
        if (auth0Authenticated) {
          // Set axios header
          const claims = await this.auth0Client.getIdTokenClaims()
          this.setAuth(claims)
        } else {
          this.removeAuth()
        }
        this.loading = false
      }
    }
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install (Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  }
}
