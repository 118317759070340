<template>
  <v-container fluid class="relative header-space container">
    <div>
      <h1>Datenlieferanten</h1>
      <v-row>
        <v-col md="8" sm="12" class="pr-lg-12">
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-simple-table v-if="!loading">
                <thead>
                <tr>
                  <th class="text-left column-width">Name</th>
                  <th class="text-left column-width">UUID</th>
                  <th class="text-left column-width">Erstellt am</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="supplier in suppliers" v-bind:key="supplier.id">
                  <td class="font-bigger bold">{{ supplier.name }}</td>
                  <td class="mono">{{ supplier.id }}</td>
                  <td class="mono">{{ supplier.createdAt }}</td>
                </tr>
                </tbody>
              </v-simple-table>
              <div v-if="loading" outlined block :loading="loading" class="pa-4 text-center grey--text text--darken-2">
                <span>Liste wird aktualisiert</span>
                <v-progress-circular indeterminate color="primary" class="ml-4"></v-progress-circular>
              </div>
            </v-card>

            <div v-show="adding">
              <v-card class="pl-5 pr-1 py-2">
                <v-row>
                  <v-col lg="8">
                    <v-text-field
                      v-model="newName"
                      :counter="30"
                      label="Lieferanten-Name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col lg="4" class="text-right">
                    <v-btn v-on:click="createSupplier" class="mt-4" color="primary" :disabled="newName.length === 0" outlined>
                      Speichern
                    </v-btn>
                    <v-btn v-on:click="adding=false" icon class="mt-4 ml-2">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </div>

            <div class="text-right" v-show="!adding && !loading">
              <v-btn v-on:click="adding=true" color="blue darken-3" outlined>
                <v-icon class="pr-1">mdi-plus</v-icon> Lieferant hinzufügen
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col md="4" sm="12">
          <h3>Anleitung</h3>
          <p>Hier können Datenlieferanten eingesehen und hinzugefügt werden. </p>
          <p>Um einen neuen Lieferanten hinzuzufügen, muss auf den Button am unteren Ende der Seite geklickt werden.
          </p>
          <p><b>Anmerkung</b><br> Der Namenslänge des Datenlieferanten muss zwischen 1 und 30 Zeichen liegen.</p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    suppliers: [],
    adding: false,
    newName: '',
    loading: false
  }),
  methods: {
    getList: async function () {
      const response = await this.$http.get('/supplier')
      this.suppliers = response.data
    },
    createSupplier: async function () {
      this.adding = false
      this.loading = true
      const payload = { name: this.newName }
      this.newName = ''
      await this.$http.post('/supplier', payload)
      await this.getList()
      this.loading = false
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    await this.getList()
    this.initiated = true
  }
}
</script>
