<template>
    <v-container class="relative header-space container-full">
      <h1 class="mb-8">Konfliktbeseitigung</h1>
      <div v-if="conflict">
        <v-alert v-if="conflict.solvedAt !== null" type="success">Dieser Konflikt ist gelöst.</v-alert>
        <v-card v-if="sampleEntry" class="mb-12" color="bg-white">
          <div class="px-4 pt-4">
            <b>Mediscreen Lieferung</b><br>
            <b>Awardee Typ: {{ sampleEntryRootType }}</b><br>
            <b>Topliste:</b> {{ sampleEntry['toplist.label'] }}<br>
            <b>Awardee UUID:</b> <span class="mono">{{ sampleEntry['awardee_core_data.data.uuid'] }}</span><br>
            <b>Awardee Name (Core Data):</b> {{ sampleEntry['awardee_core_data.properties.name'] }}<br>
            <b>Awardee Name (Award):</b> {{ sampleEntry['awardee_award_data.properties.name'] }}<br>
            <div v-if="sampleEntryRootType === 'account'">
              <b>Awardee Adresse:</b>
              {{ sampleEntry['awardee_core_data.properties.address.street'] }},
              {{ sampleEntry['awardee_core_data.properties.address.zipcode'] }}
              {{ sampleEntry['awardee_core_data.properties.address.city'] }}
            </div>
            <div v-else>
              <!-- These keys don't look 100% right -->
              <b>Einrichtung (Quickfix*):</b> {{ sampleEntry['awardee_award_data.properties.affiliation'] }}
              <b>Einrichtung (Regulär):</b> {{ sampleEntry['awardee_award_data.properties.affiliation'] }},
              <b>Adresse:</b>
              {{ sampleEntry['awardee_core_data.relationships.mainAccount.properties.address.street'] }},
              {{ sampleEntry['awardee_core_data.relationships.mainAccount.properties.address.zipcode'] }}
              {{ sampleEntry['awardee_core_data.relationships.mainAccount.properties.address.city'] }}
            </div>
          </div>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                SampleEntry (JSON) anzeigen
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <pre class="px-4">{{ JSON.stringify(sampleEntry) | pretty }}</pre>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
        <v-row v-if="conflict" class="my-0">
          <v-col md="10">
            <v-row class="my-0">
              <v-col v-for="account in candidates" v-bind:key="account.id">
                <router-link :to="'/account/' + account.id" target="_blank">
                  <v-icon size="16" class="grey--text text--darken-1">mdi-open-in-new</v-icon>
                  <v-btn class="pa-0 mr-1"  tile small text><span class="font-body">ansehen: {{ account.id }}</span></v-btn>
                </router-link>
                <Account
                  :account="account"
                  :inspectionMode="true"
                  :readonly="true"
                  :loadChanges="false"
                  rootType="contact"
                  class="mb-4">
                </Account>
                <v-btn v-on:click="pick(account.id)" block class="mb-8" color="primary">Verknüpfen</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="2" class="pl-6">
            <p>Beim <b>Ignorieren</b> der Minq ID wird der Datensatz so verändert, als gäbe es keinen Account in der betrachteten Datenlieferung. Verknüpfungen aus Vorjahren bleiben unverändert bestehen.</p>
            <v-btn v-on:click="ignore()" block class="mb-8" color="primary">Ignorieren</v-btn>
            <p>Falls eine gute Einrichtung (*nicht Quickfix) geliefert wurde aber die Verbindung zu Einrichtungen aus Vorjahren nicht sinnvoll möglich ist, kann hiermit auf die Verknüpfung verzichtet werden. Das System legt den Account dann gemäß der Lieferung <b>neu an</b>. </p>
            <v-btn v-on:click="newAccount()" block class="mb-8" color="primary">Keine Minq-Verknüpfung</v-btn>
            <p></p>
          </v-col>
        </v-row>
      </div>
    </v-container>
</template>

<script>
import Account from './../components/Account'

export default {
  components: {
    Account
  },
  data: () => ({
    id: null,
    conflict: null,
    candidates: [],
    sampleEntry: null
  }),
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 4)
    }
  },
  methods: {
    loadConflict: async function () {
      const response = await this.$http.get('/conflict/' + this.id)
      this.conflict = response.data.conflict
      this.candidates = response.data.decisionParameters.accounts
      this.sampleEntry = response.data.decisionParameters.sampleEntry
      console.log(this.conflict)
      console.log(this.candidates)
      console.log(this.sampleEntry)
    },
    pick: async function (accountId) {
      const decision = { command: 'pickOne', chosenAccountId: accountId }
      console.log(decision)
      const response = await this.$http.post('/conflict/decide/' + this.id, decision)
      this.closeConflict()
      console.log(response)
    },
    ignore: async function () {
      const decision = { command: 'ignore' }
      const response = await this.$http.post('/conflict/decide/' + this.id, decision)
      this.closeConflict()
      console.log(response)
    },
    newAccount: async function () {
      const decision = { command: 'newAccount' }
      const response = await this.$http.post('/conflict/decide/' + this.id, decision)
      this.closeConflict()
      console.log(response)
    },
    closeConflict: function () {
      this.conflict.solvedAt = true
    }
  },
  computed: {
    sampleEntryRootType: function () {
      if (
        this.sampleEntry !== null &&
        'awardee_award_data.data.type' in this.sampleEntry
      ) {
        if (this.sampleEntry['awardee_award_data.data.type'] === 'medic') {
          // "medic" is the only possible contact based awardee, everything else is an account.
          return 'contact'
        } else {
          return 'account'
        }
      }
      return null
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      this.$router.push('/')
    }

    this.id = this.$route.params.id
    // Load Conflicts
    await this.loadConflict()
    // ToDo Load sample row
  }
}
</script>
