<template>
  <v-container fluid class="relative header-space container">
    <div>
      <h1>Mapping Editor</h1>
      <v-card class="pt-4 pb-3 px-4">
        <p>An dieser Stelle folgt ein Mapping Edior.</p>
      </v-card>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'MappingEditor'
}
</script>

<style scoped>
</style>
