<template>
  <div class="mr-1 mt-1">
    <v-tooltip bottom v-if="!active">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" v-on:click="active=true" color="primary" icon outlined >
          <v-icon>mdi-lock-outline</v-icon>
        </v-btn>
      </template>
      <span>
        Dieser Eintrag ist neu und benötigt redaktionelle Freigabe.<br>
        Vorher ist die Bearbeitung gesperrt und er wird ausgeschlossen von Exporten und Salesforce.
      </span>
    </v-tooltip>
    <v-btn
      v-if="active && !loading"
      v-on:click="approve"
      class="approve-buttons"
      color="blue darken-3"
      :disabled="loading"
      :loading="loading"
      rounded
      outlined>
      Verifizieren <v-icon class="ml-1" style="margin-right: -6px">mdi-lock-open-outline</v-icon>
    </v-btn>
    <v-btn v-if="loading" color="primary" icon outlined loading></v-btn>

    <v-btn
      v-if="active && !loading"
      v-on:click.stop="showDialog = true"
      class="approve-buttons mt-3"
      color="red darken-1"
      rounded
      outlined >
      Ausschließen <v-icon class="ml-1" style="margin-right: -6px">mdi-close</v-icon>
    </v-btn>
    <ExcludeDialog v-model="showDialog" :targetClass="targetClass" :id="id"></ExcludeDialog>

  </div>
</template>

<script>
import ExcludeDialog from './ExcludeDialog'
export default {
  components: { ExcludeDialog },
  props: {
    targetClass: String,
    id: String
  },
  data: () => ({
    active: false,
    loading: false,
    target: null,
    showDialog: false
  }),
  created () {
    this.target = this.$state.getFromRegistry(this.id)
  },
  methods: {
    approve: async function () {
      this.loading = true
      await this.$http.put(this.targetClass + '/' + this.id + '/approve')
      // Reload changes
      this.$changeService.getPendingChangesByTarget(this.id, this.targetClass, true)
      this.target.isApproved = true
      this.loading = false
      this.active = false
    }
  },
  computed: {
  //
  }
}
</script>

<style scoped>
  .approve-buttons {
    margin-left: auto;
    margin-right: 0;
    display: block;
  }
</style>
