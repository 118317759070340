<template>
  <div>
    <div class="col-lg-8 col-sm-10 px-0">
      <p class="overline mb-0">Übersicht</p>
      <h1>Projekte</h1>
      <p>Das FOCUS Data Warehouse ist ein zentrales Datenbanksystem,
        das relevante Daten aus verschiedenen heterogenen Datenquellen extrahiert,
        sammelt, sichert und nachgelagerte Systeme versorgt.
        <br>Darüber hinaus sind hier die Projekte des <a href="">Center of Competence</a> gelistet.
      </p>
    </div>
    <v-row>
      <v-col md="4" sm="6" class="py-3" v-for='(sector, index) in rankingGroups' :key="index">
        <v-card tile class="fill-height">
          <v-list flat dense>
            <v-list-item-group>
              <v-img :src="'/img/logos/' + sectorString(index) + '-logo.png'" max-height="30px" contain class="my-6"></v-img>
              <v-list-item v-for='rankingGroup in sector' :to="'/projekt/' + rankingGroup.shortCode" :key="rankingGroup.id">
                <v-list-item-icon class="acronym monospace mr-3">{{ rankingGroup.shortCode }}</v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ rankingGroup.name }} <v-icon>mdi-chevron-right</v-icon></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { groupBy } from 'lodash'

export default {
  data: () => ({
    rankingGroups: {}
  }),
  methods: {
    loadRankingGroups: async function () {
      const response = await this.$http.get('/ranking_group/list')
      this.rankingGroups = groupBy(response.data, 'publicationSector')
    },
    sectorString (sectorKey) {
      return sectorKey.toLowerCase().replace('sector_', '')
    }
  },
  async created () {
    // Firewall
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      return
    }

    try {
      await this.loadRankingGroups()
    } catch (e) {}
  }
}
</script>
