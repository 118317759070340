<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row>
        <v-col sm="12">
          <h1>Samples</h1>
          <p>Eine Übersicht der hochgeladenen Dateien bzw. API Abfragen.</p>
        </v-col>
        <v-col class="text-right">
          <v-btn v-if="this.fetchRunning === true" class="ml-3 mb-3 primary" v-on:click="getList"><v-icon class="mr-1">mdi-refresh</v-icon>Refresh</v-btn><br>
          <p v-if="this.fetchRunning === true">
            <v-progress-circular size="15" :width="2" indeterminate></v-progress-circular>
            Ein API Fetch läuft gerade.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-container>
            <v-pagination
              v-model="samplesPage"
              class="my-4"
              :length="samplesPaginationLength"
              @input="getList"
            ></v-pagination>
          </v-container>
        </v-col>
        <v-col cols="12">
          <p class="small">Einträge {{ samplesCurrentOffset + 1 }} - {{ samplesCurrentOffset + samplesPerPage }} von {{ samplesTotalLength }}.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-simple-table v-if="!loading">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Rohdatei/API Fetch</th>
                  <th>Lieferant</th>
                  <th>Jahr</th>
                  <th>Projekt</th>
                  <th>angewandtes Mapping</th>
                  <th>Zeilen</th>
                  <th>erzeugte Entities</th>
                  <th>erzeugte Changes</th>
                  <th>Duplikat</th>
                  <th>Aktion</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="sample in samples" v-bind:key="sample.id" class="mono">
                  <td>
                    <v-tooltip right max-width="500" color="grey lighten-2">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon color="blue darken-3">
                          <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                      </template>
                      <span class="mono grey--text text--darken-4"><b>ID:</b> {{ sample.id }}</span><br>
                      <span class="mono grey--text text--darken-4"><b>Erstellt am:</b> {{ new Date(sample.createdAt).toLocaleString() }}</span><br>
                    </v-tooltip>
                  </td>
                  <td>{{ sample.rawDataReference }}</td>
                  <td>{{ sample.supplier.name }}</td>
                  <td>{{ sample.period.name }}</td>
                  <td style="white-space: pre">{{ sample.rankingGroup.shortCode }} ({{ sample.rankingGroup.name }})</td>
                  <td style="white-space: pre">
                    {{ sample.mapping ? sample.mapping.name : '-' }}<br>
                    {{ sample.mapping ? 'am: ' + new Date(sample.mappedDate).toLocaleString() : '' }}
                  </td>
                  <td>{{ sample.parent === null ? sample.sampleEntriesCount : sample.parent.sampleEntriesCount }}</td>
                  <td class="pt-2 pb-2" style="white-space: pre">
                    <span><b>Accounts:</b> {{ sample.accountsCount }}</span><br>
                    <span><b>Contacts:</b> {{ sample.contactsCount }}</span><br>
                    <span><b>Awards:</b> {{ sample.awardsCount }}</span><br>
                    <span><b>Scores:</b> {{ sample.scoresCount }}</span><br>
                    <span><b>Products:</b> {{ sample.productsCount }}</span><br>
                    <span><b>TOTAL:</b> {{ sample.entityCount }}</span><br>
                  </td>
                  <td>{{ sample.changesCount }}</td>
                  <td>{{ sample.parent === null ? 'nein' : 'ja' }}</td>
                  <td>
                    <v-btn class="primary white--text" v-if="sample.mapping === null" v-on:click="$router.push({ path: '/analyse/' + sample.id })">Import</v-btn>
                    <v-btn class="error white--text" v-on:click="changePeriod(sample)">Jahr ändern</v-btn>
                    <v-btn class="primary white--text" v-on:click="duplicate(sample)">Duplizieren</v-btn>
                    <v-btn class="secondary white--text" v-on:click="copyToClipboard(sample.id)">ID kopieren</v-btn>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
              <v-dialog v-model="changePeriodDialog" max-width="450">
                <v-card>
                  <v-card-title>
                    <v-icon large left>mdi-information-outline</v-icon>
                    <span class="title">Achtung!</span>
                  </v-card-title>
                  <v-card-text>
                    Hier kann das Jahr des Samples geändert werden.
                    <v-text-field
                      v-model="newPeriod"
                      label="Jahr"
                      required
                    ></v-text-field>
                    <p class="small">
                      Bitte beachten Sie, dass bei der Änderung des Jahres auch etwaige Scores und Awards das Jahr wechseln werden.
                      Dies kann unerwartete Folgen bzgl. Elasticsearch und Salesforce haben. Das heißt: nur in Absprache mit IT ändern!
                    </p>
                  </v-card-text>
                  <v-card-actions class="px-6 pb-6">
                    <v-btn v-on:click="changePeriodDialog=false" class="primary--text" outlined>
                      Abbrechen
                    </v-btn>
                    <v-btn v-on:click="changePeriodConfirm(newPeriod)" class="error">
                      Speichern
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="duplicateDialog" max-width="450">
                <v-card>
                  <v-card-title>
                    <v-icon large left>mdi-information-outline</v-icon>
                    <span class="title">Achtung!</span>
                  </v-card-title>
                  <v-card-text>
                    Möchten sie wirklich dieses Sample duplizieren?
                    Diese Funktion ist hauptsächlich dafür gedacht, ein Sample neu (mit einem anderen Mapping) importieren zu können.
                  </v-card-text>
                  <v-card-actions class="px-6 pb-6">
                    <v-btn v-on:click="duplicateDialog=false" class="primary--text" outlined>
                      Abbrechen
                    </v-btn>
                    <v-btn v-on:click="duplicateConfirm()" class="primary">
                      Duplizieren
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    samples: [],
    samplesTotalLength: null,
    samplesPaginationLength: null,
    samplesPage: 1,
    samplesPerPage: 10,
    samplesCurrentOffset: 0,
    loading: false,
    fetchRunning: false,
    changePeriodDialog: false,
    duplicateDialog: false,
    newPeriod: null,
    editedItem: null
  }),
  methods: {
    getList: async function (page = 1) {
      const offset = (page - 1) * this.samplesPerPage
      const response = await this.$http.get('/sample/list?limit=' + this.samplesPerPage + '&offset=' + offset)
      this.samples = response.data.list
      this.samplesTotalLength = response.data.total
      this.samplesPaginationLength = Math.ceil(response.data.total / this.samplesPerPage)
      this.samplesPage = page
      this.samplesCurrentOffset = offset

      console.log(this.samples)
      await this.checkApiFetchStatus()
    },
    async checkApiFetchStatus () {
      const response = await this.$http.get('/api-import/fetch_status')
      this.fetchRunning = response.data
    },
    changePeriod: async function (item) {
      this.editedItem = Object.assign({}, item)
      this.changePeriodDialog = true
    },
    changePeriodConfirm: async function (period) {
      this.loading = true
      await this.$http.post('/sample/change-period/' + this.editedItem.id + '/' + period)
      this.changePeriodDialog = false
      await this.getList()
      this.loading = false
    },
    duplicate: async function (item) {
      this.editedItem = Object.assign({}, item)
      this.duplicateDialog = true
    },
    duplicateConfirm: async function () {
      this.loading = true
      await this.$http.post('/sample/duplicate/' + this.editedItem.id)
      this.duplicateDialog = false
      await this.getList()
      this.loading = false
    },
    copyToClipboard: function (value) {
      navigator.clipboard.writeText(value)
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    await this.getList()
    this.initiated = true
  }
}
</script>
