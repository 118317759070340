<template>
  <v-card class="pa-4 mb-2">
    <div v-if="oldDynamicFields.length === 0 && newDynamicFields.length === 0">
      <p class="explanation small mb-0">Für diese Kachel liegen keine Informationen vor.</p>
    </div>
    <div v-else>
      <div class="absolute context-menu right mr-2 ml-auto" style="top: 8px;" v-if="!inspectionMode">
        <Approve
          v-if="!score.isApproved && !score.excludedByEditor"
          v-model="score.isApproved"
          targetClass="score"
          :id="score.id"></Approve>
        <DetailContext
          v-if="score.isApproved || score.excludedByEditor"
          targetClass="score"
          :id="score.id"></DetailContext>
      </div>
    </div>

    <div v-if="score.excludedByEditor">
      <v-chip class="mb-2 grey--text text--darken-2" outlined>
        <v-icon left>mdi-alert-circle-outline</v-icon>
        Auszeichnung redaktionell ausgeschlossen
      </v-chip>
    </div>

    <p class="grey--text text--darken-2 mb-0 mb-1">{{ score.rankingGroup.shortCode }} {{ score.period.name }}</p>

    <div class="text-center" v-if="!showMoreInfoForChanges">
      <v-btn
        v-if="!showMoreInfoToggle"
        v-on:click="showMoreInfoToggle = true"
        class="my-5"
        text>
        Mehr Informationen <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
      <v-btn
        v-if="showMoreInfoToggle"
        v-on:click="showMoreInfoToggle = false"
        class="my-5"
        text>
        Weniger Informationen <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </div>

    <div class="grey lighten-4 mb-4" v-show="showMoreInfo">
      <InputSwitch
        v-for="field in oldDynamicFields"
        v-bind:key="field.label.label"
        :label="field.label.label"
        v-model="field.value"
        :entryId="score.id"
        targetClass="score"
        :readonly="readOnlyMode"
        :field="field.label.name"
        :inspectionMode="inspectionMode"
        :selectables="getSelectables(field)"
        :data-type="field.label.type"
      ></InputSwitch>

      <InputSwitch
        v-for="field in newDynamicFields"
        v-bind:key="field.label.name"
        :label="field.label.name"
        v-model="field.value"
        :entryId="score.id"
        targetClass="score"
        :readonly="readOnlyMode"
        :field="field.label.name"
        :inspectionMode="inspectionMode"
      ></InputSwitch>
    </div>

    <v-btn
      v-if="!showSupplier"
      v-on:click="showSupplier = true"
      class="px-0 mx-0 mb-0 grey--text text--darken-2 mono"
      elevation="0"
      color="white">
      Lieferanten <v-icon>mdi-chevron-down</v-icon>
    </v-btn>
    <v-btn
      v-if="showSupplier"
      v-on:click="showSupplier = false"
      class="px-0 mx-0 mb-0 grey--text text--darken-2 mono"
      elevation="0"
      color="white">
      Lieferanten <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
    <div v-show="showSupplier" class="mb-0 pt-3">
      <p v-for="item in score.supplierIds" :key="item.value + item.scope" class="grey--text text--darken-2 small">
        <span class="mb-1">{{ item.supplier.name }} ({{ item.scope }}): </span>
        <span class="mono mb-0">{{ item.value }}</span>
      </p>
    </div>

  </v-card>
</template>

<script>
import InputSwitch from './InputSwitch'
import Approve from './Approve'
import DetailContext from './DetailContext'
import { filter } from 'lodash'

export default {
  props: {
    score: Object,
    readonly: {
      default: false
    },
    inspectionMode: {
      default: false
    }
  },
  components: {
    InputSwitch,
    Approve,
    DetailContext
  },
  data: () => ({
    changes: {},
    showSupplier: false,
    untrackedFields: null,
    showMoreInfoToggle: false
  }),
  created () {
    this.$state.addToRegistry(this.score)
    this.changes = this.$changeService.getPendingChangesByTarget(this.score.id, 'score')
  },
  watch: {
    changes: function () {
      this.doPolyfill()
    }
  },
  methods: {
    doPolyfill () {
      // Make sure dynamic fields that do not exist yet on the entity wll be visible if they have changes.
      // Check polyfill cache.
      if (this.untrackedFields !== null) {
        return
      }
      // No changes will skip the whole process.
      if (Object.keys(this.changes).length === 0) {
        return
      }
      // Collect those MDL attributes that are already known.
      const trackedAttributes = []
      this.score.dynamicFields.forEach(function (mdf) {
        if (Object.prototype.hasOwnProperty.call(mdf, 'label')) {
          trackedAttributes.push(mdf.label.name)
        }
      })
      // Get all attributes that we have changes for.
      const attributes = Object.keys(this.changes)
      // Create cache to collect polyfilled attributes.
      this.untrackedFields = []
      // Go through all change attributes.
      attributes.forEach((attribute) => {
        // The attribute may not be tracked yet.
        if (!trackedAttributes.includes(attribute)) {
          this.untrackedFields.push(attribute)
          // Polyfill MDL
          const polyfill = { value: null, polyfill: true, label: { name: attribute } }
          this.score.dynamicFields.push(polyfill)
        }
      })
    },
    getSelectables: function (field) {
      if (field.label.type === 'TYPE_BOOLEAN') {
        const selectable = [
          {
            text: 'true',
            value: true
          },
          {
            text: 'false',
            value: false
          }
        ]

        if (field.label.nullable === true) {
          selectable.push({
            text: 'k.A.',
            value: null
          })
        }

        return selectable
      }

      return null
    }
  },
  computed: {
    readOnlyMode: function () {
      if (this.readonly === true) {
        return true
      }
      if (this.score.excludedByEditor === true) {
        return true
      }
      if (this.score.isApproved === false) {
        return true
      }
      return false
    },
    newDynamicFields: function () {
      return filter(this.score.dynamicFields, (field) => {
        return typeof field.polyfill !== 'undefined'
      }).sort(function (a, b) {
        return a.label.name.localeCompare(b.label.name)
      })
    },
    oldDynamicFields: function () {
      // Show only fields that are not polyfilled
      return filter(this.score.dynamicFields, (field) => {
        return typeof field.polyfill === 'undefined'
      }).sort(function (a, b) {
        return a.label.label.localeCompare(b.label.label)
      })
    },
    showMoreInfoForChanges: function () {
      return Object.keys(this.changes).length !== 0
    },
    showMoreInfo: function () {
      if (this.showMoreInfoToggle) {
        return true
      }
      if (this.showMoreInfoForChanges) {
        return true
      }
      return false
    }
  }
}
</script>
