<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <h5>5-stellige Postleitzahlen</h5>
          <p class="explanation body-2 pt-3 pr-3 mb-0">
            Prüfung, ob innerhalb einer Spalte mehrheitlich Zahlen mit fünf Stellen vorliegen (z.B. Postleitzahlen).
          </p>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="profile === true">
          <p class="mb-0">
            <v-icon color="light-green">mdi-checkbox-marked-circle-outline</v-icon>
            Ok.
          </p>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-else>
        <p class="pb-2">
          <v-icon color="amber">mdi-alert-outline</v-icon>
          Vorsicht! Es wurden nicht konsistente Einträge gefunden. Eine Prüfung wird empfohlen.
        </p>
        <v-simple-table>
          <tr>
            <td v-for ="colName in header" class="column"> {{ colName }}</td>
          </tr>
          <tr>
            <td v-for="value in sub" class="column monospace">
              <p v-if="value === true"><v-icon size="18" class="small-icon">mdi-check</v-icon></p>
              <p v-else><v-icon size="18" class="small-icon" color="red darken-1">mdi-close</v-icon><br>
                <v-btn v-on:click="showInfo = true" text v-if="!showInfo">
                  mehr <v-icon size="18" class="small-icon">mdi-chevron-down</v-icon>
                </v-btn>
                <v-btn v-on:click="showInfo = false" text v-if="showInfo">
                  weniger <v-icon size="18" class="small-icon">mdi-chevron-up</v-icon>
                </v-btn>
                <span v-if="showInfo" class="small" style="line-height: 0.5">{{ value }}</span>
              </p>
            </td>
          </tr>
        </v-simple-table>
        <p class="explanation body-2 pt-3 mb-0">Als Maßstab werden 5-stellige Zahlen, wie deutsche Postleizahlen verwendet.<br>
          4-stellige österreichische Postleizahlen zum Beispiel werden nicht berücksichtigt.
        </p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: [
    'profile',
    'sub',
    'header'
  ],
  data: () => ({
    showInfo: false
  })
}
</script>
