<template>
  <v-app>
    <div>
      <v-app-bar fixed elevate-on-scroll v-if="$auth.isAuthenticated" color="white">
        <router-link to="/" class="mr-6">
          <v-toolbar-title>
            <v-img src="/img/logos/Logo-Data-Warehouse.png" max-height="50" max-width="50" contain></v-img>
          </v-toolbar-title>
        </router-link>
        <v-menu offset-y v-if="$vuetify.breakpoint.xsOnly" min-width="250px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Menü
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn to="/templates" width="100%">
                <v-icon>mdi-content-copy</v-icon><span>Templates</span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/api-import" width="100%">
                <v-icon>mdi-api</v-icon><span>Import</span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/upload" width="100%">
                <v-icon>mdi-upload</v-icon><span>Upload</span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/verifikation" width="100%">
                <v-icon>mdi-source-pull</v-icon><span>Verifikation</span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/follow-up" width="100%">
                <v-icon>mdi-help-circle-outline</v-icon><span>Follow Up</span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/konflikte" width="100%">
                <v-icon>mdi-account-switch-outline</v-icon><span>Konflikte</span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/export" width="100%">
                <v-icon>mdi-file-export</v-icon><span>Export</span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/verwaltung" width="100%">
                <v-icon>mdi-cog</v-icon><span>Verwaltung</span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/freigabe" width="100%">
                <v-icon>mdi-send-check</v-icon><span>Freigabe</span>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <span v-if="$vuetify.breakpoint.smAndUp">
          <v-btn icon to="/templates" class="ml-3 nav-btn" color="grey darken-1">
            <v-icon>mdi-content-copy</v-icon>
            <span class="show-on-hover position-absolute" style="top: 28px">Templates</span>
          </v-btn>
          <v-btn icon to="/api-import" color="grey darken-1" class="nav-btn">
            <v-icon>mdi-api</v-icon>
            <span class="show-on-hover position-absolute" style="top: 28px">Import</span>
          </v-btn>
          <v-btn icon to="/upload" color="grey darken-1" class="nav-btn">
            <v-icon>mdi-upload</v-icon>
            <span class="show-on-hover position-absolute" style="top: 28px">Upload</span>
          </v-btn>
          <v-btn icon to="/verifikation" class="ml-3 nav-btn" color="grey darken-1">
            <v-icon>mdi-source-pull</v-icon>
            <span class="show-on-hover position-absolute" style="top: 28px">Verifikation</span>
          </v-btn>
          <v-btn icon to="/follow-up" class="ml-3 nav-btn" color="grey darken-1">
            <v-icon>mdi-help-circle-outline</v-icon>
            <span class="show-on-hover position-absolute" style="top: 28px">Follow Up</span>
          </v-btn>
          <v-btn icon to="/konflikte" class="ml-3 nav-btn" color="grey darken-1">
            <v-icon>mdi-account-switch-outline</v-icon>
            <span class="show-on-hover position-absolute" style="top: 28px">Konfliktbeseitigung</span>
          </v-btn>
          <v-btn icon to="/export" class="ml-3 nav-btn" color="grey darken-1">
            <v-icon>mdi-file-export</v-icon>
            <span class="show-on-hover position-absolute" style="top: 28px">Export</span>
          </v-btn>
          <v-btn icon to="/verwaltung" class="ml-3 nav-btn" color="grey darken-1">
            <v-icon>mdi-cog</v-icon>
            <span class="show-on-hover position-absolute" style="top: 28px">Verwaltung</span>
          </v-btn>
          <v-btn icon to="/freigabe" class="ml-3 nav-btn" color="grey darken-1">
            <v-icon>mdi-send-check</v-icon>
            <span class="show-on-hover position-absolute" style="top: 28px">Freigabe</span>
          </v-btn>
        </span>

        <v-spacer></v-spacer>

        <v-dialog v-model="showNotificationPanel" fullscreen hide-overlay transition="dialog-bottom-transition" v-if="$auth.isAuthenticated">
          <template v-slot:activator="{ on }">
            <v-badge color="red darken-2"
                     :content="$state.unreadNotificationCount"
                     :value="$state.unreadNotificationCount !== 0"
                     overlap
                     offset-x="23"
                     offset-y="23"
            >
              <v-btn icon v-on="on">
                <v-icon color="grey darken-1">mdi-bell</v-icon>
              </v-btn>
            </v-badge>
          </template>

          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark v-on:click="closeNotification">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Benachrichtigungen</v-toolbar-title>
            </v-toolbar>

            <v-card v-for="notification in $state.notifications" v-bind:key="notification.tid" width="80%" class="mt-5 ml-auto mr-auto pa-4">
              <!-- explicit error with message and status -->
              <p v-if="notification.data && notification.data.message" class="mb-0">
                <span class="bold">Status Code:</span> {{ notification.status }} <br>
                <span class="bold pb-2">Nachricht:</span> {{ notification.data.message }}  <br>
                <span class="mono grey--text">{{ notification.time }}</span>
              </p>
              <!-- error but with a return -->
              <p v-else-if="notification.data" class="mb-0">
                <span class="bold">Status Code:</span> {{ notification.status }} <br>
                <v-expansion-panels flat class="pt-2 pb-2 my-0">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="pl-0 py-0 error-panel bold">Nachricht:</v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-2">
                      {{ notification.data }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <span class="mono grey--text">{{ notification.time }}</span>
              </p>
              <!-- unknown error -->
              <p v-else class="mb-0">
                <v-expansion-panels flat class="pt-2 pb-2 my-0">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="pl-0 py-0 error-panel">Nachricht:</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{ notification }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </p>
            </v-card>
          </v-card>
        </v-dialog>

        <v-avatar v-if="$auth.user" size="45" color="teal">
          <img :src="$auth.picture">
        </v-avatar>

        <div v-if="!$auth.loading" class="my-4 ml-3">
          <v-btn outlined class="grey--text" v-if="$auth.isAuthenticated" @click="$auth.logout()">
            <span v-if="$vuetify.breakpoint.smAndUp">Logout</span>
            <v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-logout</v-icon>
          </v-btn>
        </div>
        <v-progress-linear :active="$state.loading" class="bottom" absolute height="2" indeterminate></v-progress-linear>
      </v-app-bar>
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </v-app>
</template>

<style lang="scss">
  // Require all global styles in the VUE root component.
  @import "scss/global.scss";

  .header-space {
    padding-top: 84px;
    padding-bottom: 64px;
  }
  .error-panel {
    font-size: 1em;
  }
  .v-expansion-panel-header.error-panel {
    min-height: 16px;
  }
  .v-expansion-panel--active > .v-expansion-panel-header.error-panel {
    min-height: 16px;
  }
  .v-application--is-ltr .v-expansion-panel-header__icon {
    margin-left: 10px;
  }
  .show-on-hover {
    display: none;
  }
  .nav-btn:hover .show-on-hover {
    display: block;
    background-color: white;
  }
</style>

<script>
export default {
  name: 'App',
  components: {
    //
  },
  data: () => ({
    showNotificationPanel: false,
    show: true
  }),
  methods: {
    closeNotification () {
      this.showNotificationPanel = false
      this.$state.unreadNotificationCount = 0
    }
  }
}
</script>
