import Vue from 'vue'

let instance

const getState = (options) => {
  if (instance) {
    return instance
  }

  instance = new Vue({
    data: () => ({
      loader: 0,
      filters: {
        period: '2021',
        toplist: null,
        rankingGroup: null
      },
      root: null,
      registry: {},
      notifications: [],
      unreadNotificationCount: 0,
      verificationItems: [],
      verificationIndex: 0
    }),
    methods: {
      setRoot: function (object) {
        this.root = object
        this.addToRegistry(object)
        return this.root
      },
      addToRegistry: function (object) {
        if (Array.isArray(object)) {
          object.forEach((item) => {
            this.addToRegistry(item)
          })
        } else {
          this.registry[object.id] = object
        }
      },
      getFromRegistry: function (id) {
        if (typeof this.registry[id] === 'undefined') {
          return null
        }
        return this.registry[id]
      },
      resetRegistry: function () {
        this.registry = {}
      },
      addLoader: function () {
        this.loader++
      },
      resolveLoader: function () {
        if (this.loader > 0) {
          this.loader--
        }
      },
      addNotification: function (note) {
        note.tid = Math.random().toString(36).substring(7)
        this.notifications.push(note)
        this.unreadNotificationCount += 1
      },
      setVerificationItems: function (items) {
        this.verificationItems = items
        this.verificationIndex = 0
      },
      getNextVerifictaionItem: function () {
        if (!this.hasNextVerifictaionItem()) {
          return null
        }
        this.verificationIndex++

        return this.verificationItems[this.verificationIndex]
      },
      hasNextVerifictaionItem: function () {
        return typeof this.verificationItems[this.verificationIndex + 1] !== 'undefined'
      },
      getPreviousVerifictaionItem: function () {
        if (!this.hasPreviousVerifictaionItem()) {
          return null
        }
        this.verificationIndex--

        return this.verificationItems[this.verificationIndex]
      },
      hasPreviousVerifictaionItem: function () {
        return typeof this.verificationItems[this.verificationIndex - 1] !== 'undefined'
      }
    },
    computed: {
      loading: function () {
        return this.loader !== 0
      }
    }
  })

  return instance
}

export const ApplicationState = {
  install (Vue, options) {
    Vue.prototype.$state = getState(options)
  }
}
