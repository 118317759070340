<template>
  <div>
  <v-card class="pa-4 mb-2">
    <div class="notes">
      <v-textarea
        v-if="readOnly === false"
        v-model="content"
        :disabled="loading"
        :loading="loading"
        label="Kommentar"
        placeholder=" "
        rows="1"
        outlined
        auto-grow
        hide-details
      >
      </v-textarea>
      <div class="text-right" v-if="readOnly === false">
        <v-btn
          v-on:click="post"
          :disabled="loading"
          text
          small
          class="mt-2"
        >
          Speichern
        </v-btn>
      </div>
      <v-simple-table>
        <tbody>
        <tr>
          <td>Datum & User</td>
          <td>Inhalt</td>
        </tr>
        <tr v-if="notes.length === 0">
          <td>Noch keine Einträge</td>
          <td></td>
        </tr>
        <tr class="note" v-for="note in sortedNotes" :key="note.id">
          <td class="td-bg-white mono">
            <span v-if="note.createdAt === undefined">{{ note.created }}</span>
            <span v-else>{{ new Date(note.createdAt).toLocaleString() }}</span><br>

            <span class="bold">{{ note.user.fullName }}</span>
          </td>
          <td class="td-bg-white td-content">{{ note.content }}</td>
        </tr>
        </tbody>
      </v-simple-table>

    </div>
  </v-card>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    rootClass: String,
    notes: Array,
    readOnly: Boolean
  },
  components: {
    //
  },
  data: () => ({
    content: null,
    loading: false
  }),
  methods: {
    post: async function () {
      this.loading = true
      const newNote = {
        content: this.content,
        targetId: this.id,
        targetClass: this.rootClass
      }
      const response = await this.$http.post('/messages', newNote)
      newNote.created = new Date(response.data.createdAt).toLocaleString()
      newNote.user = response.data.user
      newNote.id = response.data.id
      this.notes.unshift(newNote)
      this.loading = false
      this.content = null
    }
  },
  computed: {
    sortedNotes: function () {
      const notes = this.notes
      notes.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
      return notes
    }
  }
}
</script>

<style scoped lang="scss">
  .td-bg-white {
    background-color: white;
  }
  .td-content {
    width: 60%;
  }
  .v-data-table td {
    padding-left: 0;
  }
  .theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row) {
    border-bottom: none;
  }
</style>
