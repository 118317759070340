<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <h5>Konsistenzprüfung: Text-/ Zahlenform</h5>
          <p class="explanation body-2 pt-3 pr-3 mb-0">Prüfung, ob alle Einträge innerhalb einer Spalte einheitliche Form haben.</p>
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <StringConsistencyAnalyse v-if="profile.StringConsistencyCheck === false" :profile="profile.StringConsistencyCheck" :sub="profile['StringConsistencyCheck-sub']" :header="profile['HeaderItemCount-sub']" class="mb-6"></StringConsistencyAnalyse>
        <IntegerConsistencyAnalyse v-if="profile.IntegerConsistencyCheck === false" :profile="profile.IntegerConsistencyCheck" :sub="profile['IntegerConsistencyCheck-sub']" :header="profile['HeaderItemCount-sub']" class="mb-6"></IntegerConsistencyAnalyse>
        <FloatConsistencyAnalyse v-if="profile.FloatConsistencyCheck === false" :profile="profile.FloatConsistencyCheck" :sub="profile['FloatConsistencyCheck-sub']" :header="profile['HeaderItemCount-sub']" class="mb-6"></FloatConsistencyAnalyse>
      </v-expansion-panel-content>

      <v-expansion-panel-content>
        <StringConsistencyAnalyse v-if="profile.StringConsistencyCheck === true" :profile="profile.StringConsistencyCheck" :sub="profile['StringConsistencyCheck-sub']" :header="profile['HeaderItemCount-sub']" class="mb-6"></StringConsistencyAnalyse>
        <IntegerConsistencyAnalyse v-if="profile.IntegerConsistencyCheck === true" :profile="profile.IntegerConsistencyCheck" :sub="profile['IntegerConsistencyCheck-sub']" :header="profile['HeaderItemCount-sub']" class="mb-6"></IntegerConsistencyAnalyse>
        <FloatConsistencyAnalyse v-if="profile.FloatConsistencyCheck === true" :profile="profile.FloatConsistencyCheck" :sub="profile['FloatConsistencyCheck-sub']" :header="profile['HeaderItemCount-sub']" class="mb-6"></FloatConsistencyAnalyse>
        <div v-if="checkIsFalse">
          <p class="explanation body-2 pt-3 mb-0">
            <v-icon size="18" class="small-icon">mdi-check</v-icon>bedeutet eine Spalte hat einheitliche Einträge.<br>
            <v-icon size="18" class="small-icon" color="red darken-1">mdi-close</v-icon>bedeutet, eine Spalte enthält eine Mischung textförmiger und numerischer Daten.
          </p>
        </div>
      </v-expansion-panel-content>

    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import StringConsistencyAnalyse from '../../components/analyse/StringConsistencyAnalyse'
import IntegerConsistencyAnalyse from '../../components/analyse/IntegerConsistencyAnalyse'
import FloatConsistencyAnalyse from '../../components/analyse/FloatConsistencyAnalyse'

export default {
  components: {
    StringConsistencyAnalyse,
    IntegerConsistencyAnalyse,
    FloatConsistencyAnalyse
  },
  props: [
    'profile'
  ],
  computed: {
    checkIsFalse: function () {
      if (this.profile.StringConsistencyCheck === true) {
        return false
      }
      if (this.profile.IntegerConsistencyCheck === true) {
        return false
      }
      if (this.profile.FloatConsistencyCheck === true) {
        return false
      }
      return true
    }
  }
}
</script>
