<template>
  <v-container fluid class="relative header-space container" v-show="initiated">
    <div>
      <!-- Message on download success -->
      <v-alert v-if="downloadSuccessful === true" dismissible type="success">Der Export war erfolgreich!</v-alert>

      <h1>Export</h1>
      <div>
        <p>Hier können aktuell folgende Exporte erstellt werden:</p>
        <ul class="my-3">
          <li>EasyCatalog (Print)</li>
          <li>Research (FactField Research Team)</li>
          <li>Stiftung Gesundheit (SGH, Focus Arztsuche)</li>
        </ul>
        <p>
          Alle Exporte werden als CSV Datei zur Verfügung gestellt. Falls irgendwelche Spalten in den Exporten vermisst werden, bitte
          an die IT wenden.
        </p>
      </div>

      <!-- Export area -->
      <v-card class="px-5 py-5 mb-5">
        <v-card-title>Exportfilter</v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="4" sm="12">
              <v-select
                v-model="$state.filters.rankingGroup"
                item-text="name"
                item-value="shortCode"
                :items="rankingGroups"
                label="Projekt"
                v-on:change="loadToplistLabels"></v-select>
            </v-col>
            <v-col md="4" sm="12">
              <v-select
                v-model="selectedToplists"
                item-text="value"
                item-value="id"
                :items="toplists"
                label="Topliste(n)"
                :disabled="$state.filters.rankingGroup === null"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text text-caption"
                  >
                    (+{{ selectedToplists.length - 1 }} weitere)
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @click="toggleToplistSelection"
                  >
                    <v-list-item-action>
                      <v-icon :color="selectedToplists.length > 0 ? 'indigo darken-4' : ''">
                        {{ toggleIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Alles togglen
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>
            <v-col md="2" sm="10">
              <v-select
                v-model="$state.filters.period"
                :disabled="$state.filters.rankingGroup === null"
                :items="periods"
                item-text="name"
                item-value="name"
                label="Jahrgang"></v-select>
            </v-col>
            <v-col md="2" sm="10">
              <v-select
                v-model="$state.filters.notesPeriod"
                :disabled="selectedExportType !== 'research'"
                :items="periods"
                item-text="name"
                item-value="name"
                label="Noten Jahrgang"></v-select>
            </v-col>
            <v-col md="4" sm="12">
              <v-select
                v-model="selectedExportType"
                v-on:change="loadDictionaries"
                :items="exportTypes"
                item-text="label"
                item-value="endpoint"
                label="Exporttyp"></v-select>
            </v-col>
            <v-col md="4" sm="12">
              <v-select
                v-model="selectedMarketingWavesFilter"
                :disabled="$state.filters.rankingGroup !== 'EAR'"
                :items="marketingWavesFilter"
                item-text="value"
                item-value="value"
                label="Wellenfilter (Regioärzte)"></v-select>
            </v-col>
            <v-col md="4" sm="12">
              <v-select
                v-model="selectedDictionary"
                :disabled="selectedExportType !== 'easycatalog'"
                :items="dictionariesOptions"
                item-text="label"
                item-value="id"
                label="Dictionary"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-on:click="requestExport"
            :disabled="missingInput"
            :loading="exportLoading"
            class="primary">
            <v-icon class="mr-1">mdi-download</v-icon>
            exportieren
          </v-btn>
        </v-card-actions>
      </v-card>

      <h1>Globaler Export</h1>
      <div class="mb-5">
        <p>
          Hier können ganze Verzeichnisse unabhängig von Projekt oder Auszeichnung heruntergeladen werden.<br>
          Dies ist insbesondere für Matching-Zwecke nützlich.
        </p>
        <v-btn class="primary mr-5" :disabled="exportInProgress" v-on:click="requestCompleteExport('account')"><v-icon class="mr-1">mdi-download</v-icon>Alle Accounts</v-btn>
        <v-btn class="primary mr-5" :disabled="exportInProgress" v-on:click="requestCompleteExport('contact')"><v-icon class="mr-1">mdi-download</v-icon>Alle Contacts</v-btn>

        <v-btn v-if="this.exportInProgress === true" class="primary" v-on:click="checkExportGenerationStatus"><v-icon class="mr-1">mdi-refresh</v-icon>Refresh</v-btn><br>
        <p v-if="this.exportInProgress === true" class="mt-2">
          <v-progress-circular size="15" :width="2" indeterminate></v-progress-circular>
          Ein globaler Export wird gerade erstellt.
        </p>
      </div>

      <h1>Generierte Exporte:</h1>
      <div>
        <p>Hier kann eine Auflistung der bisher generierten Exporte geholt werden mit der Möglichkeit diese erneut herunterzuladen.</p>
        <v-btn class="primary mb-5" :loading="loading" v-on:click="loadExportList()">Liste laden</v-btn>
        <v-data-table
          :headers="exportListHeaders"
          :items="exportList"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:item.modified="{ item }">
            <span>{{ new Date(item.modified * 1000).toLocaleString() }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn class="primary" v-on:click="startDownload(item.pathname)"><v-icon class="mr-1">mdi-download</v-icon>download</v-btn>
          </template>
        </v-data-table>
      </div>

      <!-- Error Dialog -->
      <v-dialog v-model="errorDialog" max-width="400">
        <v-card class="pa-4">
          <v-card-text>
            <h4 class="amber--text my-4">Achtung</h4>
            <p>
              Der Datensatz kann nicht verarbeitet werden. <br>
              Es ist ein Fehler aufgetreten.
            </p>
            <p v-if="errorMessage !== null">
              Es sieht aus, als wären keine Daten für den Export vorhanden.
            </p>
            <p v-if="errorMessage !== null" class="mb-0 small grey--text text--darken-2">
              Original-Nachricht: {{ errorMessage }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-on:click="errorDialog = false"
              color="blue darken-3"
              class="px-2"
              block
              outlined>
              Verstanden
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    errorMessage: null,
    errorDialog: false,
    exportData: [],
    exportLoading: false,
    exportList: [],
    exportListHeaders: [
      { text: 'Name', value: 'filename' },
      { text: 'Datum', value: 'modified' },
      { text: 'Download', value: 'actions', sortable: false }
    ],
    exportInProgress: false,
    downloadSuccessful: false,
    initiated: false,
    loading: false,
    periods: [],
    rankingGroups: [],
    toplists: [],
    exportTypes: [
      {
        endpoint: 'easycatalog',
        label: 'EasyCatalog'
      },
      {
        endpoint: 'research',
        label: 'Research'
      },
      {
        endpoint: 'sgh',
        label: 'Stiftung Gesundheit (SGH)'
      },
      {
        endpoint: 'api',
        label: 'Api-Import Export'
      }
    ],
    dictionaries: [],
    marketingWavesFilter: [
      {
        value: null
      },
      {
        value: '1'
      },
      {
        value: '2'
      },
      {
        value: '3'
      },
      {
        value: '4'
      },
      {
        value: '5'
      },
      {
        value: '6'
      },
      {
        value: '7'
      },
      {
        value: 'X'
      },
      {
        value: 'X1'
      },
      {
        value: 'X2'
      },
      {
        value: 'X3'
      },
      {
        value: 'X4'
      },
      {
        value: 'X5'
      },
      {
        value: 'X6'
      },
      {
        value: 'X7'
      }
    ],
    selectedDictionary: null,
    selectedExportType: null,
    selectedMarketingWavesFilter: null,
    selectedToplists: []
  }),
  created: async function () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    await this.checkExportGenerationStatus()

    // Reset filters.
    this.$state.filters.rankingGroup = null
    this.$state.filters.toplist = null

    const calls = []

    // promise of loading ranking groups from backend
    const rankingGroupPromise = this.$http.get('/ranking_group/list')
      .then((response) => {
        this.rankingGroups = this.rankingGroups.concat(response.data)
      })
    calls.push(rankingGroupPromise)

    // promise of loading periods from backend
    const periodPromise = this.$http.get('/period/list')
      .then((response) => {
        this.periods = response.data
      })
    calls.push(periodPromise)

    // Collects all promises
    await Promise.all(calls)
    this.initiated = true
  },
  methods: {
    async checkExportGenerationStatus () {
      const response = await this.$http.get('/export/generation_status')
      this.exportInProgress = response.data
    },
    async loadExportList () {
      this.loading = true
      const response = await this.$http.get('/export/list')
      this.exportList = response.data
      this.loading = false
    },
    loadToplistLabels: function () {
      this.loading = true
      const urlParams = new URLSearchParams()
      // checks for a selected ranking group
      if (this.$state.filters.rankingGroup !== null) {
        urlParams.append('rgShortCode', this.$state.filters.rankingGroup)
      }
      // promise of loading toplists from backend
      return this.$http.get('/toplist_label/list?' + urlParams.toString())
        .then((response) => {
          this.toplists = response.data.list
          this.loading = false
        })
    },
    loadDictionaries: async function () {
      if (this.dictionaries.length > 0 || this.selectedExportType !== 'easycatalog') {
        return
      }

      const response = await this.$http.get('/dictionary')
      this.dictionaries = response.data
    },
    toggleToplistSelection () {
      this.$nextTick(() => {
        if (this.selectedAllToplists) {
          this.selectedToplists = []
        } else {
          this.selectedToplists = []
          this.toplists.forEach(element => this.selectedToplists.push(element.id))
        }
        console.log(this.selectedToplists)
      })
    },
    async requestExport () {
      this.exportLoading = true

      let uri = '/export/' + this.selectedExportType
      const payload = {
        rankingGroupShortCode: this.$state.filters.rankingGroup,
        periodName: this.$state.filters.period,
        toplistLabelIds: this.selectedToplists,
        dictionaryId: this.selectedDictionary,
        notesPeriodName: this.$state.filters.notesPeriod
      }

      if (this.selectedMarketingWavesFilter !== null) {
        uri += '?ear_marketing_wave=' + this.selectedMarketingWavesFilter
      }

      await this.$http.post(uri, payload)
        .then((response) => {
          this.exportData = response.data
        })
        .catch((e) => {
          this.exportLoading = false
          if (e.response.data.message !== null) {
            this.errorMessage = e.response.data.message
            this.errorDialog = true
          }
          throw e
        })

      if (Object.prototype.hasOwnProperty.call(this.exportData, 'fileUri')) {
        await this.startDownload()
        this.exportLoading = false
      }
    },
    async requestCompleteExport (entityType) {
      this.exportInProgress = true

      await this.$http.post('/export/all/' + entityType)
        .then((response) => {
          this.exportData = response.data
        })
        .catch((e) => {
          if (e.response.data.message !== null) {
            this.errorMessage = e.response.data.message
            this.errorDialog = true
          }
          throw e
        })
    },
    async startDownload (fileUri = null) {
      console.log('Starting the download...')

      if (fileUri === null) {
        fileUri = this.exportData.fileUri
      }

      const downloadRequest = await this.$http.get('download/' + fileUri)
      // needed because text file is js generated (arbitrary js variable to file only on client side possible)
      const blob = new Blob([downloadRequest.data], { type: downloadRequest.headers['content-type'] })
      // makes download work on all browsers
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'Export-' + this.$state.filters.rankingGroup + '-' + this.$state.filters.period + '.csv')
      } else {
        const elem = window.document.createElement('a')
        elem.href = window.URL.createObjectURL(blob)
        elem.download = fileUri
        document.body.appendChild(elem)
        elem.click()
        document.body.removeChild(elem)
        this.$state.filters.rankingGroup = null
        this.selectedToplists = []
        this.$state.filters.period = null
        this.$state.filters.notesPeriod = null
        this.selectedExportType = null
        this.selectedMarketingWavesFilter = null
      }
      this.downloadSuccessful = true
    }
  },
  computed: {
    missingInput: function () {
      return this.$state.filters.rankingGroup === null || this.selectedExportType === null
    },
    selectedAllToplists () {
      return this.selectedToplists.length === this.toplists.length
    },
    selectedSomeToplists () {
      return this.selectedToplists.length > 0 && !this.selectedAllToplists
    },
    toggleIcon () {
      if (this.selectedAllToplists) return 'mdi-close-box'
      if (this.selectedSomeToplists) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    dictionariesOptions () {
      return [{ label: 'Keine', id: null }].concat(this.dictionaries)
    }
  }
}
</script>
