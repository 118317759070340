<template>
  <v-container fluid class="relative header-space container">
    <div class="profile" v-if="initialized">
      <h1>Analyse</h1>
      <p class="explanation body-2">
        Beim Upload wird eine Plausibilitätsprüfung vorgenommen. Hier werden nur kontextunabhängige Checks ausgeführt.
      </p>
      <v-row>
        <v-col lg="7">
          <div class="failure">
            <h3 class="red--text text--darken-1">
              <v-icon color="red darken-1">mdi-close-circle-outline</v-icon>
              Fehler
            </h3>
            <p class="explanation body-2">
              Tauchen bei der Plausibilitätsprüfung Fehler auf, ist eine Weiterverarbeitung <span class="bold">nicht</span> möglich. <br>
              Bitte die Datenlieferung prüfen.
            </p>
            <div>
              <BadWordHeaderAnalyse v-if="profile.BadWordHeaderCheck === false" :profile="profile.BadWordHeaderCheck" :sub="profile['BadWordHeaderCheck-sub']" :header="profile['HeaderItemCount-sub']"></BadWordHeaderAnalyse>
              <HeaderCountMatchesRowCountAnalyse v-if="profile.HeaderCountMatchesRowCount === false" :profile="profile.HeaderCountMatchesRowCount" :sub="profile['HeaderCountMatchesRowCount-sub']" ></HeaderCountMatchesRowCountAnalyse>
              <HeaderContainsLeadingOrTrailingSpace v-if="profile.HeaderContainsLeadingOrTrailingSpace === true" :profile="profile.HeaderContainsLeadingOrTrailingSpace" :sub="profile['HeaderContainsLeadingOrTrailingSpace-sub']" :header="profile['HeaderContainsLeadingOrTrailingSpace-sub']"></HeaderContainsLeadingOrTrailingSpace>
              <RowCountAnalyse v-if="profile.RowCount === false" :profile="profile.RowCount" :sub="profile['RowCount-sub']" ></RowCountAnalyse>
              <ColumnCountAnalyse v-if="profile.ColumnCount === false" :profile="profile.ColumnCount" :sub="profile['ColumnCount-sub']" :header="profile['HeaderItemCount-sub']"></ColumnCountAnalyse>
              <EncodingConsistencyAnalyse v-if="profile.EncodingConsistencyCheck === false" :profile="profile.EncodingConsistencyCheck" :sub="profile['EncodingConsistencyCheck-sub']" ></EncodingConsistencyAnalyse>
              <DateConsistencyAnalyse v-if="dateConsistencyOk === false" :profile="profile"></DateConsistencyAnalyse>
            </div>
          </div>

          <div class="attention">
            <h3 class="amber--text pt-4">
              <v-icon color="amber">mdi-alert-outline</v-icon>
              Warnung
            </h3>
            <p class="explanation body-2">
              Tauchen bei der Plausibilitätsprüfung  Warnungen auf, ist eine Weiterverarbeitung möglich,<br>
              aber die Datenlieferung sollte sicherhaltshalber geprüft werden.
            </p>
            <div>
              <LengthOfWordAnalyse v-if="profile.LengthOfWordCheck === false" :profile="profile.LengthOfWordCheck" :sub="profile['LengthOfWordCheck-sub']" :header="profile['HeaderItemCount-sub']"></LengthOfWordAnalyse>
              <BadSymbolAnalyse v-if="profile.BadSymbolCheck === false" :profile="profile.BadSymbolCheck" :sub="profile['BadSymbolCheck-sub']" :header="profile['HeaderItemCount-sub']"></BadSymbolAnalyse>
              <BadWordContentAnalyse v-if="profile.BadWordContentCheck === false" :profile="profile.BadWordContentCheck" :sub="profile['BadWordContentCheck-sub']" :header="profile['HeaderItemCount-sub']"></BadWordContentAnalyse>
              <ZipCodeConsistencyAnalyse v-if="profile.ZipCodeConsistencyCheck === false" :profile="profile.ZipCodeConsistencyCheck" :sub="profile['ZipCodeConsistencyCheck-sub']" :header="profile['HeaderItemCount-sub']"></ZipCodeConsistencyAnalyse>
              <ConsistencyAnalyse v-if="typeConsistencyOk === false" :profile="profile"></ConsistencyAnalyse>
            </div>
          </div>

          <div class="good">
            <h3 class="light-green--text pt-4">
              <v-icon color="light-green">mdi-checkbox-marked-circle-outline</v-icon>
              Richtig
            </h3>
            <div>
              <BadWordHeaderAnalyse v-if="profile.BadWordHeaderCheck === true" :profile="profile.BadWordHeaderCheck" :sub="profile['BadWordHeaderCheck-sub']" :header="profile['HeaderItemCount-sub']"></BadWordHeaderAnalyse>
              <LengthOfWordAnalyse v-if="profile.LengthOfWordCheck === true" :profile="profile.LengthOfWordCheck" :sub="profile['LengthOfWordCheck-sub']" :header="profile['HeaderItemCount-sub']"></LengthOfWordAnalyse>
              <BadSymbolAnalyse v-if="profile.BadSymbolCheck === true" :profile="profile.BadSymbolCheck" :sub="profile['BadSymbolCheck-sub']" :header="profile['HeaderItemCount-sub']"></BadSymbolAnalyse>
              <BadWordContentAnalyse v-if="profile.BadWordContentCheck === true" :profile="profile.BadWordContentCheck" :sub="profile['BadWordContentCheck-sub']" :header="profile['HeaderItemCount-sub']"></BadWordContentAnalyse>
              <HeaderContainsLeadingOrTrailingSpace v-if="profile.HeaderContainsLeadingOrTrailingSpace === false" :profile="profile.HeaderContainsLeadingOrTrailingSpace" :sub="profile['HeaderContainsLeadingOrTrailingSpace-sub']" :header="profile['HeaderContainsLeadingOrTrailingSpace-sub']"></HeaderContainsLeadingOrTrailingSpace>
              <HeaderCountMatchesRowCountAnalyse v-if="profile.HeaderCountMatchesRowCount === true" :profile="profile.HeaderCountMatchesRowCount" :sub="profile['HeaderCountMatchesRowCount-sub']" ></HeaderCountMatchesRowCountAnalyse>
              <RowCountAnalyse v-if="profile.RowCount === true" :profile="profile.RowCount" :sub="profile['RowCount-sub']" ></RowCountAnalyse>
              <ColumnCountAnalyse v-if="profile.ColumnCount === true" :profile="profile.ColumnCount" :sub="profile['ColumnCount-sub']" :header="profile['HeaderItemCount-sub']"></ColumnCountAnalyse>
              <EncodingConsistencyAnalyse v-if="profile.EncodingConsistencyCheck === true" :profile="profile.EncodingConsistencyCheck" :sub="profile['EncodingConsistencyCheck-sub']" ></EncodingConsistencyAnalyse>
              <ConsistencyAnalyse v-if="typeConsistencyOk === true" :profile="profile"></ConsistencyAnalyse>
              <DateConsistencyAnalyse v-if="dateConsistencyOk === true" :profile="profile"></DateConsistencyAnalyse>
              <ZipCodeConsistencyAnalyse v-if="profile.ZipCodeConsistencyCheck === true" :profile="profile.ZipCodeConsistencyCheck" :sub="profile['ZipCodeConsistencyCheck-sub']" :header="profile['HeaderItemCount-sub']"></ZipCodeConsistencyAnalyse>
            </div>
          </div>
        </v-col>

        <v-col lg="5">
          <h3 class="pb-1 grey--text">
            <v-icon color="grey">mdi-information-outline</v-icon>
            Fakten
          </h3>
          <v-card class="px-6 pt-2 pb-0">
            <div class="mt-2 mb-4 pb-2">
              <h5><span class="display-1">{{ profile.EntryCount }} </span>Reiheneinträge</h5>
              <p class="explanation body-2 pt-3">Anzahl der Daten-Reihen in der Lieferung.</p>
            </div>

            <div class="mb-4 pb-2">
              <h5><span class="display-1">{{ profile.HeaderItemCount }} </span>Tabellenköpfe</h5>
              <p class="explanation body-2 pt-3">Anzahl der Einträge im Tabellenkopf.</p>
            </div>

            <div class="mb-4 pb-2">
              <h5>Kardinalitäten</h5>
              <p class="explanation body-2 pt-3">
                Der Kardinalitäts-Wert gibt die Anzahl der unterschiedlichen Elemente innerhalb einer Spalte an.
                Leere Felder werden nicht mitgezählt.
              </p>
              <v-simple-table class="mb-8">
                <tr>
                  <td v-for ="colName in profile['HeaderItemCount-sub']" class="column"> {{ colName }}</td>
                </tr>
                <tr>
                  <td v-for="value in profile.Cardinality" class="column monospace">{{ value }}</td>
                </tr>
              </v-simple-table>
            </div>
          </v-card>

          <v-btn v-on:click="dryRun" class="mt-8" outlined block color="blue darken-3" :disabled="hardError">
            Import prüfen
          </v-btn>
          <p class="explanation small mt-2" v-if="hardError===false && noHardErrorsOk===false">
            Die Plausibilitätsprüfung hat Daten erkannt, die möglicherweise nicht einwandfrei sind. Es wird empfohlen, die Datenlieferung auf die entsprechenden Stellen zu prüfen.
            Es wurde kein Fehler gefunden, der die Verarbeitung verhindert.
          </p>
          <p class="explanation small mt-2" v-if="hardError">
            Die Plausibilitätsprüfung hat Fehler erkannt, die nicht zu verarbeiten sind.
          </p>
          <v-btn v-if="hardError" class="px-0" :to="'/upload'" text>Korrigierte Datenlieferung hochladen</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import BadWordHeaderAnalyse from '../components/analyse/BadWordHeaderAnalyse'
import LengthOfWordAnalyse from '../components/analyse/LengthOfWordAnalyse'
import BadSymbolAnalyse from '../components/analyse/BadSymbolAnalyse'
import BadWordContentAnalyse from '../components/analyse/BadWordContentAnalyse'
import HeaderCountMatchesRowCountAnalyse from '../components/analyse/HeaderCountMatchesRowCountAnalyse'
import RowCountAnalyse from '../components/analyse/RowCountAnalyse'
import ColumnCountAnalyse from '../components/analyse/ColumnCountAnalyse'
import EncodingConsistencyAnalyse from '../components/analyse/EncodingConsistencyAnalyse'
import ConsistencyAnalyse from '../components/analyse/ConsistencyAnalyse'
import DateConsistencyAnalyse from '../components/analyse/DateConsistencyAnalyse'
import ZipCodeConsistencyAnalyse from '../components/analyse/ZipCodeConsistencyAnalyse'
import HeaderContainsLeadingOrTrailingSpace from '../components/analyse/HeaderContainsLeadingOrTrailingSpace'

export default {
  components: {
    HeaderContainsLeadingOrTrailingSpace,
    BadWordHeaderAnalyse,
    LengthOfWordAnalyse,
    BadSymbolAnalyse,
    BadWordContentAnalyse,
    HeaderCountMatchesRowCountAnalyse,
    RowCountAnalyse,
    ColumnCountAnalyse,
    EncodingConsistencyAnalyse,
    ConsistencyAnalyse,
    DateConsistencyAnalyse,
    ZipCodeConsistencyAnalyse
  },
  data: () => ({
    initialized: false,
    sampleId: null,
    stopOnError: false,
    profile: {}
  }),
  computed: {
    typeConsistencyOk: function () {
      return this.profile.StringConsistencyCheck && this.profile.IntegerConsistencyCheck && this.profile.FloatConsistencyCheck
    },
    dateConsistencyOk: function () {
      return this.profile.DateFormatWithDotConsistencyCheck && this.profile.DateFormatWithUnderscoreConsistencyCheck
    },
    noHardErrorsOk: function () {
      return this.profile.BadWordHeaderCheck &&
          this.profile.HeaderCountMatchesRowCount &&
          this.profile.RowCount &&
          this.profile.ColumnCount &&
          this.profile.EncodingConsistencyCheck &&
          this.typeConsistencyOk &&
          this.dateConsistencyOk
    },
    hardError: function () {
      if (this.profile.BadWordHeaderCheck === false ||
          this.profile.HeaderCountMatchesRowCount === false ||
          this.profile.HeaderContainsLeadingOrTrailingSpace === true ||
          this.profile.RowCount === false ||
          this.profile.EncodingConsistencyCheck === false ||
          this.dateConsistencyOk === false) {
        return true
      }
      return false
    }
  },
  created: async function () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      this.$router.push('/')
      return
    }
    this.sampleId = this.$route.params.sampleId
    const response = await this.$http.get('/profile/' + this.sampleId)
    this.profile = response.data
    this.initialized = true
    console.log(this.profile)
  },
  methods: {
    dryRun: async function () {
      await this.$router.push({ path: '/import/' + this.sampleId })
    }
  }
}
</script>

<style lang="scss">
  .table-bg {
    background-color: #fafafa;
  }
  td {
    padding-left: 10px;
    background-color: #fafafa;
  }
  td.column {
    font-size: 0.75em;
    min-width: 100px;
    text-align: center;
  }
  td.column.monospace {
    font-size: 1em;
    text-align: center;
  }
  .v-data-table td {
    padding-left: 10px;
    padding-right: 10px;
  }
  .columnBadSymbol {
    min-width: 200px;
  }
  .explanation {
    color: #616161;
  }
  .v-expansion-panels {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .theme--light.v-data-table thead tr:last-child th {
    border-bottom: transparent;
    border-bottom: 1px solid lightgrey;
  }
  .theme--light.v-data-table thead th {
    border-bottom: transparent;
    border-bottom: 1px solid lightgrey;
  }
  .row-count {
    width: 300px;
  }
</style>
