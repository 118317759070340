<template>
  <div class="relative mb-10">
    <p class="overline blue--text label mb-0 text-right absolute">Produkt</p>
    <v-card class="pa-4 mb-2 pr-12">
      <div class="absolute context-menu right mr-2 ml-auto" style="top: 8px;" v-if="!inspectionMode">
        <Approve
          v-if="!product.isApproved"
          targetClass="product"
          :id="product.id"></Approve>
        <DetailContext targetClass="product" :id="product.id"></DetailContext>
      </div>

      <InputSwitch
        label="Markenname"
        v-model="product.brand"
        counter="255"
        :entryId="product.id"
        targetClass="product"
        field="brand"
        :readonly="readOnlyMode"
        :inspectionMode="inspectionMode"
        :loadChanges="loadChanges"
      ></InputSwitch>

      <InputSwitch
        label="Modellname"
        v-model="product.label"
        counter="255"
        required
        :entryId="product.id"
        targetClass="product"
        field="label"
        :readonly="readOnlyMode"
        :inspectionMode="inspectionMode"
        :loadChanges="loadChanges"
      ></InputSwitch>

      <InputSwitch
        v-model="product.modelYear"
        :counter="4"
        label="Modelljahr"
        :entryId="product.id"
        targetClass="product"
        field="modelYear"
        :readonly="readOnlyMode"
        :inspectionMode="inspectionMode"
        :loadChanges="loadChanges"
      ></InputSwitch>

      <template v-for="field in oldDynamicFields">
        <InputSwitch
          v-bind:key="field.label.label"
          :label="field.label.label"
          v-model="field.value"
          :readonly="readOnlyMode"
          :entryId="product.id"
          targetClass="product"
          :field="field.label.name"
          :inspectionMode="inspectionMode"
          :selectables="getSelectables(field)"
        ></InputSwitch>
      </template>

      <template v-for="field in newDynamicFields">
        <InputSwitch
          v-bind:key="field.label.name"
          :label="field.label.name"
          v-model="field.value"
          :entryId="product.id"
          targetClass="product"
          :readonly="readOnlyMode"
          :field="field.label.name"
          :inspectionMode="inspectionMode"
          :selectables="getSelectables(field)"
        ></InputSwitch>
      </template>

      <v-btn
        v-if="!showSupplier"
        v-on:click="showSupplier = true"
        class="px-0 mx-0 mb-0 grey--text text--darken-2 mono"
        elevation="0"
        color="white">
        Lieferanten <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
      <v-btn
        v-if="showSupplier"
        v-on:click="showSupplier = false"
        class="px-0 mx-0 mb-0 grey--text text--darken-2 mono"
        elevation="0"
        color="white">
        Lieferanten <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
      <div v-show="showSupplier" class="mb-0 pt-3">
        <p v-for="item in product.supplierIds" :key="item.value + item.scope" class="grey--text text--darken-2 small">
          <span class="mb-1">{{ item.supplier.name }} ({{ item.scope }}): </span>
          <span class="mono mb-0">{{ item.value }}</span>
        </p>
      </div>

    </v-card>

  </div>
</template>

<script>
import Approve from './Approve'
import DetailContext from './DetailContext'
import InputSwitch from './InputSwitch'
import { filter } from 'lodash'

export default {
  props: {
    product: Object,
    readonly: {
      type: Boolean,
      default: false
    },
    inspectionMode: {
      default: false
    },
    rootType: {
      default: ''
    },
    loadChanges: {
      type: Boolean,
      default: true
    },
    main: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Approve,
    DetailContext,
    InputSwitch
  },
  data: () => ({
    showMoreInfoToggle: false,
    showSupplier: false,
    changes: {}
  }),
  created () {
    this.$state.addToRegistry(this.product)
    if (this.loadChanges) {
      this.changes = this.$changeService.getPendingChangesByTarget(this.product.id, 'product')
    }
  },
  watch: {
    changes: function () {
      this.doPolyfill()
    }
  },
  methods: {
    doPolyfill () {
      // Make sure dynamic fields that do not exist yet on the entity wll be visible if they have changes.
      // Check polyfill cache.
      if (this.untrackedFields !== null) {
        return
      }
      // No changes will skip the whole process.
      if (Object.keys(this.changes).length === 0) {
        return
      }
      // Collect those MDL attributes that are known already to the product.
      const trackedAttributes = []
      this.product.dynamicFields.forEach(function (mdf) {
        if (Object.prototype.hasOwnProperty.call(mdf, 'label')) {
          trackedAttributes.push(mdf.label.name)
        }
      })
      // Get all attributes that we have changes for.
      const allAttributes = Object.keys(this.changes)
      // Throw away attributes, that are not MDLs.
      const attributes = filter(allAttributes, (attribute) => {
        const nonMDL = ['label', 'modelYear']
        return !nonMDL.includes(attribute)
      })
      // Create cache to collect polyfilled attributes.
      this.untrackedFields = []
      // Go through all change attributes.
      attributes.forEach((attribute) => {
        // The attribute may not be tracked yet.
        if (!trackedAttributes.includes(attribute)) {
          this.untrackedFields.push(attribute)
          // Polyfill MDL
          const polyfill = { value: null, polyfill: true, label: { name: attribute } }
          this.product.dynamicFields.push(polyfill)
        }
      })
    },
    getSelectables: function (field) {
      if (field.label.type === 'TYPE_BOOLEAN') {
        const selectable = [
          {
            text: 'true',
            value: true
          },
          {
            text: 'false',
            value: false
          }
        ]

        if (field.label.nullable === true) {
          selectable.push({
            text: 'k.A.',
            value: null
          })
        }

        return selectable
      }

      return null
    }
  },
  computed: {
    readOnlyMode: function () {
      if (this.readonly === true) {
        return true
      }
      if (this.product.isApproved === false) {
        return true
      }
      return false
    },
    showMoreInfoForChanges: function () {
      return Object.keys(this.changes).length !== 0
    },
    showMoreInfo: function () {
      if (this.showMoreInfoToggle) {
        return true
      }
      if (this.showMoreInfoForChanges) {
        return true
      }
      return false
    },
    newDynamicFields: function () {
      return filter(this.product.dynamicFields, (field) => {
        return typeof field.polyfill !== 'undefined'
      })
    },
    oldDynamicFields: function () {
      // Show only fields that are not polyfilled
      return filter(this.product.dynamicFields, (field) => {
        return typeof field.polyfill === 'undefined'
      })
    }
  }
}
</script>
