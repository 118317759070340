<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row>
        <v-col sm="12">
          <h1>Taxonomie</h1>
          <p>Eine Übersicht der Taxonomie Begriffe.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-data-table
                :headers="headers"
                :items="taxonomies"
                :items-per-page="50"
                class="elevation-1"
                sort-by="name">
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="pr-1">mdi-plus</v-icon> Begriff hinzufügen
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Bearbeiten</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container fluid>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                                <v-text-field v-if="editedItem.parent !== null && editedItem.parent.hasOwnProperty('id') === true" hint="Die ID des Parent-Begriffs." v-model="editedItem.parent.id" label="Parent"></v-text-field>
                                <v-text-field v-else hint="Die ID des Parent-Begriffs." v-model="editedItem.parent" label="Parent"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="close">
                            abbrechen
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="save">
                            <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
                            <span v-if="loading === false">Speichern</span>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="headline">Achtung!</v-card-title>
                        <v-card-text>Taxonomy Begriff "{{ editedItem.name }}" wirklich löschen?</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">abbrechen</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                            <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
                            <span v-if="loading === false">Löschen</span>
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    taxonomies: [],
    loading: false,
    headers: [
      { text: 'Id', value: 'id', sortable: false },
      { text: 'Name', value: 'name' },
      { text: 'Parent', value: 'parent.name' },
      { text: 'Created At', value: 'createdAt' },
      { text: 'Updated At', value: 'updatedAt' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      name: '',
      parent: null
    },
    defaultItem: {
      name: '',
      parent: null
    }
  }),
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    getList: async function () {
      const response = await this.$http.get('/taxonomyterm')
      this.taxonomies = response.data
    },
    editItem (item) {
      this.editedIndex = this.taxonomies.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItem (item) {
      this.editedIndex = this.taxonomies.indexOf(item)
      this.editedItem = Object.assign({}, item)
      console.log(this.editedItem)
      this.dialogDelete = true
    },
    deleteItemConfirm: async function () {
      this.loading = true
      await this.$http.delete('/taxonomyterm/delete/' + this.editedItem.id)
      this.loading = false
      this.closeDelete()
      await this.getList()
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save: async function () {
      if (this.editedIndex > -1) {
        this.loading = true
        const payload = { name: this.editedItem.name }
        if (this.editedItem.parent !== null) {
          if (Object.prototype.hasOwnProperty.call(this.editedItem, 'id') === true) {
            payload.parentId = this.editedItem.parent.id
          } else {
            payload.parentId = this.editedItem.parent
          }
        }
        await this.$http.patch('/taxonomyterm/update/' + this.editedItem.id, payload)
        this.loading = false
      } else {
        // Create new.
        this.loading = true
        const payload = { name: this.editedItem.name }
        if (this.editedItem.parent !== null) {
          if (Object.prototype.hasOwnProperty.call(this.editedItem, 'id') === true) {
            payload.parentId = this.editedItem.parent.id
          } else {
            payload.parentId = this.editedItem.parent
          }
        }
        await this.$http.post('/taxonomyterm', payload)
        this.loading = false
      }
      this.close()
      await this.getList()
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    await this.getList()
    this.initiated = true
  }
}
</script>
