<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row>
        <v-col sm="12">
          <h1>Ranking Groups</h1>
          <p>Eine Übersicht der Ranking Groups / Projekte.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-data-table
                :headers="headers"
                :items="rankingGroups"
                :items-per-page="50"
                class="elevation-1"
                sort-by="name">
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on:click="createItem"
                        >
                          <v-icon class="pr-1">mdi-plus</v-icon> Ranking Group hinzufügen
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ editing === true ? 'Bearbeiten' : 'Erstellen' }}</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container fluid>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field v-model="editedItem.salesforceName" label="Name (Salesforce)"></v-text-field>
                              </v-col>
                              <v-col cols="12" v-if="editing === false">
                                <v-text-field v-model="editedItem.shortCode" label="Short Code"></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-select
                                  v-model="editedItem.publicationSector"
                                  item-text="label"
                                  item-value="value"
                                  :items="publicationSectors"
                                  label="Publication Sector"></v-select>
                              </v-col>
                              <v-col cols="12">
                                <v-select
                                  v-model="editedItem.rootType"
                                  item-text="label"
                                  item-value="value"
                                  :items="rootTypes"
                                  label="Root Type"></v-select>
                              </v-col>
                              <v-col cols="4">
                                <p>SF Manage<br>Awards</p>
                                <v-switch
                                  v-model="editedItem.salesforceManageAwards"
                                  inset
                                ></v-switch>
                              </v-col>
                              <v-col cols="4">
                                <p>SF Manage<br>Accounts</p>
                                <v-switch
                                  v-model="editedItem.salesforceManageAccounts"
                                  inset
                                ></v-switch>
                              </v-col>
                              <v-col cols="4">
                                <p>SF Manage<br>Contacts</p>
                                <v-switch
                                  v-model="editedItem.salesforceManageContacts"
                                  inset
                                ></v-switch>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="close">
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="save">
                            <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
                            <span v-if="loading === false">Save</span>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template #item.salesforceManageAwards="{ item }">
                  <v-icon color="green" v-if="item.salesforceManageAwards === true">mdi-check</v-icon>
                  <v-icon color="red" v-else>mdi-close</v-icon>
                </template>
                <template #item.salesforceManageAccounts="{ item }">
                  <v-icon color="green" v-if="item.salesforceManageAccounts === true">mdi-check</v-icon>
                  <v-icon color="red" v-else>mdi-close</v-icon>
                </template>
                <template #item.salesforceManageContacts="{ item }">
                  <v-icon color="green" v-if="item.salesforceManageContacts === true">mdi-check</v-icon>
                  <v-icon color="red" v-else>mdi-close</v-icon>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    rankingGroups: [],
    loading: false,
    headers: [
      { text: 'Id', value: 'id', sortable: false },
      { text: 'Name', value: 'name' },
      { text: 'SF Name', value: 'salesforceName' },
      { text: 'Short Code', value: 'shortCode' },
      { text: 'Root Type', value: 'rootType' },
      { text: 'Toplist Labels', value: 'toplistLabels.length' },
      { text: 'MDLs', value: 'managedDynamicLabelsCount' },
      { text: 'SF Manage Awards', value: 'salesforceManageAwards' },
      { text: 'SF Manage Accounts', value: 'salesforceManageAccounts' },
      { text: 'SF Manage Contacts', value: 'salesforceManageContacts' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    rootTypes: [
      { value: 'App\\Entity\\Account', label: 'App\\Entity\\Account' },
      { value: 'App\\Entity\\Contact', label: 'App\\Entity\\Contact' }
    ],
    publicationSectors: [
      { value: 'SECTOR_GESUNDHEIT', label: 'SECTOR_GESUNDHEIT' },
      { value: 'SECTOR_BUSINESS', label: 'SECTOR_BUSINESS' },
      { value: 'SECTOR_MOBILITY', label: 'SECTOR_MOBILITY' },
      { value: 'SECTOR_DEUTSCHLANDTEST', label: 'SECTOR_DEUTSCHLANDTEST' },
      { value: 'SECTOR_MONEY_INTERNATIONAL_ITALY', label: 'SECTOR_MONEY_INTERNATIONAL_ITALY' },
      { value: 'SECTOR_MONEY_NATIONAL', label: 'SECTOR_MONEY_NATIONAL' }
    ],
    dialog: null,
    editedIndex: -1,
    editing: false,
    editedItem: {
      name: null,
      salesforceName: null,
      rootType: null,
      shortCode: null,
      publicationSector: null,
      salesforceManageAwards: false,
      salesforceManageAccounts: false,
      salesforceManageContacts: false
    },
    defaultItem: {
      name: null,
      salesforceName: null,
      rootType: null,
      shortCode: null,
      publicationSector: null,
      salesforceManageAwards: false,
      salesforceManageAccounts: false,
      salesforceManageContacts: false
    }
  }),
  methods: {
    getList: async function () {
      const response = await this.$http.get('/ranking_group/list')
      this.rankingGroups = response.data
    },
    createItem () {
      this.editing = false
      this.dialog = true
    },
    editItem (item) {
      this.editedIndex = this.rankingGroups.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editing = true
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save: async function () {
      if (this.editedIndex > -1) {
        this.loading = true
        const payload = {
          name: this.editedItem.name,
          publicationSector: this.editedItem.publicationSector,
          salesforceName: this.editedItem.salesforceName ? this.editedItem.salesforceName : null,
          rootType: this.editedItem.rootType,
          salesforceManageAwards: this.editedItem.salesforceManageAwards,
          salesforceManageAccounts: this.editedItem.salesforceManageAccounts,
          salesforceManageContacts: this.editedItem.salesforceManageContacts
        }
        await this.$http.patch('/ranking_group/update/' + this.editedItem.id, payload)
        this.loading = false
      } else {
        // Create new.
        this.loading = true
        const payload = {
          name: this.editedItem.name,
          salesforceName: this.editedItem.salesforceName ? this.editedItem.salesforceName : null,
          shortCode: this.editedItem.shortCode,
          publicationSector: this.editedItem.publicationSector,
          rootType: this.editedItem.rootType,
          salesforceManageAwards: this.editedItem.salesforceManageAwards,
          salesforceManageAccounts: this.editedItem.salesforceManageAccounts,
          salesforceManageContacts: this.editedItem.salesforceManageContacts
        }
        await this.$http.post('/ranking_group', payload)
        this.loading = false
      }
      this.close()
      await this.getList()
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    await this.getList()
    this.initiated = true
  }
}
</script>
