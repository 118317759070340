import Vue from 'vue'

import { find, groupBy, remove } from 'lodash'

let ChangeServiceInstance

const getChangeService = () => {
  if (ChangeServiceInstance) {
    return ChangeServiceInstance
  }

  ChangeServiceInstance = new Vue({
    data: () => ({
      changes: {},
      running: {}
    }),
    methods: {
      accept: async function (c) {
        const targetId = c.target.id
        const changeId = c.id
        const attribute = c.attributeName
        const changes = this.changes[targetId][attribute]
        // Run accept query
        await this.$http.put('/change/' + changeId + '/accept')
        // Get target from registry
        const target = this.$state.getFromRegistry(targetId)
        // On target, set attribute from newValue
        if (typeof target[attribute] !== 'undefined') {
          // Case: Direct attribute
          target[attribute] = c.newValue
        } else if (typeof target.dynamicFields !== 'undefined') {
          // Case: Managed Dynamic Field
          // Try to find MDF where field.label.name equals attribute.
          const MDFToChange = find(target.dynamicFields, (mdf) => {
            return mdf.label.name === attribute
          })
          MDFToChange.value = c.newValue
        } else {
          console.log('There seems to be a problem with MDF handling in ChangeService.')
        }
        // Remove change from cache.
        remove(changes, (change) => {
          return change.id === changeId
        })
        // Remove field category from cache if now empty.
        if (changes.length === 0) {
          this.$delete(this.changes[targetId], attribute)
        }
      },
      reject: async function (c) {
        const targetId = c.target.id
        const changeId = c.id
        const attribute = c.attributeName
        const changes = this.changes[targetId][attribute]
        // Run reject query.
        await this.$http.put('/change/' + changeId + '/decline')
        // Remove change from cache.
        remove(changes, (change) => {
          return change.id === changeId
        })
        // Remove field category from cache if now empty.
        if (changes.length === 0) {
          this.$delete(this.changes[targetId], attribute)
        }
      },
      bulkAccept: async function (targetId, targetClass) {
        const promises = []
        // Get all changes, grouped by attribute.
        const groupedChanges = this.getPendingChangesByTarget(targetId, targetClass)
        // Loop through each attribute
        Object.keys(groupedChanges).forEach((attribute) => {
          const changes = groupedChanges[attribute]
          // If only one change exists, accept it.
          if (changes.length === 1) {
            const changeToAccept = changes[0]
            const promise = this.accept(changeToAccept)
            promises.push(promise)
            setTimeout(() => {}, 2000)
          }
        })
        await Promise.all(promises)
      },
      // Fetch changes for this target and make sure no multiple fetching occurs.
      hydrateTarget: async function (targetId, targetClass, carry) {
        // Check for cache hit.
        if (typeof this.changes[targetId] === 'undefined') {
          // Initialize with a given array to keep watching intact.
          if (typeof carry !== 'undefined') {
            this.$set(this.changes, targetId, carry)
          } else {
            this.$set(this.changes, targetId, {})
          }
        } else if (Object.keys(this.changes[targetId]).length > 0) {
          return this.changes[targetId]
        }
        // In case a promise is already running, return that.
        if (typeof this.running[targetId] !== 'undefined') {
          return this.running[targetId]
        }
        // Return a promise that resolves with the prepared result.
        this.running[targetId] = new Promise((resolve, reject) => {
          // Load from API
          this.$http.get('change/list/target/' + targetClass + '/' + targetId + '?pending=1').then((response) => {
            // Group result by attribute.
            const groupedChanges = groupBy(response.data, 'attributeName')
            // Push changes into existing cache.
            Object.keys(groupedChanges).forEach((attribute) => {
              this.$set(this.changes[targetId], attribute, groupedChanges[attribute])
            })
            // Unregister the running promise.
            delete this.running[targetId]
            resolve(this.changes[targetId])
          })
        })
        // Return the promise.
        return this.running[targetId]
      },
      // Get the current known state of pending changes.
      getPendingChangesByTarget: function (targetId, targetClass, force) {
        let changes = {}
        // Force rehydration.
        if (typeof force !== 'undefined' && force === true) {
          if (typeof this.changes[targetId] !== 'undefined') {
            const attributes = Object.keys(this.changes[targetId])
            attributes.forEach((attribute) => {
              this.$delete(this.changes[targetId], attribute)
            })
            changes = this.changes[targetId]
          }
        } else {
          // Check for cache hit.
          if (typeof this.changes[targetId] !== 'undefined') {
            return this.changes[targetId]
          }
        }
        // Hydrate cache.
        this.hydrateTarget(targetId, targetClass, changes)
        return changes
      },
      reset: function () {
        const targetIds = Object.keys(this.changes)
        targetIds.forEach((targetId) => {
          const attributes = Object.keys(this.changes[targetId])
          attributes.forEach((attribute) => {
            this.$delete(this.changes[targetId], attribute)
          })
          this.$delete(this.changes, targetIds)
        })
        this.changes = {}
      },
      addChanges: function (changes) {
        // Manually hydrate changes in this services cache. Useful for temporary changes in inspection.
        changes.forEach((change) => {
          const targetId = change.target.id
          const attribute = change.attributeName
          // Prepare cache.
          if (typeof this.changes[targetId] === 'undefined') {
            this.$set(this.changes, targetId, {})
          }
          if (typeof this.changes[targetId][attribute] === 'undefined') {
            this.$set(this.changes[targetId], attribute, [])
          }
          this.changes[targetId][attribute].push(change)
        })
      }
    },
    computed: {
      //
    }
  })

  return ChangeServiceInstance
}

export const ChangeService = {
  install (Vue, options) {
    Vue.prototype.$changeService = getChangeService(options)
  }
}
