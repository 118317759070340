<template>
  <v-container fluid class="relative header-space container">
    <div>
      <h1>Verwaltung</h1>
      <v-row>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/lieferanten">
            <v-img class="illu" src="/img/illu-suppliers.png"></v-img>
            <v-card-title class="black--text">Lieferanten</v-card-title>
          </v-card>
        </v-col>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/benutzer">
            <v-img class="illu" src="/img/illu-users.png"></v-img>
            <v-card-title class="black--text">Benutzer</v-card-title>
          </v-card>
        </v-col>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/toplisten">
            <v-img class="illu" src="/img/illu-toplists.png"></v-img>
            <v-card-title class="black--text">Toplisten</v-card-title>
          </v-card>
        </v-col>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/dynamische-felder">
            <v-img class="illu" src="/img/illu-mdl.png"></v-img>
            <v-card-title class="black--text">Dynamische Felder</v-card-title>
          </v-card>
        </v-col>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/ranking-groups">
            <v-img class="illu" src="/img/illu-ranking.png"></v-img>
            <v-card-title class="black--text">Ranking Groups</v-card-title>
          </v-card>
        </v-col>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/translation">
            <v-img class="illu" src="/img/illu-translation.png"></v-img>
            <v-card-title class="black--text">Translation</v-card-title>
          </v-card>
        </v-col>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/mapping">
            <v-img class="illu" src="/img/illu-mapping.png"></v-img>
            <v-card-title class="black--text">Mapping</v-card-title>
          </v-card>
        </v-col>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/samples">
            <v-img class="illu" src="/img/illu-samples.png"></v-img>
            <v-card-title class="black--text">Samples</v-card-title>
          </v-card>
        </v-col>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/taxonomy">
            <v-img class="illu" src="/img/illu-taxonomy.png"></v-img>
            <v-card-title class="black--text">Taxonomie</v-card-title>
          </v-card>
        </v-col>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/geodaten-abgleich">
            <v-img class="illu" src="/img/illu-geodata.png"></v-img>
            <v-card-title class="black--text">Geo-Daten Abgleich</v-card-title>
          </v-card>
        </v-col>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/salesforce-sync">
            <v-img class="illu" src="/img/illu-salesforce.png"></v-img>
            <v-card-title class="black--text">Salesforce</v-card-title>
          </v-card>
        </v-col>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/elasticsearch">
            <v-img class="illu" src="/img/illu-elasticsearch.png"></v-img>
            <v-card-title class="black--text">Elasticsearch</v-card-title>
          </v-card>
        </v-col>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/periods">
            <v-img class="illu" src="/img/illu-periods.png"></v-img>
            <v-card-title class="black--text">Periods</v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <h1>Admin Area</h1>
      <v-row>
        <v-col md="3" class="mb-6">
          <v-card to="/verwaltung/system">
            <v-img class="illu" src="/img/illu-system.png"></v-img>
            <v-card-title class="black--text">System Information</v-card-title>
          </v-card>
        </v-col>
        <v-col md="3" class="mb-6">
          <v-card to="/award-duplikation">
            <v-img class="illu" src="/img/illu-system.png"></v-img>
            <v-card-title class="black--text">Award Duplikation</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    //
  }),
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
    }
  }
}
</script>

<style>
  .illu {
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
  }
</style>
