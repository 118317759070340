<template>
  <v-dialog v-model="show" max-width="350px">
    <v-card>
      <v-card-title class="headline">
        <v-icon class="ml-1 amber--text" style="margin-right: 10px">mdi-alert-outline</v-icon>
        Achtung!
      </v-card-title>
      <v-card-text class="pb-0">
        Dieses Element soll abgelehnt werden. Es steht dann einer weiteren Bearbeitung nicht mehr zur Verfügung.
        <p class="bold pt-2">Sicher, dass dieses Element redaktionell ausgeschlossen werden soll?</p>
      </v-card-text>
      <v-card-actions class="pt-1 pb-6 px-6">
        <v-btn color="red darken-1" rounded outlined v-on:click="exclude" :disabled="loading" :loading="loading">
          Ja, ablehnen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-2" text v-on:click="show = false">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    targetClass: String,
    id: String,
    value: Boolean
  },
  data: () => ({
    loading: false,
    target: null
  }),
  created () {
    this.target = this.$state.getFromRegistry(this.id)
  },
  methods: {
    exclude: async function () {
      this.loading = true
      const change = {
        targetClass: this.targetClass,
        targetId: this.id,
        attributeName: 'excludedByEditor',
        newValue: true
      }
      await this.$http.post('/change', change)
      this.target.excludedByEditor = true
      this.loading = false
      this.show = false
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
