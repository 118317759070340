<template>

  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <h5>Wortlänge</h5>
          <p class="explanation body-2 pt-3 pr-3 mb-0">Es wird geprüft, ob ein Eintrag die Anzahl von 100 Zeichen überschreitet.</p>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="profile === true">
        <v-icon color="light-green">mdi-checkbox-marked-circle-outline</v-icon>
        Ok.
      </v-expansion-panel-content>
      <v-expansion-panel-content v-else>
        <p class="pb-2">
          <v-icon color="amber">mdi-alert-outline</v-icon>
          Vorsicht! Es gibt Felder, die eine Länge von 100 Zeichen überprüfen. Eine Prüfung wird empfohlen.
        </p>
        <ul>
          <li v-for="(row, rowIndex) in sub" class="column">
            Einträge in Zeile {{ rowIndex }}
            <span v-for="(value, header) in row">, Spalte:
              "<span class="monospace font-bigger">{{ header }}</span>"
            überprüfen.</span>
          </li>
        </ul>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
export default {
  props: [
    'profile',
    'sub',
    'header'
  ]
}
</script>
