<template>

  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <h5>Tabellenkopf-Länge</h5>
          <p class="explanation body-2 pt-3 pr-3 mb-0">
            Es wird geprüft, ob die Anzahl der Einträge im Tabellenkopf mit
            der Anzahl der Einträge in der längsten Zeile übereinstimmen.
          </p>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="profile === true">
        <v-icon color="light-green">mdi-checkbox-marked-circle-outline</v-icon>
        Ok.
      </v-expansion-panel-content>
      <v-expansion-panel-content v-else>
        <v-icon color="red darken-1">mdi-close-circle-outline</v-icon>
        Achtung! Die Anzahl der Einträge im Header stimmt nicht mit der Anzahl der Einträge in der längsten Zeile überein.
        Dies muss in der Lieferung behoben werden.
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
export default {
  props: [
    'profile',
    'sub'
  ]
}
</script>
