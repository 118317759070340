<template>

  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <h5>Unzulässige Sonderzeichen</h5>
          <p class="explanation body-2 pt-3 pr-3 mb-0">
            Der Sonderzeichen-Check überprüft Einträge auf möglicherweise unzulässige Sonderzeichen.
          </p>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="profile === true">
        <v-icon color="light-green">mdi-checkbox-marked-circle-outline</v-icon>
        Es wurden keine unzulässigen Sonderzeichen gefunden.
      </v-expansion-panel-content>
      <v-expansion-panel-content v-else>
        <p class="pb-2">
          <v-icon color="amber">mdi-alert-outline</v-icon>
          Warnung! An folgenden Stellen wurden möglicherweise unzulässige Sonderzeichen gefunden. Die Fundstellen sollten überprüft werden. Ein Weiterverarbeitung is dennoch möglich.
        </p>
        <v-simple-table style="background-color: #fafafa">
          <tr v-for="(row, rowIndex) in sub">
            <td class="column">Zeile <span class="mono font-bigger">{{ rowIndex }}</span></td>
            <td v-for="(value, header) in row" class="column">
              Spalte: <br><span class="mono font-bigger">{{ header }}</span>
            </td>
          </tr>
        </v-simple-table>
        <p class="explanation body-2 pt-3 mb-0">Als Sonderzeichen definiert sind: \ ^ £ $ % &amp; * ( ) { } # ~ ? &lt; &gt; , | = + -</p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
export default {
  props: [
    'profile',
    'sub',
    'header'
  ]
}
</script>
