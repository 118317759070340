<template>
  <v-container fluid class="relative header-space container">
    <h1>Schnellverifikation</h1>
    <v-row>
      <div class="col-lg-8 col-sm-12">
        <v-alert border="bottom" type="info" colored-border elevation="2">
          Probleme mit der Datenanlieferungen, dem Importvorgang oder der Datenqualität werden in die Stammdaten übernommen und können in der Schnellverifikation unter Umständen erhebliche Aufwände verursachen.
          Eine vorhergehende, stichprobenartige Prüfung wird dringend empfohlen.
        </v-alert>
        <v-card v-if="initiated">
          <v-tabs
            background-color="primary"
            center-active
            dark
            grow
          >
            <v-tab key="default" v-on:click="resetFilters">
              <v-icon class="mr-3">mdi-check-all</v-icon>Default
            </v-tab>
            <v-tab key="sample" v-on:click="resetFilters">
              <v-icon class="mr-3">mdi-format-list-checkbox</v-icon>Sample
            </v-tab>

            <v-tab-item key="default">
              <v-row class="px-4 py-4">
                <v-col class="col-12 col-md-4">
                  <v-select
                    v-model="selectedRankingGroupShortCode"
                    label="Projekt"
                    :items="rankingGroups"
                    item-text="name"
                    item-value="shortCode"
                  ></v-select>
                </v-col>
                <v-col class="col-12 col-md-4">
                  <v-select
                    v-model="selectedPeriodName"
                    label="Jahrgang"
                    :items="periods"
                    :disabled="selectedRankingGroupShortCode === null"
                    item-text="name"
                    item-label="name"
                  ></v-select>
                </v-col>
                <v-col class="col-12 col-md-4">
                  <v-btn color="primary" block class="mt-3 mb-3" v-on:click="loadToplists" :loading="loading">Toplisten anzeigen</v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item key="sample">
              <v-row class="px-4 py-4">
                <v-col class="col-12 col-md-9">
                  <v-select
                    v-model="selectedSample"
                    label="Sample auswählen"
                    :items="samples"
                    item-text="rawDataReference"
                    item-value="id">
                  </v-select>
                </v-col>
                <v-col class="col-12 col-md-3">
                  <v-btn v-on:click="bulkAcceptBySample" :disabled="selectedSample === null" color="primary" block class="mt-3 mb-3">Verifizieren</v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card>

        <div v-if="toplistLabels.length > 0">
          <v-simple-table>
            <tbody>
              <tr v-for="toplist in toplistLabels" :key="toplist.id" class="pa-2 mb-4">
                <td class="bold">{{ toplist.value }}</td>
                <td>
                  <v-btn v-on:click="bulkAccept(toplist.id)" v-if="toplist.allow_redo === true" :loading="toplist.loading">{{ toplist.executed === true && toplist.allow_redo === true ? 'Weiter verifizieren' : 'Verifizieren'}}</v-btn>
                  <p v-if="toplist.executed === true" class="pt-3">
                    Automatisch angenommen: {{ toplist.accepted }}<br>
                    Konnte nicht annehmen: {{ toplist.ignored }}<br>
                    Weiterhin offen: {{ toplist.pendingChanges }}
                  </p>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-btn v-on:click="bulkAcceptRankingGroupAndPeriod()"  color="error" class="ml-3 mb-3 error">Projekt + Jahrgang verifizieren</v-btn>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12">
        <p>Die Schnellverifikation erlaubt es, alle vorliegenden Änderungen eines Projekt unmittelbar zu übernehmen.</p>
        <p>Alle vorliegenden Einträge für das gewählte Projekt werden initial akzeptiert und zusätzlich werden alle Daten übernommen, zu denen genau ein Wert vorliegt. Gibt es unterschiedliche Werte, nimmt diese Funktion an dieser Stelle keine Änderung vor.</p>
        <p>Es werden pro Topliste maximal ca. 10.000 Changes akzeptiert. Wenn mehr offene Changes vorhanden sind,
        muss die Aktion mehrmals ausgeführt werden. Dies ist so gestaffelt, um Timeouts oder sonstige Performance-Probleme zu vermeiden.</p>
      </div>
    </v-row>
    <v-dialog v-if="bulkAcceptShowDialog" v-model="bulkAcceptShowDialog" max-width="450">
      <v-card>
        <v-card-title class="green--text">
          <v-icon color="green" large left>mdi-information-outline</v-icon>
          <span class="title">Schnellverifikation Resultat</span>
        </v-card-title>
        <v-card-text>
          <p>
            Hier ist die Zusammenfassung der Anfrage:
          </p>
          <p>
            <strong>Angenommene Änderungen:</strong> {{ bulkAcceptResponse.acceptedChanges }}<br>
            <strong>Abgelehnte Änderungen¹:</strong> {{ bulkAcceptResponse.rejectedChanges }}<br>
            <strong>Offene Änderungen²:</strong> {{ bulkAcceptResponse.pendingChanges }}<br>
          </p>

          <p class="small">
            ¹ Falls es für ein Feld mehrere offene Änderungen gibt, können diese nicht automatisch verifiziert werden.<br>
            ² Falls es für diese Selektion mehr als 10.000 Changes gibt, muss dieser Schritt mehrmals ausgeführt werden.
          </p>
        </v-card-text>
        <v-card-actions class="px-6 pb-6 justify-end">
          <v-btn v-on:click="bulkAcceptShowDialog=false" class="primary--text" outlined>Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { find } from 'lodash'

export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    loading: false,
    selectedRankingGroupShortCode: null,
    selectedPeriodName: null,
    selectedSample: null,
    rankingGroups: [{ shortCode: null, name: '' }],
    samples: [{ id: null, rawDataReference: null }],
    periods: [],
    toplistLabels: [],
    bulkAcceptShowDialog: false,
    bulkAcceptResponse: {
      acceptedChanges: null,
      rejectedChanges: null,
      pendingChanges: null
    }
  }),
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
    }

    const calls = []
    // Load RankingGroups
    const rankingGroupPromise = this.$http.get('/ranking_group/list')
      .then((response) => {
        this.rankingGroups = response.data
      })
    calls.push(rankingGroupPromise)
    // Load Periods
    const periodPromise = this.$http.get('/period/list')
      .then((response) => {
        this.periods = response.data
      })
    calls.push(periodPromise)
    // Load samples
    const payload = {
      query: '{ samples { id, rawDataReference } }'
    }
    const samplesPromise = this.$http.post('/warehouse-graphql', payload)
      .then((response) => {
        this.samples = response.data.data.samples
      })
    calls.push(samplesPromise)
    // Collects all promises
    await Promise.all(calls)
    this.initiated = true
  },
  methods: {
    resetFilters: async function () {
      this.selectedRankingGroupShortCode = null
      this.selectedPeriodName = null
      this.selectedSample = null
    },
    loadToplists: function () {
      this.loading = true
      this.toplistLabels = []
      const urlParams = new URLSearchParams()
      // checks for a selected ranking group
      if (this.selectedRankingGroupShortCode !== null) {
        urlParams.append('rgShortCode', this.selectedRankingGroupShortCode)
      }
      // checks for a selected period
      if (this.selectedPeriodName !== '') {
        urlParams.append('periodName', this.selectedPeriodName)
      }
      // promise of loading toplists from backend
      this.$http.get('/toplist_label/list?' + urlParams.toString())
        .then((response) => {
          this.toplistLabels = response.data.list
          this.toplistLabels.forEach((item) => {
            this.$set(item, 'loading', false)
            this.$set(item, 'executed', false)
            this.$set(item, 'allow_redo', true)
          })
          this.loading = false
        })
    },
    bulkAccept: async function (toplistLabelId) {
      const toplistLabel = find(this.toplistLabels, { id: toplistLabelId })
      const url = '/change/bulk-accept'
      const body = {
        toplistId: toplistLabel.id,
        periodId: this.selectedPeriod.id
      }
      toplistLabel.loading = true
      await this.$http.put(url, body)
        .then((response) => {
          this.$set(toplistLabel, 'accepted', response.data.acceptedChanges)
          this.$set(toplistLabel, 'ignored', response.data.rejectedChanges)
          this.$set(toplistLabel, 'pendingChanges', response.data.pendingChanges)
          toplistLabel.loading = false
          toplistLabel.executed = true
          toplistLabel.allow_redo = response.data.pendingChanges > 0
        })
        .catch((response) => {
          this.$set(toplistLabel, 'accepted', 0)
          this.$set(toplistLabel, 'ignored', 0)
          toplistLabel.loading = false
          toplistLabel.executed = true
          toplistLabel.allow_redo = false
        })
    },
    bulkAcceptRankingGroupAndPeriod: async function () {
      const body = {
        rgShortCode: this.selectedRankingGroupShortCode,
        periodId: this.selectedPeriod.id
      }
      const url = '/change/bulk-accept'
      await this.$http.put(url, body)
        .then((response) => {
          this.bulkAcceptShowDialog = true
          this.bulkAcceptResponse = response.data
        })
    },
    bulkAcceptBySample: async function () {
      const url = '/change/sample-bulk-accept/' + this.selectedSample
      await this.$http.put(url)
        .then((response) => {
          this.bulkAcceptShowDialog = true
          this.bulkAcceptResponse = response.data
        })
    }
  },
  computed: {
    selectedPeriod () {
      if (this.selectedPeriodName === null) {
        return null
      }
      return find(this.periods, { name: this.selectedPeriodName })
    }
  }
}
</script>
