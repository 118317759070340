<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row>
        <v-col sm="12">
          <h1>Benutzer</h1>
          <p>Eine Übersicht aller Benutzer des Data Warehouse.</p>
          <p>
            Das Anlegen eines Nutzers hier ermöglicht es erst dem Nutzer sich selbst über das Auth0 Popup zu registrieren.
            Dabei muss er die gleiche E-Mail Adresse verwenden, wie hier angegeben.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <div v-show="initiated">
            <v-card class="mb-6">
              <v-data-table
                :headers="headers"
                :items="users"
                :items-per-page="50"
                class="elevation-1"
                sort-by="name">
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="pr-1">mdi-plus</v-icon> Benutzer hinzufügen
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Benutzer</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container fluid>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field v-if="editedIndex === -1" v-model="editedItem.email" label="E-Mail" hint="Die Mail Adresse, mit der sich der User anmeldet."></v-text-field>
                                <v-text-field v-model="editedItem.fullName" label="Name" hint="Der volle Name des Nutzers."></v-text-field>
                                <v-text-field v-model="editedItem.shortCode" label="Kürzel" hint="Muss zwischen 2 und 4 Zeichen lang sein."></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="close">
                            abbrechen
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="save">
                            <v-progress-circular v-if="loading === true" color="primary" size="25" :width="2" indeterminate></v-progress-circular>
                            <span v-if="loading === false">Speichern</span>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.id="{ item }">
                  <small class="mono">{{ item.id }}</small>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <small>{{ new Date(item.createdAt).toLocaleString() }}</small>
                </template>
                <template v-slot:item.lastLogin="{ item }">
                  <small>{{ item.updatedAt ? new Date(item.updatedAt).toLocaleString() : '' }}</small>
                </template>
                <template v-slot:item.isActive="{ item }">
                  <span v-if="item.isActive === true">aktiv</span><span v-else>nicht aktiv</span>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    users: [],
    loading: false,
    headers: [
      { text: 'Id', value: 'id', sortable: false },
      { text: 'E-Mail', value: 'email' },
      { text: 'Name', value: 'fullName' },
      { text: 'Roles', value: 'roles' },
      { text: 'Short Code', value: 'shortCode' },
      { text: 'Status', value: 'isActive' },
      { text: 'Created At', value: 'createdAt' },
      { text: 'Last Login', value: 'lastLogin' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    dialog: false,
    editedIndex: -1,
    editedItem: {
      fullName: '',
      email: '',
      shortCode: ''
    },
    defaultItem: {
      fullName: '',
      email: '',
      shortCode: ''
    }
  }),
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    getList: async function () {
      const response = await this.$http.get('/admin/user/list')
      this.users = response.data
    },
    editItem (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save: async function () {
      if (this.editedIndex > -1) {
        this.loading = true
        const payload = {
          fullName: this.editedItem.fullName,
          shortCode: this.editedItem.shortCode
        }
        await this.$http.post('/not-implemented' + this.editedItem.id, payload)
        this.loading = false
      } else {
        // Create new.
        this.loading = true
        const payload = {
          email: this.editedItem.email,
          fullName: this.editedItem.fullName,
          shortCode: this.editedItem.shortCode,
          roles: ['ROLE_ADMIN'],
          isActive: true
        }
        await this.$http.post('/admin/user', payload)
        this.loading = false
      }
      this.close()
      await this.getList()
    }
  },
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    await this.getList()
    this.initiated = true
  }
}
</script>
