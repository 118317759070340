<template>

  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <h5>Leerzeichen am Anfang oder Ende von Spaltennamen</h5>
          <p class="explanation body-2 pt-3 pr-3 mb-0">
            Bei diesem Check wird geprüft, ob sich unerwünschte Leerzeichen am Anfang oder Ende von Spaltennamen eingeschlichen haben.
          </p>
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content v-if="profile === true">
        <p class="pb-2">
          <v-icon color="red darken-1">mdi-close-circle-outline</v-icon>
          Achtung! Es wurden unerwünschte Leerzeichen gefunden. Dies wird höchstwahrscheinlich zu unerwarteten Ergebnissen beim Mapping bzw. Import führen. Spalte im Sample und im Mapping fixen!
        </p>
        <ul>
          <li v-for="(value, header) in sub">
            <div v-if="value === true" class="red--text text--darken-1">
              Spalte <span class="monospace font-bigger">"{{ header }}"</span> enthält unerwünschtes Whitespace.
            </div>
            <div v-else>
              Spalte <span class="monospace font-bigger">"{{ header }}"</span> ist in Ordnung.
            </div>
          </li>
        </ul>
      </v-expansion-panel-content>

      <v-expansion-panel-content v-else>
        <p class="pb-2">
          <v-icon color="light-green">mdi-checkbox-marked-circle-outline</v-icon>
          Es wurden keine unerwünschten Leerzeichen gefunden.
        </p>
        <ul>
          <li v-for="(value, header) in sub">
            Spalte <span class="monospace font-bigger">"{{ header }}"</span> ist in Ordnung.
          </li>
        </ul>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
export default {
  props: [
    'profile',
    'sub',
    'header'
  ]
}
</script>
