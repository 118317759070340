<template>
  <v-container fluid class="relative header-space container">
    <div v-if="initiated">
      <h1>Verifikation</h1>
      <v-row class="mt-3">
        <div class="col-lg-8 col-sm-12">
          <v-card>
            <v-tabs
              background-color="primary"
              center-active
              dark
              grow
            >
              <v-tab key="default" v-on:click="resetFilters">
                <v-icon class="mr-3">mdi-check-all</v-icon>Default
              </v-tab>
              <v-tab key="sample" v-on:click="resetFilters">
                <v-icon class="mr-3">mdi-format-list-checkbox</v-icon>Sample
              </v-tab>

              <v-tab-item key="default">
                <v-row class="pl-4 pr-4 pt-4">
                  <div class="col-sm-12 col-md-4">
                    <v-select
                      v-model="selectedRankingGroupId"
                      v-on:change="loadItems"
                      label="Projekt auswählen"
                      :items="rankingGroups"
                      item-text="name"
                      item-value="id">
                    </v-select>
                  </div>
                  <div class="col-sm-12 col-md-4">
                    <v-select
                      v-model="selectedPeriodNames"
                      v-on:change="loadItems"
                      label="Jahrgang"
                      :items="periodNames">
                    </v-select>
                  </div>
                  <div class="col-sm-12 col-md-4">
                    <v-switch
                      v-model="selectedIsNotApproved"
                      v-on:change="loadItems"
                      label="Initiale Verifizierung"
                      :false-value="null"
                      :true-value="0"
                      hide-details>
                    </v-switch>
                  </div>
                </v-row>
              </v-tab-item>
              <v-tab-item key="sample">
                <v-row class="pl-4 pr-4 pt-4">
                  <div class="col-sm-12">
                    <v-select
                      v-model="selectedSample"
                      v-on:change="loadItems"
                      label="Sample auswählen"
                      :items="samples"
                      item-text="rawDataReference"
                      item-value="id">
                    </v-select>
                  </div>
                </v-row>
              </v-tab-item>
            </v-tabs>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-left column-width">ausstehende Aktion</th>
                  <th class="text-left column-width">Name</th>
                  <th class="text-left column-width">ID</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(group, id) in groupedChanges" :to="group[0].getTargetLink()">
                  <td class="open-changes">
                    <span v-if="group[0].getRootTarget().isApproved"> <!-- open Changes -->
                      <v-icon size="20" color="grey">mdi-sync</v-icon>
                      Erwartet Änderungen <br>
                      <span class="small grey--text text--darken-2 pt-0 mt-0 ml-6">
                        offene Änderungen: {{ group.length }}
                      </span>
                    </span>
                    <span v-if="!group[0].getRootTarget().isApproved"> <!-- not approved Account/Contacts -->
                      <v-icon size="20" color="grey">mdi-account-check</v-icon>
                      Initiale Prüfung
                    </span>
                  </td>
                  <td>
                    <router-link :to="group[0].getTargetLink()">
                      <span v-if="group[0].getTargetShortClass() === 'Account'">
                        {{ group[0].getRootTarget().name }}
                      </span>
                      <span v-if="group[0].getTargetShortClass() === 'Product'">
                        {{ group[0].getRootTarget().brand }} {{ group[0].getRootTarget().label }}
                      </span>
                      <span v-if="group[0].getTargetShortClass() === 'Contact'">
                        {{ group[0].getRootTarget().firstName }} {{ group[0].getRootTarget().surname }}
                      </span>
                    </router-link>
                  </td>
                  <td>
                    <span class="mono grey--text text--darken-2 small">
                      {{ group[0].getTargetShortClass() }}: {{ id }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <p class="grey--text text--darken-2 pl-4 pb-4 pt-4 small">
              Datenpunkte zur Verifikation ausstehend: insgesamt {{ changes.length }}
            </p>
          </v-card>
        </div>

        <div class="col-lg-4 col-sm-12">
          <h3>Verifikationsbereich</h3>
          <p>In diesem Bereich sind alle Einträge zu finden die, die eine Aktion von einem Daten-Redakteur erwarten.
          </p>
          <p>Die Verifikation von Einträgen kann in zwei Aktionstypen unterschieden werden:
          </p>
          <ul>
            <li><b>Initiale Verifizierung:</b><br>Neu erstellte Einträge die erstmals verifiziert werden müssen.</li>
            <li><b>Erwartet Änderungen:</b><br>Einträge bei denen es zu einem Update kam. Diese geänderten Daten müssen erneut verifiziert werden.</li>
          </ul>
          <br>
          <h3>Anleitung</h3>
          <p>Mithilfe der Filter oberhalb der Tabelle lassen sich Einträge nach Projekt und Jahrgang filtern. Ebenfalls möglich ist es, die Anzeige aller Einträge auf die Einträge, mit ausstehender Verifikation zu reduzieren.</p>
          <p>Durch Klicken auf den Namen des Eintrags können weitere Details eingesehen und die Verifizierung vorgenommen werden.</p>

          <v-btn class="float-right mt-4" to="/verifikation/kurz" type="secondary">Schnellverifikation</v-btn>
        </div>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import Change from './../models/change.js'
import { groupBy } from 'lodash'

export default {
  components: {
    //
  },
  data: () => ({
    initiated: false,
    changes: [],
    selectedIsNotApproved: null,
    selectedRankingGroupId: null,
    selectedPeriodNames: null,
    selectedSample: null,
    rankingGroups: [{ id: null, name: 'Alle Projekte' }],
    samples: [{ id: null, rawDataReference: null }],
    periodNames: ['2025', '2024', '2023', '2022']
  }),
  async created () {
    // Firewall
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }
    const calls = []
    // promise of loaded loadItems
    // let loadItemsPromise = this.loadItems()
    // calls.push(loadItemsPromise)
    // promise of loaded rankingGroups
    const rankingGroupPromise = this.$http.get('/ranking_group/list')
      .then((response) => {
        this.rankingGroups = this.rankingGroups.concat(response.data)
      })
    calls.push(rankingGroupPromise)
    // Load samples
    const payload = {
      query: '{ samples { id, rawDataReference } }'
    }
    const samplesPromise = this.$http.post('/warehouse-graphql', payload)
      .then((response) => {
        this.samples = response.data.data.samples
      })
    calls.push(samplesPromise)
    // collects all promises
    await Promise.all(calls)
    this.initiated = true
  },
  methods: {
    resetFilters: async function () {
      this.changes = []
      this.selectedIsNotApproved = null
      this.selectedRankingGroupId = null
      this.selectedPeriodNames = null
      this.selectedSample = null
    },
    loadItems: async function () {
      this.changes = []
      const urlParams = new URLSearchParams()
      if (this.selectedIsNotApproved !== null) {
        urlParams.append('approved', this.selectedIsNotApproved)
      }
      if (this.selectedRankingGroupId !== null) {
        urlParams.append('rgId', this.selectedRankingGroupId)
      }
      if (this.selectedPeriodNames !== null) {
        urlParams.append('pName', this.selectedPeriodNames)
      }
      if (this.selectedSample !== null) {
        urlParams.append('sampleId', this.selectedSample)
      }
      // Create a promise for loading all items.
      const loadItemsPromise = this.$http.get('/change/list/pending?' + urlParams.toString())
      // Conditions which have to be passed before resolving the promise of loading all items.
        .then((response) => {
          response.data.forEach((changeData) => {
            this.changes.push(new Change(changeData))
          })
          // Filter all changes and ignore orphans.
          const filtered = this.changes.filter((change) => {
            return change.isOrphan() === false
          })
          // Group all filtered changes to one root Element and return the matching rootTarget.
          const grouped = groupBy(filtered, (change) => {
            return change.getRootTarget().id
          })
          // Create an array of all items with open verification.
          const verificationItems = []
          Object.keys(grouped).forEach((id) => {
            const verificationItem = { id, rootType: grouped[id][0].getTargetShortClass() }
            verificationItems.push(verificationItem)
          })
          this.$state.setVerificationItems(verificationItems)
        })
      console.log(this.changes)
      return loadItemsPromise
    }
  },
  computed: {
    groupedChanges: function () {
      const filtered = this.changes.filter((change) => {
        return change.isOrphan() === false
      })
      return groupBy(filtered, (change) => {
        return change.getRootTarget().id
      })
    },
    orphans: function () {
      return this.changes.filter((change) => {
        return change.isOrphan()
      })
    }
  }
}
</script>

<style scoped>
  .column-width {
    width: 33%;
  }
  .open-changes {
    line-height: 8px;
  }
</style>
